import { LbButton } from "@components/feedback/LbButton/LbButton"
import { LbPasswordField } from "@components/inputs/LbPasswordField/LbPasswordField"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import { usePostAuthChangePasswordMutation } from "@leadbay/state/api"
import { Close } from "@mui/icons-material"
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material"

import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import { toast } from "react-toastify"
import { object, string, type InferType } from "yup"

const validationSchema = object().shape({
  password: string().matches(
    /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase",
  ),
  passwordConfirmation: string().matches(
    /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase",
  ),
})

export type ResetPasswordScreenValues = InferType<typeof validationSchema>

interface UpdatePasswordProps {
  showPasswordUpdate: boolean
  handleShowPasswordUpdate: () => void
}

export const UpdatePassword = (props: UpdatePasswordProps) => {
  const { showPasswordUpdate, handleShowPasswordUpdate } = props

  const [changePassword, { isLoading, isSuccess, error }] =
    usePostAuthChangePasswordMutation()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<ResetPasswordScreenValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  })

  const handleFormSubmit: SubmitHandler<ResetPasswordScreenValues> = async ({
    passwordConfirmation,
    password,
  }) => {
    try {
      if (!password) throw new Error("Password is required")

      if (!passwordConfirmation)
        throw new Error("Password confirmation is required")

      if (password === passwordConfirmation)
        throw new Error(
          "Your new password must be different from your current password",
        )

      await changePassword({
        passwordChange: {
          current: password,
          new: passwordConfirmation,
        },
      })
    } catch (error) {
      const err = error as Error

      toast.error(err.message)
    } finally {
      reset()
    }
  }

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      onSuccess: {
        message: {
          text: "Your password has been reset successfully",
        },
        action: handleShowPasswordUpdate,
      },
      statusCodeConfig: {
        401: {
          message: {
            text: "An error occurred, please verify your current password",
          },
        },
      },
    },
  })

  return (
    <Modal
      open={showPasswordUpdate}
      onClose={handleShowPasswordUpdate}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(255,255,255,0.8)",
          },
        },
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: 600,
          backgroundColor: "white",
          p: 4,
          borderRadius: 1,
          border: "1px solid rgba(0, 0, 0, 0.08)",
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="h2" sx={{ lineHeight: 1 }}>
                Update your password
              </Typography>

              <IconButton size="small" onClick={handleShowPasswordUpdate}>
                <Close />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <LbPasswordField
                    label="Current password"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    placeholder="********"
                    fullWidth
                    required
                    {...field}
                  />
                )}
              />

              <Controller
                control={control}
                name="passwordConfirmation"
                render={({ field }) => (
                  <LbPasswordField
                    label="New password"
                    placeholder="********"
                    fullWidth
                    required
                    error={!!errors.passwordConfirmation}
                    helperText={errors.passwordConfirmation?.message}
                    {...field}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <LbButton
              sx={{
                mt: (errors?.password ?? errors?.passwordConfirmation) ? 5 : 0,
              }}
              variant="contained"
              color="primary"
              size="large"
              disabled={!isValid || isLoading}
              type="submit"
              loading={isLoading}
            >
              Update my password
            </LbButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
