import {
  LeadStatus,
  usePostLeadsByLeadIdSetStatusMutation,
} from "@leadbay/state/api"
import { useAppSelector } from "@leadbay/state/hooks"
import {
  selectCommonsState,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"
import { Create } from "@mui/icons-material"
import { IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material"
import React from "react"
import { toast } from "react-toastify"

const STATUS: LeadStatus[] = ["WANTED", "LOST", "WON"]

interface LbUpdateLeadStatusProps {
  leadId: string
}

export const LbUpdateLeadStatus = ({ leadId }: LbUpdateLeadStatusProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { wishlistViewMode } = useAppSelector(selectCommonsState)

  const open = Boolean(anchorEl)

  const [useStatusMutation] = usePostLeadsByLeadIdSetStatusMutation()

  const handleUpdateLeadStatus = async (status: LeadStatus) => {
    try {
      const payload = {
        leadId,
        setStatusRequest: {
          status,
        },
      }

      await useStatusMutation(payload).unwrap()

      const successMessage =
        wishlistViewMode === WishlistViewMode.DISCOVER
          ? "Lead status updated  and moved to the monitor"
          : "Lead status updated"

      toast.success(successMessage)
    } catch (error) {
      console.error(error)

      toast.error("Failed to update lead status")
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const tooltipMessage =
    wishlistViewMode === WishlistViewMode.DISCOVER
      ? "Signal already LOST or WON lead"
      : "Update lead status"

  return (
    <>
      <Tooltip title={tooltipMessage}>
        <IconButton
          id="basic-button"
          color="primary"
          sx={{ display: "flex", alignItems: "center", gap: 1, ml: 0.5 }}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          size="small"
        >
          <Create sx={{ fontSize: "1.1rem" }} />
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {STATUS.map((status) => (
          <MenuItem key={status} onClick={() => handleUpdateLeadStatus(status)}>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: "bold",
              }}
            >
              {status}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
