import { LbLottie } from "@components/animations/LbLottie/LbLottie"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { LbPasswordField } from "@components/inputs/LbPasswordField/LbPasswordField"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import {
  AUTH_TOKEN,
  CENTERED_FLEX_COL,
  RESET_PASSWORD_EMAIL,
  WEB_APP_ROUTES,
} from "@leadbay/constants"
import {
  useGetUsersMeQuery,
  usePostAuthRegisterMutation,
  usePostAuthVerifyEmailCodeMutation,
  type AuthInvitesInviteIdGet200Response,
  type PostAuthRegisterApiArg,
} from "@leadbay/state/api"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import { selectAuthState, setToken } from "@leadbay/state/slices/authSlice"
import { Grid, TextField, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { useAsyncEffect } from "ahooks"
import localforage from "localforage"
import { useState } from "react"
import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import type * as Yup from "yup"
import { object, string } from "yup"
import { checkNonProEmail } from "@leadbay/utils"

const validationSchema = object().shape({
  name: string().required().min(3),
  password: string().matches(
    /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase",
  ),
})

export type RegisterScreenValues = Yup.InferType<typeof validationSchema>

interface RegisterFormProps {
  inviteData?: AuthInvitesInviteIdGet200Response
  invite?: string
}

export const RegisterForm = ({ inviteData, invite }: RegisterFormProps) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const [verifyEmailCode] = usePostAuthVerifyEmailCodeMutation()

  const [registerMutation, { isLoading, isSuccess, error, isError }] =
    usePostAuthRegisterMutation()

  const isPWAInstalled = window.matchMedia("(display-mode: standalone)").matches

  const [registerEnd, setRegisterEnd] = useState(false)

  const { token } = useAppSelector(selectAuthState)

  const [codeOk, setCodeOk] = useState(false)

  const { data: useMeData } = useGetUsersMeQuery(undefined, {
    skip: !token && !codeOk,
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<RegisterScreenValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      name: "",
    },
  })

  const [code, setCode] = useState("")

  const handleSetCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
  }

  const [verifyLoading, setVerifyLoading] = useState(false)

  const handleVerifyEmailCode = async () => {
    try {
      setVerifyLoading(true)

      const response = await verifyEmailCode({
        emailVerifyCode: {
          code,
        },
      })

      if ("error" in response) throw new Error("Error verifying email code")

      setCodeOk(true)
    } catch (error) {
      toast.error("Error verifying email code, please try again.")

      console.error(error)
    } finally {
      setVerifyLoading(false)
    }
  }

  useAsyncEffect(async () => {
    if (useMeData?.verified) {
      navigate(WEB_APP_ROUTES.ROOT)
    }
  }, [useMeData])

  const handleFormSubmit: SubmitHandler<RegisterScreenValues> = async ({
    password,
    name,
  }) => {
    try {
      const email =
        inviteData?.email ?? (await localforage.getItem(RESET_PASSWORD_EMAIL))

      if (!email) throw new Error("Email not found")

      checkNonProEmail(email)

      if (!email) throw new Error("Email not found")

      const payload: PostAuthRegisterApiArg = {
        code: isPWAInstalled,
        userRegistration: {
          email,
          password: password as string,
          name,
          invite,
        },
      }

      const { data } = await registerMutation(payload)

      if (!data) throw new Error("No data returned")

      const token = data.token

      if (token) {
        await localforage.setItem(AUTH_TOKEN, token)

        dispatch(setToken(token))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleResendEmail = async () => {
    try {
      const url = `${
        import.meta.env.VITE_LEADBAY_API_BASE_URL
      }/auth/resend_email`

      await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      toast.success("Email confirmation sent successfully")
    } catch (error) {
      toast.error("Error resending email")

      console.error(error)
    }
  }

  const handleRegisterAction = async () => {
    try {
      if (!isError) setRegisterEnd(true)
      await localforage.removeItem(RESET_PASSWORD_EMAIL)
    } catch (error) {
      console.log(error)
    }
  }

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      onSuccess: {
        action: handleRegisterAction,
      },
      statusCodeConfig: {
        400: {
          message: {
            text: "You already have an account, please login or your Invite is already used",
          },
        },
        403: {
          message: {
            text: "You don't have beta access to Leadbay beta",
          },
        },
      },
    },
  })

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} id="register-form">
      <Grid
        container
        spacing={2}
        rowGap={7}
        sx={{
          maxWidth: "550px",
          margin: "0 auto",
        }}
      >
        <Grid item xs={12}>
          <Box
            component="header"
            sx={{
              display: registerEnd ? "flex" : "block",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {registerEnd && isPWAInstalled && (
              <LbLottie
                type="success"
                style={{ width: 90, marginBottom: 30 }}
              />
            )}

            {!registerEnd ? (
              <Typography
                component="h1"
                variant={"h2"}
                fontFamily="BasementGrotesque"
                fontSize={40}
                sx={{
                  textTransform: "uppercase",
                  textAlign: "left",
                  lineHeight: 1.2,
                }}
              >
                welcome to <br /> the future of
                <Typography
                  fontFamily="Reenie Beanie"
                  component="span"
                  variant="h2"
                  fontSize={64}
                  marginLeft={1}
                  sx={{ lineHeight: 0.9 }}
                >
                  Sales
                </Typography>
                <Box
                  sx={{
                    mb: 1,
                    width: "100%",
                    height: 6,
                    background: "#61E786",
                  }}
                />
              </Typography>
            ) : (
              <>
                {isPWAInstalled ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h4"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontFamily: "Hanken Grotesk",
                      }}
                    >
                      Please enter the one time code sent to your email to
                      verify your account.
                    </Typography>

                    <TextField
                      value={code}
                      onChange={handleSetCode}
                      type="number"
                      focused={code.length === 6}
                      inputProps={{ maxLength: 6 }}
                      sx={{
                        mt: 5,
                        mb: 2,
                        width: "380px",
                      }}
                      label="Verification code"
                    />

                    {code.length === 6 && (
                      <LbButton
                        loading={verifyLoading}
                        onClick={handleVerifyEmailCode}
                        variant="contained"
                        color="primary"
                        sx={{ mb: 5 }}
                      >
                        Verify
                      </LbButton>
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      component="h1"
                      variant="h4"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontFamily: "Hanken Grotesk",
                      }}
                    >
                      Your account has been created!
                      <br /> Check your email to confirm and activate your
                      account. You can now close this page.
                    </Typography>

                    <Box sx={{ mt: 6, ...CENTERED_FLEX_COL }}>
                      <LbButton
                        onClick={handleResendEmail}
                        variant="text"
                        size="small"
                        sx={{
                          color: "text.secondary",
                          textTransform: "unset",
                        }}
                      >
                        Resend email confirmation
                      </LbButton>
                    </Box>
                  </>
                )}

                <a href="mailto:help@leadbay.ai" rel="noopenner">
                  <LbButton
                    variant="text"
                    size="small"
                    sx={{
                      color: "text.secondary",
                      textTransform: "unset",
                    }}
                  >
                    If you&apos;re experiencing issues, please get in touch with
                    us.
                  </LbButton>
                </a>
              </>
            )}

            {!registerEnd && (
              <Typography variant="h6" component="h2" color="text.secondary">
                Register
              </Typography>
            )}
          </Box>
        </Grid>

        {!registerEnd && (
          <>
            <Grid item xs={6} sx={{ ...CENTERED_FLEX_COL, gap: 2 }}>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    error={!!errors.name}
                    type="text"
                    fullWidth
                    helperText={errors.name?.message}
                    sx={{
                      ".MuiFormHelperText-root": {
                        position: "absolute",
                        top: "105%",
                      },
                    }}
                    label="Name"
                    placeholder="John Doe"
                    {...field}
                    required
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} sx={{ ...CENTERED_FLEX_COL, gap: 2 }}>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <LbPasswordField
                    label="Password"
                    placeholder="********"
                    fullWidth
                    required
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <LbButton
                loading={isLoading}
                variant="contained"
                color="primary"
                sx={{ marginBottom: 3 }}
                size="large"
                disabled={!isValid || isLoading}
                type="submit"
                id="register-button-validation"
              >
                Register
              </LbButton>

              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <Typography>Already have an account?</Typography>

                <LbButton
                  variant="text"
                  color="primary"
                  component={RouterLink}
                  to={WEB_APP_ROUTES.PUBLIC.LOGIN}
                  sx={{ textTransform: "capitalize" }}
                >
                  Login
                </LbButton>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  )
}
