import { TimelinePart } from "@components/display/TimelinePart/TimelinePart"
import { CENTERED_FLEX_COL } from "@leadbay/constants"
import {
  useAppDispatch,
  useAppSelector,
  useTimeline,
} from "@leadbay/state/hooks"
import { selectAuthState } from "@leadbay/state/slices/authSlice"
import {
  NavDrawerState,
  selectCommonsState,
  toggleNavDrawer,
} from "@leadbay/state/slices/commonsSlice"
import { Box, Typography } from "@mui/material"
import { useEffect, useRef } from "react"

interface Timeline {
  enableSelection?: boolean
}

export const Timeline = ({ enableSelection }: Timeline) => {
  const dispatch = useAppDispatch()

  const { wishlistViewMode } = useAppSelector(selectCommonsState)

  const endOfListElementRef = useRef<HTMLDivElement>(null)

  const { user } = useAppSelector(selectAuthState)

  const { timeline, noData, isFetching } = useTimeline({
    endOfListElementRef,
  })

  useEffect(() => {
    if (noData)
      dispatch(
        toggleNavDrawer({
          isOpen: false,
          partial: NavDrawerState.LEAD_INFOS,
        }),
      )
  }, [noData, wishlistViewMode])

  return (
    <Box
      id="timeline-screen"
      flex={1}
      display="flex"
      flexDirection="column"
      gap={2}
    >
      {!noData && user?.computing_daily_timeline && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            zIndex: 1,
            right: 20,
          }}
        >
          <Typography variant="subtitle2">Timeline updating</Typography>
        </Box>
      )}

      {(!noData || timeline.length < 2) && (
        <Typography
          component="p"
          sx={{ mr: 10, ml: 0.5 }}
          color="text.secondary"
          variant="subtitle2"
        >
          💡 To receive more personalized recommendations, like and export leads
          you find interesting
        </Typography>
      )}

      <Box flex={1} display="flex" flexDirection="column">
        {timeline.map((timelinePart, timelinePartIndex) => (
          <TimelinePart
            key={timelinePart.id + timelinePart.type + timelinePart.value}
            timelinePart={timelinePart}
            enableSelection={enableSelection}
            firstTableBlockId={
              timeline.filter((part) => part.type === "table")[0]?.id
            }
            isFirstOfDate={
              timeline[timelinePartIndex - 1]?.type === "date" ||
              timelinePartIndex === 0
            }
            isLastOfDate={
              timeline[timelinePartIndex + 1]?.type === "date" ||
              timelinePartIndex === timeline.length - 1
            }
          />
        ))}
      </Box>

      {noData && !isFetching && (
        <Box
          sx={{
            ...CENTERED_FLEX_COL,
            height: "calc(100vh - 200px)",
            width: "100%",
          }}
        >
          <Typography>
            {user?.computing_daily_timeline
              ? "Timeline updating, please check back later. 🕒"
              : "Nothing to show at the moment. Please check back later. 🕒"}
          </Typography>
        </Box>
      )}

      <div ref={endOfListElementRef} />
    </Box>
  )
}
