import { LbMappingRow } from "@components/display/LbMappingRow/LbMappingRow"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { MAPPING_STEP } from "@leadbay/constants"
import {
  useGetImportsByImportIdQuery,
  usePostImportsByImportIdUpdateFieldMappingsMutation,
  type FieldMappings,
} from "@leadbay/state/api"
import { useAppDispatch, useLeadbayWSNotification } from "@leadbay/state/hooks"
import { setMappingStep } from "@leadbay/state/slices/onboardingSlice"
import { MessageType } from "@leadbay/typings"
import { Box, Tooltip, Typography } from "@mui/material"
import { useAsyncEffect } from "ahooks"
import localforage from "localforage"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { getHintForKey } from "@leadbay/utils"
import { Step } from "../../Step/Step"

export const YourFields = () => {
  const dispatch = useAppDispatch()

  const { messages: importWsMessages, clearMessageHistory } =
    useLeadbayWSNotification(MessageType.IMPORT_PROGRESS)

  const lastMessage = importWsMessages[importWsMessages.length - 1]

  const importId = lastMessage?.importId

  const uploadIsDone = lastMessage?.done

  const [loading, setLoading] = useState(false)
  const [showMore, setShowMore] = useState(false)

  const { data: status } = useGetImportsByImportIdQuery(
    { importId: importId! },
    { skip: !importId && !uploadIsDone },
  )

  const [crmUpdateFieldMappings] =
    usePostImportsByImportIdUpdateFieldMappingsMutation()

  const statusFieldHints = status?.pre_processing?.hints?.fields || {}
  const fields = status?.mappings?.fields || statusFieldHints

  const statusSamples = status?.pre_processing?.samples || []
  const statusFields = statusSamples[0] || {}
  const statusFieldsArray = Object.keys(statusFields)

  const [selectedFields, setSelectedFields] = useState<
    Record<string, string | null>
  >({
    name: null,
    fieldStatus: null,
    website: null,
    location: null,
    email: null,
    streetNumber: null,
    street: null,
    postCode: null,
    city: null,
  })

  useEffect(() => {
    if (!fields) return

    setSelectedFields({
      name: getHintForKey("LEAD_NAME", fields) || null,
      fieldStatus: getHintForKey("LEAD_STATUS", fields) || null,
      website: getHintForKey("LEAD_WEBSITE", fields) || null,
      location: getHintForKey("LEAD_LOCATION", fields) || null,
      email: getHintForKey("EMAIL", fields) || null,
      streetNumber: getHintForKey("LEAD_LOCATION_STREET_NUM", fields) || null,
      street: getHintForKey("LEAD_LOCATION_STREET", fields) || null,
      postCode: getHintForKey("LEAD_LOCATION_POSTCODE", fields) || null,
      city: getHintForKey("LEAD_LOCATION_CITY", fields) || null,
    })
  }, [fields])

  const availableFields = useMemo(() => {
    return statusFieldsArray.filter(
      (field) => !Object.values(selectedFields).includes(field),
    )
  }, [statusFieldsArray, selectedFields])

  const handleShowMore = () => {
    setShowMore((prev) => !prev)
  }

  const handleSetField = (field: string | null, fieldName: string) => {
    setSelectedFields((prev) => ({
      ...prev,
      [fieldName]: field,
    }))
  }

  const handleNext = async () => {
    try {
      setLoading(true)

      if (!selectedFields.name) throw Error("Company name is required")

      const testExist = (field: string | null) => field && field.length > 0

      const generateFieldMapping = (
        field: string | null,
        mappingValue: string,
      ) => (testExist(field) ? { [field as string]: mappingValue } : {})

      const fieldMappings = {
        fields: {
          [selectedFields.name]: "LEAD_NAME",
          ...generateFieldMapping(selectedFields.fieldStatus, "LEAD_STATUS"),
          ...generateFieldMapping(selectedFields.website, "LEAD_WEBSITE"),
          ...generateFieldMapping(selectedFields.location, "LEAD_LOCATION"),
          ...generateFieldMapping(selectedFields.email, "EMAIL"),
          ...generateFieldMapping(
            selectedFields.streetNumber,
            "LEAD_LOCATION_STREET_NUM",
          ),
          ...generateFieldMapping(
            selectedFields.street,
            "LEAD_LOCATION_STREET",
          ),
          ...generateFieldMapping(
            selectedFields.postCode,
            "LEAD_LOCATION_POSTCODE",
          ),
          ...generateFieldMapping(selectedFields.city, "LEAD_LOCATION_CITY"),
        },
      } as unknown as FieldMappings

      const response = await crmUpdateFieldMappings({
        importId: importId!,
        fieldMappings,
      })

      if ("error" in response) throw new Error("Something went wrong")
    } catch (error) {
      setLoading(false)

      toast.error("An error occurred, please try again later")

      console.error(error)
    }
  }

  useAsyncEffect(async () => {
    if (!uploadIsDone) return

    if (!status?.mappings?.fields || !loading) return

    await localforage.setItem(MAPPING_STEP, 1)

    dispatch(setMappingStep(1))

    clearMessageHistory()
  }, [uploadIsDone, status?.mappings?.fields])

  return (
    <Step
      stepIndex={2}
      title={`📊 We are going to match your CRM data together (1/2)`}
      ctaTitle="Submit your fields"
      onValidate={handleNext}
      ctaLoading={loading}
      ctaDisabled={loading || !selectedFields.name}
      sx={{ maxWidth: "700px", margin: "0 auto" }}
    >
      <Box
        className="hide-scrollbar"
        sx={{ display: "flex", gap: 1.5, flexDirection: "column" }}
      >
        <Box sx={{ display: "flex", paddingLeft: "35px" }}>
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{ fontSize: "14px", width: 160 }}
          >
            Our field
          </Typography>

          <Typography
            variant="h6"
            color="text.secondary"
            sx={{ width: 225, fontSize: "14px" }}
          >
            Your field
          </Typography>

          <Tooltip title="Sample values corresponding to these criteria can be aligned with our specified fields.">
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ ml: 5.5, fontSize: "14px" }}
            >
              Sample value
            </Typography>
          </Tooltip>
        </Box>

        {[
          { key: "name", title: "Company" },
          { key: "fieldStatus", title: "Status" },
          { key: "website", title: "Website" },
          { key: "location", title: "Complete location" },
          ...(showMore
            ? [
                { key: "streetNumber", title: "Street number" },
                { key: "street", title: "Street" },
                { key: "postCode", title: "Post code" },
                { key: "city", title: "City" },
                { key: "email", title: "Email" },
              ]
            : []),
        ].map(({ key, title }) => (
          <LbMappingRow
            key={title}
            value={selectedFields[key] ?? undefined}
            samples={statusSamples}
            onChange={(value) => handleSetField(value, key)}
            row={{
              title,
              userFiled: availableFields,
              hint: getHintForKey(key.toUpperCase(), statusFieldHints),
              tooltip: `The ${title.toLowerCase()} of your lead`,
            }}
          />
        ))}

        <Box sx={{ mt: 1 }}>
          <LbButton onClick={handleShowMore} sx={{ textTransform: "none" }}>
            {showMore ? "Hide optional fields" : "Show more fields"}
          </LbButton>
        </Box>
      </Box>
    </Step>
  )
}
