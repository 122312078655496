import { WEB_APP_ROUTES } from "@leadbay/constants"
import { leadbayApi } from "@leadbay/state/api"
import { useAsyncEffect } from "ahooks"
import { useRef } from "react"

export const HubspotScreen = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get("code")

  const [finishOauthHubspot] = leadbayApi.useFinishOauthHubspotMutation()

  const calledRef = useRef(false)

  useAsyncEffect(async () => {
    try {
      if (calledRef.current) {
        return
      }

      if (code) {
        calledRef.current = true

        await finishOauthHubspot({ code })

        location.href = `${WEB_APP_ROUTES.APP}/${WEB_APP_ROUTES.PRIVATE.INTEGRATIONS}`
      }
    } catch (error) {
      console.error(error)
    } finally {
      calledRef.current = false
    }
  }, [])

  return <div />
}
