import {
  PostAuthLogoutApiArg,
  leadbayApi,
  usePostAuthLogoutMutation,
} from "@leadbay/state/api"
import localforage from "localforage"
import { toast } from "react-toastify"
import { setToken, setUser } from "../../slices/authSlice"
import { setCurrentLensId } from "../../slices/commonsSlice"
import { useAppDispatch } from "../commons/useAppDispatch"

export const useLogout = () => {
  const dispatch = useAppDispatch()

  const [authLogout] = usePostAuthLogoutMutation()

  const logout = async (toastContent?: string) => {
    try {
      const payload: PostAuthLogoutApiArg = {
        authLogoutPostRequest: {
          all: true,
        },
      }

      await authLogout(payload).unwrap()

      if (toastContent) toast(toastContent)
    } catch (error) {
      console.log(error)

      toast.error("An unexpected error occurred.")
    } finally {
      dispatch(setToken(undefined))
      dispatch(setUser(undefined))
      dispatch(setCurrentLensId(""))

      await localforage.clear()

      dispatch(leadbayApi.util.resetApiState())
    }
  }

  return { logout }
}
