import { LbButton } from "@components/feedback/LbButton/LbButton"
import {
  leadbayApi,
  usePostLensesByLensIdLeadsAndLeadIdUntrashMutation,
} from "@leadbay/state/api"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import { selectCommonsState } from "@leadbay/state/slices/commonsSlice"
import { Box } from "@mui/material"
import { toast } from "react-toastify"

interface LbUntrashButtonProps {
  leadId: string
}

export const LbUntrashButton = ({ leadId }: LbUntrashButtonProps) => {
  const { currentLensId } = useAppSelector(selectCommonsState)

  const dispatch = useAppDispatch()

  const [untrashLead] = usePostLensesByLensIdLeadsAndLeadIdUntrashMutation()

  const handleUntrashLead = async () => {
    try {
      await untrashLead({ lensId: currentLensId, leadId }).unwrap()

      dispatch(leadbayApi.util.invalidateTags(["Leads"]))
    } catch (err) {
      console.error(err)
      toast.error("Failed to untrash lead")
    }
  }

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <LbButton onClick={handleUntrashLead} size="small">
        Recover from trash
      </LbButton>
    </Box>
  )
}
