import { LbButton } from "@components/feedback/LbButton/LbButton"
import {
  leadbayApi,
  usePostLeadsByLeadIdDislikeMutation,
} from "@leadbay/state/api"
import { useAppDispatch } from "@leadbay/state/hooks"
import { HeartBroken } from "@mui/icons-material"
import { Box } from "@mui/material"
import { toast } from "react-toastify"

interface LbDislikeButtonProps {
  leadId: string
}

export const LbDislikeButton = ({ leadId }: LbDislikeButtonProps) => {
  const dispatch = useAppDispatch()

  const [dislikeLead] = usePostLeadsByLeadIdDislikeMutation()

  const handleDislikeLead = async () => {
    try {
      await dislikeLead({ leadId }).unwrap()

      dispatch(leadbayApi.util.invalidateTags(["Leads"]))
    } catch (err) {
      console.error(err)
      toast.error("Failed to Dislike lead")
    }
  }

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <LbButton
        onClick={handleDislikeLead}
        size="small"
        startIcon={<HeartBroken />}
      >
        Dislike
      </LbButton>
    </Box>
  )
}
