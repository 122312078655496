import {
  Autocomplete,
  Box,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { type StatuesTypes } from "@screens/onboarding/OnboardingScreen/partials/StepMapping/partials/OurStatuses"
import { memo, useState } from "react"
import { upperCaseFirstLetter } from "@leadbay/utils"

export interface UserDataStatusRow {
  title: string
  userFiled: string[]
}

interface StatusRowProps {
  row: UserDataStatusRow
  value: StatuesTypes | undefined
  onChange: (value: StatuesTypes | null, remove: boolean) => void
}

export const LbStatusRow = memo((props: StatusRowProps) => {
  const { row, onChange, value } = props
  const { title } = row

  const [selectValue, setSelectValue] = useState<string | null>(
    value?.[title] ?? null,
  )
  const [inputValue, setInputValue] = useState("")

  const handleChange = (newValue: string | null) => {
    setSelectValue(newValue ?? null)

    onChange(
      {
        [title]: newValue?.toLocaleUpperCase() ?? "",
      } as unknown as StatuesTypes,
      newValue === null,
    )
  }
  return (
    <Box sx={{ position: "relative", display: "flex", alignItems: "center" }}>
      <Box
        sx={[
          (theme) => ({
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: theme.palette.secondary.main,
            px: "35px",
            py: "15px",
            gap: 1,
            borderRadius: "24px",
          }),
        ]}
      >
        <Tooltip title={title} placement="bottom">
          <Typography
            variant="h6"
            sx={{
              maxWidth: "380px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              mr: 2,
            }}
          >
            {title}
          </Typography>
        </Tooltip>

        <Autocomplete
          value={selectValue ? upperCaseFirstLetter(selectValue) : null}
          options={["Wanted", "Won", "Lost"]}
          sx={{ width: 220 }}
          inputValue={inputValue}
          isOptionEqualToValue={(option, value) => option === value}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue)
          }}
          onChange={(_, newValue: string | null) => {
            handleChange(newValue)
          }}
          renderInput={(params) => (
            <TextField label="Choose a status" {...params} />
          )}
        />
      </Box>
    </Box>
  )
})
