import { LbFade } from "@components/animations/LbFade/LbFade"
import { LBLogo } from "@components/display/LbLogo/LBLogo"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { CENTERED_FLEX_COL } from "@leadbay/constants"
import {
  GetOauthApplicationsByAppIdApiResponse,
  useGetOauthApplicationsByAppIdQuery,
  usePostOauthGrantsMutation,
} from "@leadbay/state/api"
import { useAppSelector } from "@leadbay/state/hooks"
import { selectAuthState } from "@leadbay/state/slices/authSlice"
import { ArrowRightAlt } from "@mui/icons-material"
import { Paper, Typography } from "@mui/material"
import Box from "@mui/material/Box/Box"

import { useLayoutEffect, useState } from "react"
import { toast } from "react-toastify"

interface ZapierRequestParams {
  client_id: string
  state: string
  redirect_uri: string
  response_type: "code"
}

const fetchUrlParams = (): ZapierRequestParams => {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  const paramsObject: ZapierRequestParams = {
    client_id: "",
    state: "",
    redirect_uri: "",
    response_type: "code",
  }

  for (const param of params) {
    // @ts-expect-error:next-line - TS doesn't recognize the destructured param
    paramsObject[param[0]] = param[1]
  }

  return paramsObject
}

export const OauthScreen = () => {
  const { token } = useAppSelector(selectAuthState)
  const [grantAccess] = usePostOauthGrantsMutation()

  const [canceled, setCanceled] = useState(false)
  const [grantLoading, setGrantLoading] = useState(false)
  const [isFetchError, setIsFetchError] = useState(false)
  const [zapierRequestParams, setZapierRequestParams] = useState<
    ZapierRequestParams | undefined
  >()

  const { data, isFetching } = useGetOauthApplicationsByAppIdQuery(
    {
      appId: zapierRequestParams?.client_id!,
    },
    {
      skip: !zapierRequestParams,
    },
  )

  useLayoutEffect(() => {
    const paramsObject = fetchUrlParams()

    setZapierRequestParams(paramsObject as ZapierRequestParams)
  }, [])

  const connectZapier = async () => {
    try {
      setGrantLoading(true)

      const zapierRequestParams = fetchUrlParams()

      if (zapierRequestParams.response_type !== "code") {
        throw new Error("Invalid response type")
      }

      const url = `${import.meta.env.VITE_LEADBAY_API_BASE_URL}/oauth/applications/${zapierRequestParams?.client_id}`

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })

      if (!response.ok) {
        throw new Error("Failed to fetch application data")
      }

      const data: GetOauthApplicationsByAppIdApiResponse = await response.json()

      if (!data?.redirect_uris.includes(zapierRequestParams.redirect_uri)) {
        throw new Error("Invalid redirect uri")
      }

      const { code } = await grantAccess({
        oAuthCreateGrant: {
          application_id: zapierRequestParams.client_id,
          redirect_uri: zapierRequestParams.redirect_uri,
        },
      }).unwrap()

      window.location.href = `${zapierRequestParams.redirect_uri}?code=${code}&state=${zapierRequestParams.state}`
    } catch (error) {
      setGrantLoading(false)

      setIsFetchError(true)

      const e = error as Error

      console.error(e)

      toast.error(e.message)
    }
  }

  const handleCloseTab = () => {
    window.close()
  }

  if (isFetching) return null

  if (isFetchError)
    return (
      <Box sx={{ minHeight: "100vh", ...CENTERED_FLEX_COL }}>
        <Box sx={{ minHeight: "100vh", ...CENTERED_FLEX_COL }}>
          <Typography variant="h6" color="text.secondary" sx={{ mb: 2 }}>
            An error occurred, please try again later.
          </Typography>

          <LbButton type="button" onClick={handleCloseTab}>
            Close tab
          </LbButton>
        </Box>
      </Box>
    )

  if (canceled)
    return (
      <Box sx={{ minHeight: "100vh", ...CENTERED_FLEX_COL }}>
        <Box sx={{ minHeight: "100vh", ...CENTERED_FLEX_COL }}>
          <Typography variant="h6" color="text.secondary" sx={{ mb: 2 }}>
            You have canceled the authorization process, you can close this tab
            now.
          </Typography>

          <LbButton type="button" onClick={handleCloseTab}>
            Close tab
          </LbButton>
        </Box>
      </Box>
    )

  return (
    <LbFade styles={{ minHeight: "100vh", ...CENTERED_FLEX_COL }}>
      <Box sx={{ ...CENTERED_FLEX_COL }} id="oauth-screen">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <img width={50} src={data?.logo} alt={data?.name} />
          <ArrowRightAlt /> <LBLogo width={50} small />
        </Box>

        <Paper elevation={0} sx={{ mt: 4, px: 3, py: 2 }}>
          <Box sx={{ p: 2 }}>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Allow Zapier to access your Leadbay COMPASS account?
            </Typography>

            <Box
              sx={{ display: "flex", justifyContent: "center", gap: 3, mt: 2 }}
            >
              <LbButton
                type="button"
                loading={grantLoading}
                onClick={connectZapier}
              >
                {grantLoading ? "Loading..." : "Allow"}
              </LbButton>

              <LbButton
                type="button"
                onClick={() => setCanceled(true)}
                variant="text"
              >
                Cancel
              </LbButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </LbFade>
  )
}
