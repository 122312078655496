import {
  DARK_COLORS,
  GLOBAL_MUI_STYLES,
  LIGHT_COLORS,
} from "@leadbay/constants"
import { useAppSelector } from "@leadbay/state/hooks"
import { selectCommonsState } from "@leadbay/state/slices/commonsSlice"
import { createTheme, type PaletteOptions, ThemeProvider } from "@mui/material"
import { type ReactNode, useMemo } from "react"

interface MuiThemeProviderProps {
  children: ReactNode
}

export const MuiThemeProvider = (props: MuiThemeProviderProps) => {
  const { children } = props

  const { themeMode } = useAppSelector(selectCommonsState)

  const getDesignToken = (): PaletteOptions =>
    themeMode === "dark" ? DARK_COLORS : LIGHT_COLORS

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          ...getDesignToken(),
        },
        ...GLOBAL_MUI_STYLES,
      }),
    [themeMode],
  )

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
