import { LbButton } from "@components/feedback/LbButton/LbButton"
import { LbPasswordField } from "@components/inputs/LbPasswordField/LbPasswordField"
import { yupResolver } from "@hookform/resolvers/yup"
import { CENTERED_FLEX_ROW_START, WEB_APP_ROUTES } from "@leadbay/constants"
import { useLogin } from "@leadbay/state/hooks"
import { Grid, TextField, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"
import { object, string } from "yup"

export interface LoginScreenValues {
  email: string
  password: string
}

const validationSchema = object().shape({
  email: string()
    .required()
    .matches(
      /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Please enter a valid email address",
    ),
  password: string().required().min(1),
})

const LoginScreen = () => {
  const { login, isLoading } = useLogin()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<LoginScreenValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const handleFormSubmit: SubmitHandler<LoginScreenValues> = async (data) => {
    await login({
      email: data.email,
      password: data.password,
    })
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} id="login-screen">
      <Grid
        container
        spacing={2}
        rowGap={7}
        sx={{
          maxWidth: "550px",
          margin: "0 auto",
        }}
      >
        <Grid item xs={12}>
          <Box component="header">
            <Typography
              component="h1"
              variant="h2"
              fontFamily="BasementGrotesque"
              fontSize={40}
              sx={{ textTransform: "uppercase" }}
            >
              welcome to <br /> the future of
              <Typography
                fontFamily="Reenie Beanie"
                component="span"
                variant="h2"
                fontSize={64}
                marginLeft={1}
                sx={{ lineHeight: 0.9 }}
              >
                Sales
              </Typography>
              <Box sx={{ width: "94%", height: 6, background: "#61E786" }} />
            </Typography>

            <Typography
              sx={{ mt: 1 }}
              variant="h6"
              component="h2"
              color="text.secondary"
            >
              Login to your account
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                error={!!errors.email}
                type="email"
                fullWidth
                helperText={errors.email?.message}
                sx={{
                  ".MuiFormHelperText-root": {
                    position: "absolute",
                    top: "105%",
                  },
                }}
                label="Email"
                placeholder="name@company.com"
                {...field}
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <LbPasswordField
                label="Password"
                placeholder="********"
                fullWidth
                required
                error={!!errors.password}
                helperText={errors.password?.message}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <LbButton
            id="login-button"
            variant="contained"
            color="primary"
            size="large"
            disabled={!isValid || isLoading}
            type="submit"
            sx={{ marginBottom: 3 }}
            loading={isLoading}
          >
            Login
          </LbButton>

          <Box>
            <Box sx={{ ...CENTERED_FLEX_ROW_START, gap: 0.5 }}>
              <Typography> Forgot your password ?</Typography>

              <LbButton
                variant="text"
                color="primary"
                component={RouterLink}
                to={WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.FORGOT_PASSWORD}
                sx={{ textTransform: "capitalize" }}
              >
                Reset password
              </LbButton>
            </Box>

            <Box sx={{ ...CENTERED_FLEX_ROW_START, gap: 0.5 }}>
              <Typography>Don’t have an account?</Typography>

              <LbButton
                id="register-button"
                variant="text"
                color="primary"
                component={RouterLink}
                to={WEB_APP_ROUTES.ROOT}
                sx={{ textTransform: "capitalize" }}
              >
                Register
              </LbButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default LoginScreen
