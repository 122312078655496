import { DEFAULT_LENS } from "@leadbay/constants"
import { SelectChangeEvent } from "@mui/material"
import { useMemo } from "react"
import { useGetLensesQuery } from "../../api"
import { selectAuthState } from "../../slices/authSlice"
import { selectCommonsState, setCurrentLensId } from "../../slices/commonsSlice"
import { useAppDispatch, useAppSelector } from "../index"

export const useManageLenses = () => {
  const dispatch = useAppDispatch()

  const { token } = useAppSelector(selectAuthState)

  const { data: lenses } = useGetLensesQuery(undefined, {
    skip: !token,
  })

  const { currentLensId } = useAppSelector(selectCommonsState)

  const handleChangeLens = async (event: SelectChangeEvent) => {
    if (event.target.value) {
      dispatch(setCurrentLensId(event.target.value))
    }
  }

  const userLenses = useMemo(
    () =>
      lenses ? [...lenses].sort((a) => (a.name === DEFAULT_LENS ? 1 : -1)) : [],
    [lenses],
  )

  return { userLenses, currentLensId, handleChangeLens }
}
