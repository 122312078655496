import { useAppSelector } from "@leadbay/state/hooks"
import { selectAuthState } from "@leadbay/state/slices/authSlice"
import { Box, Button, Divider, Grid, Typography } from "@mui/material"
import { useState } from "react"
import { UpdatePassword } from "./partials/UpdatePassword/UpdatePassword"

const AccountScreen = () => {
  const { user } = useAppSelector(selectAuthState)

  const [showPasswordUpdate, setShowPasswordUpdate] = useState(false)

  const handleShowPasswordUpdate = () => {
    setShowPasswordUpdate(!showPasswordUpdate)
  }

  return (
    <Box
      id="account-screen"
      component="article"
      sx={{
        maxHeight: "calc(100vh - 30px)",
        minWidth: "600px",
        mt: 6,
        mb: 14,
        justifySelf: "start",
      }}
    >
      <Box component="header">
        <Typography variant="h4" fontFamily="BasementGrotesque">
          My Account
        </Typography>

        <Typography variant="h6" sx={{ my: 2 }} color="text.secondary">
          Manage your account settings and set e-mail preferences.
        </Typography>
      </Box>

      <Divider sx={{ mt: 5, mb: 2 }} />

      <Box component="section">
        <Box
          component="header"
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h6" fontFamily="Hanken Grotesk">
              Information
            </Typography>

            <Typography color="text.secondary">
              User account information.
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              fontFamily="Hanken Grotesk"
              fontSize="1.1rem"
            >
              Name
            </Typography>
          </Grid>

          <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Typography sx={{ px: 1 }} variant="body1" color="text.secondary">
                {user?.name}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              fontFamily="Hanken Grotesk"
              fontSize="1.1rem"
            >
              Email
            </Typography>
          </Grid>

          <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Typography sx={{ px: 1 }} variant="body1" color="text.secondary">
                {user?.email}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              fontFamily="Hanken Grotesk"
              fontSize="1.1rem"
            >
              Role
            </Typography>
          </Grid>

          <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Typography sx={{ px: 1 }} variant="body1" color="text.secondary">
                {user?.admin ? "Admin" : "User"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ mt: 4, mb: 3 }} />

      <Box component="section">
        <Box
          component="header"
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h6" fontFamily="Hanken Grotesk">
              Security
            </Typography>

            <Typography color="text.secondary">
              Manage account security.
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              fontFamily="Hanken Grotesk"
              fontSize="1.1rem"
            >
              Password
            </Typography>
          </Grid>

          <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Button
                onClick={handleShowPasswordUpdate}
                variant="text"
                sx={{ textTransform: "none" }}
              >
                Modify your password
              </Button>

              <Typography
                sx={{ px: 1, mt: 2 }}
                variant="body1"
                color="text.secondary"
              >
                ****************
              </Typography>
            </Box>
          </Grid>

          <UpdatePassword
            handleShowPasswordUpdate={handleShowPasswordUpdate}
            showPasswordUpdate={showPasswordUpdate}
          />
        </Grid>
      </Box>
    </Box>
  )
}

export default AccountScreen
