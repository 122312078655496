import { Box, Chip, Divider, Slider } from "@mui/material"
import { useState } from "react"
import { upperCaseFirstLetter } from "@leadbay/utils"

const marks = [
  {
    value: 0,
    label: "Low",
  },
  {
    value: 100,
    label: "High",
  },
]

export interface LbScoreFilterProps {
  label?: string
  value: number
  elements: string[]
  isHighlighted?: boolean
  onChange: (newValue: number) => void
  onDelete: () => void
}

export const LbScoreFilter = (props: LbScoreFilterProps) => {
  const {
    value: localValue,
    onChange,
    elements,

    isHighlighted,
    onDelete,
  } = props

  const [localScore, setLocalScore] = useState<number>(localValue)

  const handleChange = (newValue: number) => {
    onChange(newValue)
    setLocalScore(newValue)
  }

  return (
    <>
      <Divider sx={{ mt: 2, mb: 3 }} />

      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
        {elements.map((element, index) => (
          <Chip
            key={element + index}
            variant={isHighlighted ? "filled" : "outlined"}
            color={isHighlighted ? "primary" : "default"}
            label={upperCaseFirstLetter(element)}
            sx={{ fontWeight: "bold" }}
            onDelete={onDelete}
          />
        ))}
      </Box>

      <Box sx={{ mt: 5 }}>
        <Slider
          min={0}
          max={100}
          step={1}
          value={localScore}
          defaultValue={localScore}
          onChange={(_, newValue: number | number[]) => {
            handleChange(newValue as number)
          }}
          valueLabelDisplay="on"
          marks={marks}
        />
      </Box>
    </>
  )
}
