import { useAsyncEffect } from "ahooks"
import JSConfetti from "js-confetti"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import {
  leadbayApi,
  useGetLensesByLensIdLeadsNewCountsQuery,
  useGetTimelineNewCountQuery,
} from "../../api"
import { selectCommonsState } from "../../slices/commonsSlice"
import { useAppDispatch, useAppSelector } from "../index"

export const useCountSeenLeads = () => {
  const dispatch = useAppDispatch()

  const { currentLensId, selectedIds } = useAppSelector(selectCommonsState)

  const { data: newCountData } = useGetLensesByLensIdLeadsNewCountsQuery(
    {
      lensId: currentLensId,
    },
    {
      skip: !currentLensId,
    },
  )

  const { data: timelineNewCountData } = useGetTimelineNewCountQuery()

  const [progress, setProgress] = useState(0)
  const [goalReached, setGoalReached] = useState(false)
  const [retryCount, setRetryCount] = useState(0)

  const newMonitorLeads = newCountData?.monitor ?? 0
  const newWishedLeads = newCountData?.wished ?? 0
  const newTimelineLeads = timelineNewCountData ?? 0

  const totalNewLeads = useMemo(
    () => newMonitorLeads + newWishedLeads + newTimelineLeads,
    [newMonitorLeads, newWishedLeads, newTimelineLeads],
  )

  const seenLeadCount = useMemo(() => selectedIds.length, [selectedIds])

  useAsyncEffect(async () => {
    if (!newCountData || totalNewLeads === 0) return

    if (totalNewLeads === 0) {
      setProgress(100)
    } else {
      const progress = selectedIds.length / totalNewLeads
      const seenLeads = Math.ceil(progress * 100)

      if (seenLeads > 100 && retryCount <= 0) {
        dispatch(leadbayApi.util.invalidateTags(["Leads", "Timeline"]))

        setRetryCount(retryCount + 1)
      } else {
        setProgress(Math.min(seenLeads, 100))
      }

      if (seenLeads === 100) {
        setGoalReached(true)
      }
    }
  }, [selectedIds])

  useEffect(() => {
    if (!goalReached) return

    toast.success(
      "🎉 you're all caught up with new leads. Go close some deals.",
    )

    const jsConfetti = new JSConfetti()

    jsConfetti.addConfetti().catch((err) => {
      console.log(err)
    })
  }, [goalReached])

  return {
    progress,
    goalReached,
    totalNewLeads,
    seenLeadCount,
  }
}
