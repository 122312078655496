import { LbButton } from "@components/feedback/LbButton/LbButton"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import {
  selectCommonsState,
  setDeferredPrompt,
} from "@leadbay/state/slices/commonsSlice"
import { Close, InstallDesktop } from "@mui/icons-material"
import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"

interface LbInstalButtonProps {
  onToggle: (value: boolean) => void
}

export const LbInstalButton = ({ onToggle }: LbInstalButtonProps) => {
  const dispatch = useAppDispatch()

  const { deferredPrompt } = useAppSelector(selectCommonsState)

  const [isChrome, setIsChrome] = useState(false)
  const [isFirefox, setIsFirefox] = useState(false)

  const [firefoxModal, setFirefoxModal] = useState(false)

  const installPWA = async () => {
    if (!deferredPrompt) return

    await deferredPrompt.prompt()

    const choiceResult = await deferredPrompt.userChoice
    if (choiceResult.outcome === "accepted") {
      console.log("User accepted the A2HS prompt")
    } else {
      console.log("User dismissed the A2HS prompt")
    }

    dispatch(setDeferredPrompt(null))
  }

  useEffect(() => {
    const userAgent = window.navigator.userAgent
    const isChrome = userAgent.includes("Chrome")
    const isEdge = userAgent.includes("Edg")
    const isFirefox = userAgent.includes("Firefox")

    const supportsPWA = "BeforeInstallPromptEvent" in window

    setIsChrome((isChrome || isEdge) && supportsPWA)

    setIsFirefox(isFirefox)
  }, [])

  return (
    <>
      {isChrome && deferredPrompt && (
        <ListItem dense disablePadding onClick={() => onToggle(false)}>
          <ListItemButton onClick={installPWA}>
            <ListItemIcon sx={{ color: "inherit" }}>
              <InstallDesktop />
            </ListItemIcon>

            <ListItemText>
              <Typography variant="subtitle2" component="span" fontWeight={900}>
                Install desktop app
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      )}

      {isFirefox && (
        <>
          <ListItem dense disablePadding>
            <ListItemButton onClick={() => setFirefoxModal(true)}>
              <ListItemIcon sx={{ color: "inherit" }}>
                <InstallDesktop />
              </ListItemIcon>

              <ListItemText>
                <Typography
                  variant="subtitle2"
                  component="span"
                  fontWeight={900}
                >
                  Install desktop app
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>

          <Modal open={firefoxModal} onClose={() => setFirefoxModal(false)}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "20px",
                p: 4,
              }}
            >
              <Box sx={{ position: "absolute", top: 2, right: 5 }}>
                <IconButton onClick={() => setFirefoxModal(false)}>
                  <Close />
                </IconButton>
              </Box>

              <Typography id="modal-modal-title" variant="h6" component="h2">
                Firefox desktop app installation
              </Typography>

              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3 }}>
                To get Leadbay installed on your desktop, kindly add this
                extension to Firefox and adhere to the guidelines provided
                within the extension.
              </Typography>

              <a
                href="https://addons.mozilla.org/en-US/firefox/addon/pwas-for-firefox/"
                target="_blank"
                rel="noreferrer"
              >
                <LbButton onClick={() => onToggle(false)}>
                  Progressive Web Apps for Firefox
                </LbButton>
              </a>
            </Box>
          </Modal>
        </>
      )}
    </>
  )
}
