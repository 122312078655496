import { InfoRounded } from "@mui/icons-material"
import {
  Autocomplete,
  Box,
  IconButton,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { useClickAway } from "ahooks"
import { motion } from "framer-motion"
import { useMemo, useRef, useState, type SyntheticEvent } from "react"
import { upperCaseFirstLetter } from "@leadbay/utils"

export interface UserDataMappingRow {
  title: string
  userFiled: string[]
  hint?: string
  tooltip: string
}

interface MappingRowProps {
  row: UserDataMappingRow
  value: string | undefined
  samples: Array<Record<string, string>> | undefined
  onChange: (value: string | null) => void
}

export const LbMappingRow = (props: MappingRowProps) => {
  const { row, onChange, value, samples } = props
  const { userFiled, title, tooltip } = row

  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [inputValue, setInputValue] = useState("")

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(anchorEl ? null : (event.currentTarget as HTMLElement))
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popper" : undefined

  useClickAway(() => {
    setAnchorEl(null)
  }, buttonRef)

  const options = useMemo(
    () => userFiled.map((item) => upperCaseFirstLetter(item)),
    [userFiled],
  )

  const handleChange = (newValue: string | null) => {
    const userFiledValue =
      userFiled.find((item) => upperCaseFirstLetter(item) === newValue) ?? ""

    onChange(userFiledValue)
  }

  const sampleValue = useMemo(() => {
    const computedValue = samples
      ?.map((item) => item[value ?? -1] ?? "")
      .filter((item) => item !== "")

    if (!computedValue) return []

    return computedValue[Math.floor(Math.random() * computedValue.length)]
  }, [value])

  return (
    <Box sx={{ position: "relative", display: "flex", alignItems: "center" }}>
      <Box sx={{ position: "absolute", left: -70 }}>
        <IconButton
          component={motion.button}
          animate={{ opacity: 1, translateY: 0 }}
          initial={{ opacity: 0, translateY: -10 }}
          transition={{ ease: "linear", delay: 0.4 }}
          ref={buttonRef}
          sx={{
            left: 0,
          }}
          onClick={handleClick}
        >
          <InfoRounded />
        </IconButton>

        <Popper id={id} open={open} anchorEl={anchorEl}>
          <Box
            sx={{
              border: 1,
              p: 3,
              background: "white",
              maxWidth: 300,
              borderRadius: "20px",
            }}
          >
            <Typography variant="h6" lineHeight="1">
              {title} field
            </Typography>

            <Typography sx={{ mt: 1 }}>{tooltip}</Typography>
          </Box>
        </Popper>
      </Box>

      <Box
        sx={[
          (theme) => ({
            flex: 1,
            display: "flex",
            alignItems: "center",
            background: theme.palette.secondary.main,
            px: "35px",
            py: "15px",
            gap: 1,
            borderRadius: "24px",
            width: 250,
          }),
        ]}
      >
        <Typography sx={{ width: 150 }} variant="h6">
          {title}
        </Typography>

        <Autocomplete
          value={value && value !== "" ? upperCaseFirstLetter(value) : null}
          disablePortal
          options={options}
          sx={{ width: 250, mr: 2 }}
          inputValue={inputValue}
          isOptionEqualToValue={(option, value) => option === value}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue)
          }}
          onChange={(_, newValue: string | null) => {
            handleChange(newValue)
          }}
          renderInput={(params) => <TextField {...params} />}
          autoComplete={false}
          spellCheck={false}
        />

        {sampleValue && sampleValue?.length > 0 && (
          <Tooltip title={sampleValue}>
            <Typography
              sx={{
                mt: 1,
                textAlign: "left",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                flex: 1,
                cursor: "zoom-in",
              }}
              variant="subtitle2"
              color="text.secondary"
            >
              <strong>{sampleValue}</strong>
            </Typography>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}
