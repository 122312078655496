import { LbFade } from "@components/animations/LbFade/LbFade"
import { LbLottie } from "@components/animations/LbLottie/LbLottie"
import { CENTERED_FLEX_COL } from "@leadbay/constants"
import { Refresh } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { LbButton } from "../LbButton/LbButton"

export const LbAppError = () => {
  const handleReload = () => {
    window.location.reload()
  }

  return (
    <LbFade>
      <Box sx={{ ...CENTERED_FLEX_COL }}>
        <LbLottie type="danger" loop style={{ width: "100px" }} />

        <Typography sx={{ mb: 4 }}>An error occurred</Typography>

        <LbButton onClick={handleReload} startIcon={<Refresh />}>
          Reload
        </LbButton>
      </Box>
    </LbFade>
  )
}
