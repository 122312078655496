import { Search } from "@mui/icons-material"
import {
  Badge,
  Box,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material"
import { ChangeEvent, useEffect } from "react"

import {
  leadbayApi,
  useGetLensesByLensIdLeadsNewCountsQuery,
  useGetTimelineNewCountQuery,
} from "@leadbay/state/api"
import {
  selectCommonsState,
  setInitialCountData,
  setWishlistViewMode,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"

import {
  useAppDispatch,
  useAppSelector,
  useSavedLeads,
} from "@leadbay/state/hooks"
import { LbNewLeadSeenProgress } from "./LbNewLeadSeenProgress"

interface LbDataGridSearchProps {
  onSearch: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  searchValue?: string
}

const toggleButtonSx = { textTransform: "capitalize", height: "100%" }

interface ToggleButtonConfig {
  value: WishlistViewMode | string
  onClick: () => void
  title?: string
  badgeContent?: number
  badgeInvisible?: boolean
  label: string
  disabled?: boolean
}

export const LbDataGridSearch = ({
  onSearch,
  searchValue,
}: LbDataGridSearchProps) => {
  const dispatch = useAppDispatch()

  const { wishlistViewMode, initialCountData, currentLensId, selectedIds } =
    useAppSelector(selectCommonsState)

  const {
    data: countData,
    refetch: refetchCountData,
    isUninitialized: isCountDataUninitialized,
  } = useGetLensesByLensIdLeadsNewCountsQuery(
    { lensId: currentLensId },
    { skip: !currentLensId },
  )

  const {
    data: newCountTimelineData,
    refetch: refetchNewCountTimelineData,
    isUninitialized: isNewCountTimelineDataUninitialized,
  } = useGetTimelineNewCountQuery()

  const toggleViewMode = (mode: WishlistViewMode) => {
    dispatch(setWishlistViewMode(mode))
  }

  useEffect(() => {
    if (!isCountDataUninitialized) refetchCountData()
    if (!isNewCountTimelineDataUninitialized) refetchNewCountTimelineData()
  }, [selectedIds.length])

  useEffect(() => {
    if (!initialCountData && countData) {
      dispatch(setInitialCountData(countData))
    }
  }, [initialCountData, countData, dispatch])

  const { countSavedLeads } = useSavedLeads()

  const toggleButtonsConfig: ToggleButtonConfig[] = [
    {
      value: WishlistViewMode.TIMELINE,
      onClick: () => {
        toggleViewMode(WishlistViewMode.TIMELINE)
        dispatch(leadbayApi.util.invalidateTags(["Leads"]))
      },
      title: "View a timeline of all activities",
      badgeContent: newCountTimelineData,
      badgeInvisible: !newCountTimelineData || newCountTimelineData <= 0,
      label: "Timeline",
    },
    {
      value: WishlistViewMode.DISCOVER,
      onClick: () => {
        toggleViewMode(WishlistViewMode.DISCOVER)
        dispatch(leadbayApi.util.invalidateTags(["Leads"]))
      },
      title: "Discover new leads",
      badgeContent: countData?.wished,
      badgeInvisible: !(countData?.wished && countData.wished > 0),
      label: "Discover",
    },
    {
      value: WishlistViewMode.MONITOR,
      onClick: () => {
        toggleViewMode(WishlistViewMode.MONITOR)
        dispatch(leadbayApi.util.invalidateTags(["Leads"]))
      },
      title: "Monitor previously identified leads",
      badgeContent: countData?.monitor,
      badgeInvisible: !(countData?.monitor && countData.monitor > 0),
      label: "Monitor",
    },
    {
      value: WishlistViewMode.SAVED_LEADS,
      onClick: () => {
        toggleViewMode(WishlistViewMode.SAVED_LEADS)
        dispatch(leadbayApi.util.invalidateTags(["Leads"]))
      },
      badgeContent: countSavedLeads,
      badgeInvisible: countSavedLeads === 0,
      label: "Saved leads",
      disabled: countSavedLeads === 0,
    },
  ]

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        width: "100%",
        gap: 3,
        position: "sticky",
        top: "30px",
        pt: "16px",
        pb: "11px",
        zIndex: 100,
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
      }}
    >
      <Box sx={{ display: "flex", gap: 3, alignItems: "flex-start" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          <ToggleButtonGroup value={[wishlistViewMode]} size="small">
            {toggleButtonsConfig.map((config) => (
              <Tooltip key={config.value} title={config.title || ""}>
                <Badge
                  invisible={config.badgeInvisible}
                  color="primary"
                  badgeContent={config.badgeContent}
                >
                  <ToggleButton
                    value={config.value}
                    onClick={config.onClick}
                    sx={toggleButtonSx}
                    disabled={config.disabled}
                  >
                    {config.label}
                  </ToggleButton>
                </Badge>
              </Tooltip>
            ))}
          </ToggleButtonGroup>

          <LbNewLeadSeenProgress />
        </Box>
      </Box>

      {![WishlistViewMode.TIMELINE, WishlistViewMode.SAVED_LEADS].includes(
        wishlistViewMode,
      ) && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            value={searchValue}
            sx={{ width: 220 }}
            onChange={onSearch}
            InputProps={{
              type: "search",
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            size="small"
            label="Search"
          />
        </Box>
      )}
    </Box>
  )
}
