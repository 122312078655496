import { LbButton } from "@components/feedback/LbButton/LbButton"
import { MAPPING_STEP } from "@leadbay/constants"
import { useGetImportsQuery } from "@leadbay/state/api"
import { useAppDispatch } from "@leadbay/state/hooks"
import {
  setCurrentStep,
  setMappingStep,
  setSelectedFile,
} from "@leadbay/state/slices/onboardingSlice"
import { Add } from "@mui/icons-material"
import { Box, Divider, List, Skeleton, Typography } from "@mui/material"
import localforage from "localforage"
import { useState } from "react"
import { Fragment } from "react/jsx-runtime"
import { analytics } from "@leadbay/utils"
import { DataSource } from "./DataSource"
import { UploadDataSourceModal } from "./modals/UploadDataSourceModal"

export const DataSources = () => {
  const dispatch = useAppDispatch()

  const {
    data: importData,
    refetch: refetchImportData,
    isLoading: isImportFetching,
  } = useGetImportsQuery()

  const [openUploadModal, setOpenUploadModal] = useState(false)

  const handleToggleUploadModal = async (config?: { noConfirm: boolean }) => {
    if (openUploadModal && !config?.noConfirm) {
      const confirm = window.confirm(
        "Are you sure you want to cancel the export, all progress will be lost",
      )

      if (!confirm) return
    }

    if (!openUploadModal) {
      analytics.startImportingDatasource()
    } else {
      analytics.finishImportingDatasource()
    }

    setOpenUploadModal(!openUploadModal)

    dispatch(setCurrentStep(1))
    await localforage.removeItem(MAPPING_STEP)
    dispatch(setSelectedFile(undefined))
    dispatch(setMappingStep(0))

    await refetchImportData()
  }

  return (
    <section>
      <Box component="header">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            fontFamily="BasementGrotesque"
          >
            Data sources
          </Typography>

          {importData && importData?.length > 0 && (
            <Typography
              variant="caption"
              color="text.secondary"
              fontWeight="bold"
            >
              {`${importData?.length} data source${importData?.length !== 1 ? "s" : ""} connected`}
            </Typography>
          )}
        </Box>

        <Typography variant="h6" color="text.secondary">
          Link your data sources with your Leadbay account.
        </Typography>

        <Box sx={{ mt: 2 }}>
          <LbButton
            size="small"
            onClick={async () => await handleToggleUploadModal()}
            startIcon={<Add />}
          >
            Add new data sources
          </LbButton>
        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        {!isImportFetching ? (
          <>
            {!importData?.length ? (
              <Box sx={{ textAlign: "center", mt: 8 }}>
                <Typography variant="subtitle1" color="text.secondary">
                  No data sources connected
                </Typography>
              </Box>
            ) : (
              <List
                sx={{
                  width: "100%",
                }}
              >
                {importData?.map((imported, index) => (
                  <Fragment key={index}>
                    {index > importData.length - 1 && (
                      <Divider variant="inset" component="li" />
                    )}

                    <DataSource imported={imported} />
                  </Fragment>
                ))}
              </List>
            )}
          </>
        ) : (
          <Skeleton sx={{ width: "124px", height: "230px" }} />
        )}
      </Box>

      <UploadDataSourceModal
        open={openUploadModal}
        onClose={handleToggleUploadModal}
      />
    </section>
  )
}
