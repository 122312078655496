import { LbFade } from "@components/animations/LbFade/LbFade"
import { LbCopyright } from "@components/display/LbCopyright/LbCopyright"
import { LBLogo } from "@components/display/LbLogo/LBLogo"
import {
  APP_BAR_HEIGHT,
  CENTERED_FLEX_COL,
  CENTERED_FLEX_ROW_START,
  WEB_APP_ROUTES,
} from "@leadbay/constants"
import { useAppSelector } from "@leadbay/state/hooks"
import { selectAuthState } from "@leadbay/state/slices/authSlice"
import { Box, Container } from "@mui/material"
import { Link, Navigate, Outlet, useLocation } from "react-router-dom"

export const EmptyLayout = () => {
  const { user } = useAppSelector(selectAuthState)

  const location = useLocation()

  const isOnboardingProcess =
    location.pathname ===
      WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PRIVATE.ONBOARDING ||
    location.pathname ===
      WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.VERIFY_EMAIL ||
    user?.organization?.onboarding_step !== "FINISHED"

  if (user?.verified && !isOnboardingProcess) {
    const to =
      (location.state?.from as string | undefined) || WEB_APP_ROUTES.APP
    return <Navigate to={to} />
  }

  if (
    user?.verified &&
    location.pathname !==
      WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PRIVATE.ONBOARDING &&
    user?.organization?.onboarding_step !== "FINISHED"
  ) {
    return <Navigate to={WEB_APP_ROUTES.PRIVATE.ONBOARDING} />
  }

  return (
    <>
      <Box
        component="nav"
        sx={{
          px: 4,
          height: APP_BAR_HEIGHT,
          ...CENTERED_FLEX_ROW_START,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <Link to={WEB_APP_ROUTES.ROOT}>
          <LBLogo />
        </Link>
      </Box>

      <LbFade>
        <Container
          component="main"
          sx={{
            height: `calc(100vh - 60px)`,
            ...CENTERED_FLEX_COL,
          }}
        >
          <Outlet />
        </Container>

        <Box component="aside">
          <LbCopyright />
        </Box>
      </LbFade>
    </>
  )
}
