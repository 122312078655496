import { LbWishlistCrud } from "@components/navigation/LbMainDrawer/partials/LbWishListFilters/LbWishlistCrud"
import {
  leadbayApi,
  useGetLensesByLensIdFilterQuery,
  usePostLensesByLensIdFilterMutation,
  type Filter,
  type FilterCriterion,
} from "@leadbay/state/api"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import {
  selectCommonsState,
  setWishListLoading,
} from "@leadbay/state/slices/commonsSlice"
import { Box, Chip, CircularProgress, Paper, Typography } from "@mui/material"
import { useMemo, useState } from "react"
import { toast } from "react-toastify"
import {
  analytics,
  generateLocationString,
  upperCaseFirstLetter,
} from "@leadbay/utils"

// TODO: remove all ts expect error

export interface LbWishlistFiltersCardProps {
  label?: string
  filterItem?: FilterCriterion
  variant?: "filled" | "outlined"
  readonly?: boolean
  icp: Filter
  isDefaultLens: boolean
}

export const LbFilterCard = (props: LbWishlistFiltersCardProps) => {
  const {
    label,
    filterItem,
    isDefaultLens,
    variant = "filled",
    readonly,
    icp,
  } = props

  const dispatch = useAppDispatch()

  const [showInput, setShowInput] = useState(false)

  const { currentLensId } = useAppSelector(selectCommonsState)

  const { refetch: refetchICP } = useGetLensesByLensIdFilterQuery(
    {
      lensId: currentLensId,
    },
    {
      skip: !currentLensId,
    },
  )

  const [postOrganizationsByOrgIdIcp, { isLoading }] =
    usePostLensesByLensIdFilterMutation()

  const filteredList = useMemo(() => {
    switch (filterItem?.type) {
      case "size":
        return filterItem.sizes.map((size) => `${size.min}-${size.max}`)
      case "keywords":
        return filterItem.keywords
      case "sectors":
        return filterItem.sectors
      case "location":
        return filterItem.locations.map(({ city, state, country }) =>
          generateLocationString({
            city,
            state,
            country,
          }),
        )
      case "custom_field":
        return filterItem.values
      default:
        return []
    }
  }, [filterItem])

  const handleDeleteItem = async (item: string) => {
    try {
      dispatch(setWishListLoading(true))

      const newIcp = JSON.parse(JSON.stringify(icp)) as typeof icp

      const isFilterType = <T extends string>(
        filterItem: FilterCriterion,
        type: T,
      ): filterItem is Extract<typeof filterItem, { type: T }> => {
        return filterItem?.type === type
      }

      if (filterItem !== undefined) {
        newIcp.items[0].criteria = newIcp.items[0].criteria.flatMap(
          (criterion) => {
            if (isFilterType(criterion, "size")) {
              criterion.sizes = criterion.sizes.filter(
                (size) => `${size.min}-${size.max}` !== item,
              )
              if (criterion.sizes.length === 0) return []
            } else if (isFilterType(criterion, "keywords")) {
              criterion.keywords = criterion.keywords.filter(
                (keyword) => keyword !== item,
              )
              if (criterion.keywords.length === 0) return []
            } else if (isFilterType(criterion, "sectors")) {
              criterion.sectors = criterion.sectors.filter(
                (sector) => sector !== item,
              )
              if (criterion.sectors.length === 0) return []
            } else if (isFilterType(criterion, "location")) {
              criterion.locations = criterion.locations.filter(
                (location) =>
                  generateLocationString({
                    city: location.city,
                    state: location.state,
                    country: location.country,
                  }) !== item,
              )
              if (criterion.locations.length === 0) return []
            } else if (
              isFilterType(criterion, "custom_field") &&
              isFilterType(filterItem, "custom_field") &&
              criterion.field === filterItem.field
            ) {
              criterion.values = criterion.values.filter(
                (value) => value !== item,
              )
              if (criterion.values.length === 0) return []
            }
            return [criterion]
          },
        )
      }

      if (filterItem?.type !== undefined) {
        analytics.wishlistCriteriaUpdated({
          criteriaName: filterItem?.type,
        })
      }

      await postOrganizationsByOrgIdIcp({
        lensId: currentLensId,
        filter: newIcp,
      })

      await refetchICP()

      dispatch(leadbayApi.util.invalidateTags(["Leads"]))
    } catch (error) {
      console.error(error)
      toast.error("Error updating wishlist criteria")
    } finally {
      dispatch(setWishListLoading(false))
    }
  }

  return (
    <Paper
      key={JSON.stringify(icp)}
      elevation={0}
      sx={{
        px: variant === "outlined" ? 0 : 2,
        py: 2,
        backgroundColor: variant === "outlined" ? "transparent" : "paper",
        pointerEvents: isLoading || isDefaultLens ? "none" : "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          gap: 1,
        }}
      >
        <button
          style={{
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
            opacity: isDefaultLens ? 0.6 : 1,
          }}
          onDoubleClick={() => setShowInput(!showInput)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              fontSize={16}
              fontFamily="Hanken Grotesk"
              fontWeight="bold"
            >
              {upperCaseFirstLetter(label!)} ({filteredList.length})
            </Typography>

            {isLoading && <CircularProgress size={14} />}
          </Box>
        </button>

        {isDefaultLens && (
          <Chip
            variant="outlined"
            sx={{ fontWeight: "bold", fontSize: "0.7rem", opacity: 0.6 }}
            label="Read only"
          />
        )}
      </Box>

      {filterItem?.type && (
        <Box>
          <LbWishlistCrud
            field={
              filterItem.type === "custom_field"
                ? { type: filterItem.type, field: filterItem.field }
                : { type: filterItem.type }
            }
            icp={icp.items[0]}
            setShowInput={setShowInput}
          />
        </Box>
      )}

      <Box sx={{ my: 1, display: "flex", flexWrap: "wrap", gap: 1 }}>
        {filteredList
          .slice()
          .sort((a, b) => a.localeCompare(b))
          .map((value, index) => (
            <Chip
              key={value + index}
              sx={{
                pointerEvents: readonly ? "none" : "auto",
              }}
              onDelete={
                readonly ? undefined : async () => await handleDeleteItem(value)
              }
              label={upperCaseFirstLetter(value)}
              variant={readonly ? "filled" : "outlined"}
            />
          ))}
      </Box>
    </Paper>
  )
}
