import { DashboardLayout } from "@layouts/DashboardLayout/DashboardLayout"
import { EmptyLayout } from "@layouts/EmptyLayout/EmptyLayout"
import { OnboardingLayout } from "@layouts/OnboardingLayout/OnboardingLayout"
import { WEB_APP_ROUTES } from "@leadbay/constants"
import CheckAccessScreen from "@screens/auth/CheckAccessScreen/CheckAccessScreen"
import ForgotPasswordScreen from "@screens/auth/ForgotPasswordScreen/ForgotPasswordScreen"
import LoginScreen from "@screens/auth/LoginScreen/LoginScreen"
import ResetPasswordScreen from "@screens/auth/ResetPasswordScreen/ResetPasswordScreen"
import { VerifyEmail } from "@screens/auth/VerifyEmailScreen/VerifyEmailScreen"
import ErrorScreen from "@screens/commons/ErrorScreen/ErrorScreen"
import { LeadbayMapScreen } from "@screens/commons/LeadbayMapScreen/LeadbayMapScreen"
import AccountScreen from "@screens/dashboard/AccountScreen/AccountScreen"
import FavoritesScreen from "@screens/dashboard/FavoritesScreen/FavoritesScreen"
import { HubspotScreen } from "@screens/dashboard/HubspotScreen/HubspotScreen"
import { IntegrationsScreen } from "@screens/dashboard/IntegrationsScreen/IntegrationsScreen"
import { OauthScreen } from "@screens/dashboard/OauthScreen/OauthScreen"
import OrganisationScreen from "@screens/dashboard/OrganisationScreen/OrganisationScreen"
import SubscriptionScreenV2 from "@screens/dashboard/SubscriptionScreen/SubscriptionScreenV2"
import TrashScreen from "@screens/dashboard/TrashScreen/TrashScreen"
import WishlistScreen from "@screens/dashboard/WishlistScreen/WishlistScreen"
import OnboardingScreen from "@screens/onboarding/OnboardingScreen/OnboardingScreen"
import { withSentryReactRouterV6Routing } from "@sentry/react"
import { Route, Routes } from "react-router-dom"

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

export const AppRoutes = () => {
  return (
    <SentryRoutes>
      <Route path={WEB_APP_ROUTES.ROOT} element={<EmptyLayout />}>
        <Route index element={<CheckAccessScreen />} />

        <Route
          path={WEB_APP_ROUTES.PUBLIC.INVITE_REGISTER}
          element={<CheckAccessScreen />}
        />

        <Route path={WEB_APP_ROUTES.PUBLIC.LOGIN} element={<LoginScreen />} />

        <Route
          path={WEB_APP_ROUTES.PUBLIC.FORGOT_PASSWORD}
          element={<ForgotPasswordScreen />}
        />

        <Route
          path={WEB_APP_ROUTES.PUBLIC.RESET_PASSWORD}
          element={<ResetPasswordScreen />}
        />

        <Route
          path={WEB_APP_ROUTES.PUBLIC.VERIFY_EMAIL}
          element={<VerifyEmail />}
        />
      </Route>

      <Route path={WEB_APP_ROUTES.APP} element={<DashboardLayout />}>
        <Route index element={<WishlistScreen />} />

        <Route
          path={WEB_APP_ROUTES.PRIVATE.INTEGRATIONS}
          element={<IntegrationsScreen />}
        />

        <Route
          path={WEB_APP_ROUTES.PRIVATE.ACCOUNT}
          element={<AccountScreen />}
        />

        <Route
          path={WEB_APP_ROUTES.PRIVATE.ORGANIZATION}
          element={<OrganisationScreen />}
        />

        <Route
          path={WEB_APP_ROUTES.PRIVATE.FAVORITES}
          element={<FavoritesScreen />}
        />

        <Route path={WEB_APP_ROUTES.PRIVATE.TRASH} element={<TrashScreen />} />

        <Route
          path={WEB_APP_ROUTES.PRIVATE.HUBSPOT_OAUTH_CALLBACK}
          element={<HubspotScreen />}
        />
      </Route>

      <Route
        path={WEB_APP_ROUTES.PRIVATE.SUBSCRIPTION}
        element={<SubscriptionScreenV2 />}
      />

      <Route
        path={WEB_APP_ROUTES.PRIVATE.ONBOARDING}
        element={<OnboardingLayout />}
      >
        <Route index element={<OnboardingScreen />} />
      </Route>

      <Route path={WEB_APP_ROUTES.PUBLIC.MAP} element={<LeadbayMapScreen />} />

      <Route path={WEB_APP_ROUTES.PRIVATE.OAUTH} element={<OauthScreen />} />

      <Route path={WEB_APP_ROUTES.ALL} element={<ErrorScreen />} />
    </SentryRoutes>
  )
}
