import { useGetLeadsSavedQuery } from "@leadbay/state/api"
import { useMemo } from "react"

export const useSavedLeads = () => {
  const { data: savedLeads, isFetching } = useGetLeadsSavedQuery({
    count: 1000,
    page: 0,
  })

  const monitorLeadsData = useMemo(
    () => savedLeads?.items?.filter((lead) => lead.saved_source === "MONITOR"),
    [savedLeads],
  )

  const wishlistLeadsData = useMemo(
    () => savedLeads?.items?.filter((lead) => lead.saved_source === "DISCOVER"),
    [savedLeads],
  )

  const timeLineLeadsData = useMemo(
    () => savedLeads?.items?.filter((lead) => lead.saved_source === "TIMELINE"),
    [savedLeads],
  )

  const countSavedLeads =
    (monitorLeadsData?.length ?? 0) +
    (wishlistLeadsData?.length ?? 0) +
    (timeLineLeadsData?.length ?? 0)

  return {
    countSavedLeads,

    isFetching,

    monitorLeadsData,
    wishlistLeadsData,
    timeLineLeadsData,

    allSavedLeads: savedLeads?.items,
  }
}
