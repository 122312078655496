import { LbButton } from "@components/feedback/LbButton/LbButton"
import {
  useDeleteCrmCustomFieldsByFieldIdMutation,
  useGetCrmCustomFieldsQuery,
  usePostCrmCustomFieldsByFieldIdMutation,
  usePostCrmCustomFieldsMutation,
  type CustomField,
} from "@leadbay/state/api"
import { Add, RemoveCircleOutlineOutlined } from "@mui/icons-material"
import { Box, IconButton, TextField, Typography } from "@mui/material"
import { useCallback, useMemo, useState } from "react"

export const CustomFields = () => {
  const [newCustomFields, setNewCustomFields] = useState<
    Array<CustomField & { selected?: boolean }>
  >([])
  const { data: customFields = [] } = useGetCrmCustomFieldsQuery()
  const [removedCustomFields, setRemovedCustomFields] = useState<CustomField[]>(
    [],
  )

  const allCustomFields = useMemo(
    () => [
      ...customFields
        .filter(
          (field) =>
            !newCustomFields.some((f) => f.id === field.id) &&
            !removedCustomFields.some((f) => f.id === field.id),
        )
        .map((field) => ({ ...field, selected: false })),
      ...newCustomFields,
    ],
    [newCustomFields, customFields, removedCustomFields],
  )

  const [isModified, setIsModified] = useState(false)

  const isValid = useMemo(
    () =>
      newCustomFields.every(
        (field, index) =>
          field.name.trim() !== "" &&
          newCustomFields.findIndex((f) => f.name === field.name) === index &&
          customFields.every((f) => f.name !== field.name),
      ),
    [newCustomFields, customFields],
  )

  const addField = useCallback(async () => {
    setNewCustomFields((prev) => [
      ...prev.map((field) => ({ ...field, selected: false })),
      {
        id: `tmp-${Date.now()}`,
        name: `Field ${allCustomFields.length + 1}`,
        selected: true,
      },
    ])
    setIsModified(true)
  }, [allCustomFields])

  const removeField = useCallback(async (field: CustomField) => {
    if (field.id.startsWith("tmp-")) {
      setNewCustomFields((prev) => prev.filter((f) => f.id !== field.id))
    } else {
      setRemovedCustomFields((prev) => [...prev, field])
    }
    setIsModified(true)
  }, [])

  const renameField = useCallback(
    async (field: CustomField, name: string) => {
      if (
        !field.id.startsWith("tmp-") &&
        !newCustomFields.some((f) => f.id === field.id)
      ) {
        setNewCustomFields((prev) => [
          ...prev.map((f) => ({ ...f, selected: false })),
          { ...field, name, selected: false },
        ])
      } else {
        setNewCustomFields((prev) => {
          const updated = [...prev].map((field) => ({
            ...field,
            selected: false,
          }))
          updated[prev.findIndex((f) => f.id === field.id)].name = name
          return updated
        })
      }

      setIsModified(true)
    },
    [newCustomFields],
  )

  const [createCustomField, { isLoading: isCreatingCustomField }] =
    usePostCrmCustomFieldsMutation()
  const [updateCustomField, { isLoading: isUpdatingCustomField }] =
    usePostCrmCustomFieldsByFieldIdMutation()
  const [removeCustomField, { isLoading: isRemovingCustomField }] =
    useDeleteCrmCustomFieldsByFieldIdMutation()

  const save = useCallback(async () => {
    await Promise.all([
      ...newCustomFields.map(async (field) => {
        if (field.id.startsWith("tmp-")) {
          await createCustomField({
            createCustomField: {
              name: field.name,
            },
          })
        } else {
          await updateCustomField({
            fieldId: field.id,
            updateCustomField: {
              name: field.name,
            },
          })
        }
      }),
      ...removedCustomFields.map(async (field) => {
        await removeCustomField({
          fieldId: field.id,
        })
      }),
    ])

    setNewCustomFields([])
    setIsModified(false)
  }, [
    newCustomFields,
    customFields,
    createCustomField,
    updateCustomField,
    removeCustomField,
    removedCustomFields,
  ])

  const isSaving = useMemo(
    () =>
      isCreatingCustomField || isUpdatingCustomField || isRemovingCustomField,
    [isCreatingCustomField, isUpdatingCustomField, isRemovingCustomField],
  )

  return (
    <section>
      <Box component="header">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            fontFamily="BasementGrotesque"
          >
            Custom fields
          </Typography>
        </Box>

        <Typography variant="h6" color="text.secondary">
          Add custom fields to your CRM
        </Typography>

        <Box mt={2} display="flex" gap={2}>
          <LbButton size="small" onClick={addField} startIcon={<Add />}>
            Add new custom field
          </LbButton>

          {isModified && (
            <LbButton
              size="small"
              onClick={save}
              disabled={isSaving || !isValid}
              variant="outlined"
            >
              Save
            </LbButton>
          )}
        </Box>
      </Box>

      {allCustomFields.length === 0 && (
        <Box mt={8} textAlign="center" width="100%">
          <Typography variant="subtitle1" color="text.secondary">
            No custom fields added
          </Typography>
        </Box>
      )}

      <Box
        mt={3}
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(10rem, 1fr))"
        gap={2}
      >
        {allCustomFields.map((field, index) => (
          <Box position="relative" key={index}>
            <TextField
              size="small"
              sx={{ width: "100%" }}
              placeholder="Field name"
              value={field.name}
              onChange={async (e) => await renameField(field, e.target.value)}
              focused={field.selected}
              ref={(input) => {
                if (input && field.selected) {
                  const inputEl = input.querySelector("input")
                  inputEl?.focus()
                  inputEl?.select()
                  inputEl?.setSelectionRange(0, field.name.length)
                }
              }}
            />
            <Box position="absolute" right={0} top={0}>
              <IconButton onClick={async () => await removeField(field)}>
                <RemoveCircleOutlineOutlined />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
    </section>
  )
}
