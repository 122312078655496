import { LbTimelineGrid } from "@components/display/LbTimelineGrid/LbTimelineGrid"
import { Interaction } from "@leadbay/state/api"
import { useRecordUserInteractions, useTimeline } from "@leadbay/state/hooks"
import { WishlistViewMode } from "@leadbay/state/slices/commonsSlice"
import { Box, Typography } from "@mui/material"
import { useAsyncEffect } from "ahooks"
import { formatDate } from "date-fns"
import { useMemo, useRef } from "react"

type TimelinePartType = ReturnType<
  typeof useTimeline
>[WishlistViewMode.TIMELINE][number]["type"]

interface TimelinePartProps<T extends TimelinePartType = TimelinePartType> {
  enableSelection?: boolean
  timelinePart: Extract<
    ReturnType<typeof useTimeline>[WishlistViewMode.TIMELINE][number],
    { type: T }
  >
  firstTableBlockId: string
  isFirstOfDate: boolean
  isLastOfDate: boolean
}

export const TimelinePart = (props: TimelinePartProps) => {
  return (
    <Box display="flex" flexDirection="row" gap={2}>
      <Box>
        <TimelineThead withBall={props.timelinePart.type === "date"} />
      </Box>

      <Box width="0" flexGrow={1}>
        <_TimelinePart {...props} />
      </Box>
    </Box>
  )
}

const TimelineThead = ({ withBall }: { withBall?: boolean }) => {
  const ballSize = 20

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={0.5}
      height="100%"
      minWidth={ballSize}
      width={ballSize}
      maxWidth={ballSize}
    >
      <Box
        sx={{ backgroundColor: "#e0e0e0" }}
        borderRadius={9999}
        width="2px"
        flex={1}
      />

      {withBall && (
        <>
          <Box
            sx={{
              width: ballSize,
              height: ballSize,
              borderRadius: "100px",
              background: "#e0e0e0",
            }}
          />

          <Box
            sx={{ backgroundColor: "#e0e0e0" }}
            borderRadius={9999}
            width="2px"
            flex={1}
          />
        </>
      )}
    </Box>
  )
}

const _TimelinePart = (props: TimelinePartProps) => {
  switch (props.timelinePart.type) {
    case "date":
      return <TimelineDatePart {...(props as TimelinePartProps<"date">)} />
    case "text":
      return <TimelineTextPart {...(props as TimelinePartProps<"text">)} />
    case "table":
      return <TimelineTablePart {...(props as TimelinePartProps<"table">)} />
  }
}

const TimelineDatePart = (props: TimelinePartProps<"date">) => {
  const { date, text } = props.timelinePart.value

  const formattedDate = useMemo(() => {
    return formatDate(date, "EEEE, MMMM 'the' do")
  }, [date])

  return (
    <Typography sx={{ fontWeight: "bold" }} variant="subtitle1" my={3}>
      {formattedDate}
      {text ? ` - ${text}` : ""}
    </Typography>
  )
}

const TimelineTextPart = (props: TimelinePartProps<"text">) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
      <Box bgcolor="background.paper" p={4} mb={2} borderRadius={1}>
        <Typography>{props.timelinePart.value.text}</Typography>
      </Box>
    </Box>
  )
}

const TimelineTablePart = (props: TimelinePartProps<"table">) => {
  const tablePartRef = useRef<HTMLDivElement>(null)

  const { handleRecordUserInteractions } = useRecordUserInteractions()

  useAsyncEffect(async () => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting) {
            const interactions = props.timelinePart.value.rows.map((item) => ({
              type: "BLOCK_LEAD_SEEN",
              lead_id: item.id,
              block_id: item.block_id,
            })) as Interaction[]

            await handleRecordUserInteractions(interactions)

            observer.disconnect()
          }
        })
      },
      { threshold: 0.1 },
    )

    if (tablePartRef.current) {
      observer.observe(tablePartRef.current)
    }
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={2}
      ref={tablePartRef}
      width={"100%"}
      bgcolor="background.paper"
      my={2}
      borderRadius={2}
      p={4}
    >
      <Box display="flex" flexDirection="column" width={"100%"}>
        <Typography mb={2} variant="subtitle1" fontStyle="italic">
          {props.timelinePart.value.title}
        </Typography>

        <Box p={1} bgcolor="#fff" borderRadius={1}>
          <LbTimelineGrid
            rows={props.timelinePart.value.rows}
            firstTableBlockId={props.firstTableBlockId}
          />
        </Box>
      </Box>
    </Box>
  )
}
