import { LbWishlistGrid } from "@components/display/LbWishlistGrid/LbWishlistGrid"
import {
  useAppSelector,
  useKeyboardController,
  useWishlistData,
} from "@leadbay/state/hooks"
import {
  selectCommonsState,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"
import { useEffect, useMemo, useState } from "react"
import { generateWishlistRows } from "@leadbay/utils"

export const Discover = () => {
  const [rowCountState, setRowCountState] = useState(0)

  const { wishlistViewMode, pageSize } = useAppSelector(selectCommonsState)

  const {
    wishlistData,
    isFetching,
    showLoader,
    wishlistPaginationModel,
    setWishlistPaginationModel,
  } = useWishlistData()

  const rows = useMemo(
    () => generateWishlistRows(wishlistData?.items ?? []),
    [wishlistData],
  )

  const { dataGridRef } = useKeyboardController({
    page: wishlistPaginationModel.page,
    rows,
  })

  useEffect(() => {
    if (pageSize) {
      setWishlistPaginationModel({
        pageSize,
        page: 0,
      })
    }
  }, [wishlistViewMode, pageSize])

  useEffect(() => {
    const total = wishlistData?.pagination.total

    if (total !== undefined) {
      setRowCountState(total)
    }
  }, [wishlistData, wishlistViewMode])

  return (
    <LbWishlistGrid
      dataGridRef={dataGridRef}
      rows={rows}
      isFetching={isFetching}
      rowCount={rowCountState}
      showLoader={showLoader}
      gridSubject={WishlistViewMode.DISCOVER}
      paginationModel={wishlistPaginationModel}
      setPaginationModel={setWishlistPaginationModel}
    />
  )
}
