import { LbDatableCell } from "@components/display/LbDatatableCell/LbDatatableCell"
import { LbFavoritesSearch } from "@components/inputs/LbFavoritesSearch/LbFavoritesSearch"
import {
  APP_DRAWER_WIDTH,
  CENTERED_FLEX_COL,
  CENTERED_FLEX_ROW,
} from "@leadbay/constants"
import { useGetLeadsLikesQuery, type Lead1 } from "@leadbay/state/api"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import {
  NavDrawerState,
  selectCommonsState,
  toggleNavDrawer,
} from "@leadbay/state/slices/commonsSlice"
import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { Box, Chip, IconButton, Typography } from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import {
  DataGrid,
  useGridApiRef,
  type GridPaginationModel,
  type GridRowParams,
} from "@mui/x-data-grid"
import { motion } from "framer-motion"
import {
  useEffect,
  useMemo,
  useState,
  type ChangeEvent,
  type SyntheticEvent,
} from "react"
import { generateWishlistRowsSimplified } from "@leadbay/utils"
import { GenerateWishlistRowsSimplifiedParams } from "@leadbay/utils/commons/generateWishListRows"

const FavoritesScreen = () => {
  const dispatch = useAppDispatch()

  const {
    favoriteViewMode,
    pageSize,
    navDrawerIsOpen,
    navDrawerPartial,
    drawerData,
  } = useAppSelector(selectCommonsState)

  const [currentFilter, setCurrentFilter] = useState<string | undefined>()
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: pageSize,
    page: 0,
  })

  const { data, isFetching, isLoading } = useGetLeadsLikesQuery({
    count: pageSize,
    page: paginationModel.page,
  })

  const [rowCountState, setRowCountState] = useState(
    data?.pagination.total || 0,
  )

  useEffect(() => {
    if (!pageSize) return

    setPaginationModel({
      pageSize,
      page: 0,
    })
  }, [favoriteViewMode, pageSize])

  const handleSetPaginationModel = (params: GridPaginationModel) => {
    const { page, pageSize } = params

    setPaginationModel({
      page,
      pageSize,
    })
  }

  const handleToggleDrawer = (event: SyntheticEvent) => {
    event.stopPropagation()
    event.preventDefault()

    dispatch(
      toggleNavDrawer({
        isOpen: !navDrawerIsOpen,
        partial: navDrawerPartial,
        data: drawerData,
      }),
    )
  }

  const handleToggleLeadInfos = (dataGrid: GridRowParams) => {
    if (data?.items.length === 0) return

    dispatch(
      toggleNavDrawer({
        isOpen: true,
        partial: NavDrawerState.LEAD_INFOS,
        data: dataGrid,
      }),
    )
  }

  const apiRef = useGridApiRef()

  useEffect(() => {
    try {
      if (data?.items && data?.items?.length > 0) {
        apiRef.current.selectRow(data.items[0].id)

        const firstRowParams = apiRef.current.getRowParams(data.items[0].id)

        dispatch(
          toggleNavDrawer({
            isOpen: true,
            partial: NavDrawerState.LEAD_INFOS,
            data: firstRowParams,
          }),
        )
      } else {
        dispatch(
          toggleNavDrawer({
            isOpen: false,
            partial: NavDrawerState.LEAD_INFOS,
          }),
        )
      }
    } catch (error) {
      console.error(error)
    }
  }, [data])

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data?.pagination.total !== undefined
        ? data?.pagination.total
        : prevRowCountState,
    )
  }, [setRowCountState, favoriteViewMode, data?.pagination.total])

  const handleSearch = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCurrentFilter(event.target.value)
  }

  const tablesRows = useMemo(() => {
    if (!data?.items) return []
    if (!currentFilter) return data.items
    else
      return data?.items?.filter((lead) =>
        lead.name.toLowerCase().includes(currentFilter.toLowerCase()),
      )
  }, [currentFilter, data?.items])

  const rows = generateWishlistRowsSimplified(
    tablesRows as GenerateWishlistRowsSimplifiedParams[],
  )

  const showSkeleton = !isLoading

  return showSkeleton ? (
    <Box
      id="favorites-screen"
      sx={{
        display: "flex",
        alignItems: "center",
        height: "calc(100vh - 30px)",
        position: "relative",
        flex: 1,
      }}
      className="hide-scrollbar"
    >
      <Box
        className="hide-scrollbar"
        component="article"
        sx={{
          height: "calc(100vh - 30px)",
          width: `calc(100vw - ${navDrawerIsOpen ? APP_DRAWER_WIDTH : "0px"})`,
          maxWidth: "1600px",
          mx: "auto",
          overflowY: "auto",
          flex: 1,
          px: "40px",
          pt: "10px",
        }}
      >
        <LbFavoritesSearch
          onSearch={handleSearch}
          searchValue={currentFilter}
        />

        <Box
          sx={{
            position: "relative",
            flex: 1,
            minWidth: "0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {tablesRows.length > 0 ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              style={{ width: "100%", height: "100%" }}
            >
              <DataGrid
                apiRef={apiRef}
                loading={isFetching}
                onRowClick={handleToggleLeadInfos}
                onPaginationModelChange={handleSetPaginationModel}
                paginationMode="server"
                rowCount={rowCountState}
                paginationModel={paginationModel}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "id", sort: "desc" }],
                  },
                }}
                slots={{
                  toolbar: undefined,
                }}
                sx={{
                  borderRadius: 0,
                  border: 0,
                  m: 0,
                  backgroundColor: "#fff",
                  ".MuiDataGrid-columnHeaderTitle": {
                    color: "text.secondary",
                  },
                }}
                getRowClassName={() => "lead-row"}
                pagination
                // @ts-expect-error:next-line
                experimentalFeatures={{ clipboardPaste: true }}
                unstable_ignoreValueFormatterDuringExport
                disableColumnFilter
                disableColumnMenu
                rowHeight={40}
                getRowSpacing={() => ({
                  top: 2,
                  bottom: 2,
                })}
                rows={rows}
                columns={[
                  {
                    field: "name",
                    headerName: "Name",
                    sortable: true,
                    flex: 1,
                    renderCell: (params) => {
                      const row = params.row as unknown as Lead1

                      return (
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                              fontFamily: "Hanken Grotesk",
                            }}
                          >
                            {params.value}
                          </Typography>

                          <Box sx={{ ...CENTERED_FLEX_COL, height: "20px" }}>
                            {row.logo && (
                              <img
                                style={{
                                  objectFit: "contain",
                                  height: "100%",
                                  borderRadius: "5px",
                                }}
                                src={row.logo}
                                alt={params.value}
                              />
                            )}
                          </Box>
                        </Box>
                      )
                    },
                  },
                  {
                    field: "status",
                    headerName: "Status",
                    sortable: true,
                    width: 80,
                    renderCell: (params) => {
                      return params?.value ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            width: "100%",
                          }}
                        >
                          <Chip
                            label={params?.value}
                            sx={{
                              fontWeight: 700,
                              fontSize: "0.7rem",
                              paddingLeft: "4px !important",
                              paddingRight: "4px !important",
                            }}
                            color={
                              params.value === "WON"
                                ? "info"
                                : params.value === "LOST"
                                  ? "warning"
                                  : "default"
                            }
                            size="small"
                          />
                        </Box>
                      ) : null
                    },
                  },
                  {
                    field: "sector",
                    flex: 1,
                    headerName: "Sector",
                    sortable: false,
                    renderCell: ({ row, value }) => (
                      <LbDatableCell
                        type="SECTOR"
                        row={row as unknown as Lead1}
                        value={value}
                      />
                    ),
                  },
                  {
                    field: "location",
                    flex: 1,
                    headerName: "Location",
                    sortable: false,
                    renderCell: ({ row, value }) => (
                      <LbDatableCell
                        type="LOCATION"
                        row={row as unknown as Lead1}
                        value={value}
                      />
                    ),
                  },
                  {
                    field: "size",
                    headerName: "Size",
                    sortable: true,
                    sortComparator: (v1, v2, param1, param2) => {
                      return param2.value?.[1] - param1.value?.[1]
                    },
                    renderCell: ({ row, value }) => (
                      <LbDatableCell
                        type="SIZE"
                        row={row as unknown as Lead1}
                        value={value}
                      />
                    ),
                  },
                ]}
              />
            </motion.div>
          ) : (
            <motion.div>
              <Box
                sx={{
                  ...CENTERED_FLEX_COL,

                  width: "100%",
                  height: "calc(100vh - 90px)",
                }}
              >
                <Box
                  sx={{
                    ...CENTERED_FLEX_COL,
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      ...CENTERED_FLEX_ROW,
                      transform: "translateY(-5vh)",
                    }}
                  >
                    <Typography sx={{ marginLeft: 1 }}>
                      No leads have been found
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </motion.div>
          )}
        </Box>
      </Box>

      {rows?.length > 0 && (
        <Box sx={{ position: "absolute", right: 0 }}>
          <IconButton size="small" onClick={handleToggleDrawer}>
            {!navDrawerIsOpen ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        height: window.innerHeight,
        width: "calc(100vw - 383px)",
        px: "40px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{ width: "100%", mb: 3, mt: 10 }}
        height={50}
      />

      <Skeleton
        variant="rectangular"
        sx={{ width: "100%" }}
        height={window.innerHeight - 300}
      />
    </Box>
  )
}

export default FavoritesScreen
