import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import { createReduxEnhancer } from "@sentry/react"
import { baseApi } from "./api/baseApi"
import authReducer from "./slices/authSlice"
import commonsReducer from "./slices/commonsSlice"
import onboardingReducer from "./slices/onboardingSlice"

export const sentryReduxEnhancer = createReduxEnhancer()

const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,

    commons: commonsReducer,
    auth: authReducer,
    onboarding: onboardingReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(baseApi.middleware),

  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer)
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store
}

export default store
