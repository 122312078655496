import { GridRowParams } from "@mui/x-data-grid"

export type ThemeMode = "light" | "dark"

export type AccessRight =
  | "account_already_exists"
  | "not_whitelisted"
  | "can_register"
  | "idle"

export interface ApiError {
  status: number
  message: string
}

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed"
    platform: string
  }>
  prompt: () => Promise<void>
}

export interface ReleaseDetail {
  title: string
  description: string
}

export interface ReleaseInfo {
  version: string
  date: string
  details: ReleaseDetail[]
}

export interface Release {
  date: string
  version: string
  content: string
}

export interface ReleasesData {
  releases: Release[]
}

export interface LightLead {
  name: string
  lat: number
  lng: number
  logo: string
}

export interface Coordinate {
  lat: number
  lng: number
}

export type SavedStatus = "saved" | "unsaved"

export type LikeStatus = "like" | "dislike" | "none"

export enum NavDrawerState {
  SCORING_PARAMETERS = "SCORING_PARAMETERS",
  WISHLIST_FILTERS = "WISHLIST_FILTERS",
  LEAD_INFOS = "LEAD_INFOS",
  LENS_CRUD = "LENS_CRUD",
}

export enum WishlistViewMode {
  DISCOVER = "whishlist",
  MONITOR = "monitor",
  TIMELINE = "timeline",
  SAVED_LEADS = "saved",
}
export type FavoriteViewMode = "all" | "wanted" | "unwanted"

export interface ToggleDrawerParams {
  isOpen: boolean
  partial?: NavDrawerState
  data?: GridRowParams
}

export interface TempLikedLead {
  id: string
  liked: LikeStatus
}

export interface AutoCompleteOption {
  label: string
  value: string
}

export enum MessageType {
  IMPORT_PROGRESS = "import_progress",
  LENS_REFRESHED = "lens_refreshed",
  PING = "ping",
  PONG = "pong",
  SCORES_UPDATED = "scores_updated",
}

export interface ImportProgress {
  type: MessageType.IMPORT_PROGRESS
  importId: string
  step: "PREPROCESSING" | "PROCESSING"
  progress: number
  done: boolean
}

export interface LensRefreshed {
  type: MessageType.LENS_REFRESHED
  lens_id: string
}

export interface Ping {
  type: MessageType.PING
}

export interface Pong {
  type: MessageType.PONG
}

export interface ScoresUpdated {
  type: MessageType.SCORES_UPDATED
  lens_id: string
  scores: Record<string, number>
}

export type WebSocketMessage =
  | ImportProgress
  | LensRefreshed
  | Ping
  | Pong
  | ScoresUpdated

export type MessageTypeToMessage = {
  [MessageType.IMPORT_PROGRESS]: ImportProgress
  [MessageType.LENS_REFRESHED]: LensRefreshed
  [MessageType.PING]: Ping
  [MessageType.PONG]: Pong
  [MessageType.SCORES_UPDATED]: ScoresUpdated
}

export type MessagesByType = {
  [key in MessageType]?: WebSocketMessage[]
}

export interface PaginationModel {
  pageSize: number
  page: number
}
