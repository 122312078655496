import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import { useGetAuthInvitesByInviteIdQuery } from "@leadbay/state/api"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import {
  selectAuthState,
  setAccessRight,
} from "@leadbay/state/slices/authSlice"
import { useAsyncEffect } from "ahooks"
import { useEffect, useState } from "react"
import { CheckForm } from "./partials/CheckForm"
import { RegisterForm } from "./partials/RegisterForm"

const CheckAccessScreen = () => {
  const { accessRight } = useAppSelector(selectAuthState)

  const [invite, setInvite] = useState<string | undefined>()
  const [requestSuccessful, setRequestSuccessful] = useState(false)

  const { data, isFetching, isError, error, isSuccess } =
    useGetAuthInvitesByInviteIdQuery(
      {
        inviteId: invite!,
      },
      {
        skip: !invite || requestSuccessful,
      },
    )

  const dispatch = useAppDispatch()

  useAsyncEffect(async () => {
    const queryParams = new URLSearchParams(window.location.search)
    const invite = queryParams.get("invite")

    if (invite) {
      dispatch(setAccessRight("can_register"))

      setInvite(invite)
    }
  }, [])

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      statusCodeConfig: {
        400: {
          message: {
            text: "Your invite isn't valid",
          },
        },
      },
    },
  })

  useEffect(() => {
    if (isSuccess && !requestSuccessful) {
      setRequestSuccessful(true)
    }
  }, [isSuccess])

  return (
    <div>
      {accessRight === "can_register" && !isFetching && !isError ? (
        <div id="register-form-wrapper">
          <RegisterForm invite={invite} inviteData={data} />
        </div>
      ) : (
        <div id="check-form-wrapper">
          <CheckForm />
        </div>
      )}
    </div>
  )
}

export default CheckAccessScreen
