import { LbButton } from "@components/feedback/LbButton/LbButton"
import { useGetConnectorsQuery } from "@leadbay/state/api"
import { Add } from "@mui/icons-material"
import { Box, Divider, List, Skeleton, Typography } from "@mui/material"
import { useState } from "react"
import { Fragment } from "react/jsx-runtime"
import { Integration } from "./Integration"
import { NewIntegrationModal } from "./modals/NewIntegrationModal"

export const Integrations = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const { data, isLoading, refetch } = useGetConnectorsQuery()

  const handleToggleIntegrationModal = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <section>
      <NewIntegrationModal
        modalOpen={modalOpen}
        toggleModal={handleToggleIntegrationModal}
      />

      <Box component="header">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            fontFamily="BasementGrotesque"
          >
            Connectors
          </Typography>

          {data && data?.length > 0 && (
            <Typography
              variant="caption"
              color="text.secondary"
              fontWeight="bold"
            >
              {data?.length} Zapier apps connected
            </Typography>
          )}
        </Box>

        <Typography variant="h6" color="text.secondary">
          Connect your Leadbay account with external services.
        </Typography>

        <Box sx={{ mt: 2 }}>
          <LbButton
            size="small"
            onClick={handleToggleIntegrationModal}
            startIcon={<Add />}
          >
            Add new integration
          </LbButton>
        </Box>
      </Box>

      <Box sx={{ mt: 5 }}>
        {!isLoading ? (
          <>
            {!data?.length ? (
              <Box sx={{ textAlign: "center", mt: 8 }}>
                <Typography variant="subtitle1" color="text.secondary">
                  No integrations found
                </Typography>
              </Box>
            ) : (
              <List
                sx={{
                  width: "100%",
                }}
              >
                {data?.map((connector, index) => (
                  <Fragment key={index}>
                    {index > data.length - 1 && (
                      <Divider variant="inset" component="li" />
                    )}

                    <Integration
                      {...connector}
                      refetch={async () => {
                        await refetch()
                      }}
                    />
                  </Fragment>
                ))}
              </List>
            )}
          </>
        ) : (
          <Skeleton sx={{ width: "124px", height: "230px" }} />
        )}
      </Box>
    </section>
  )
}
