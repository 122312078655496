import { CURRENT_LENS_ID, DEFAULT_LENS } from "@leadbay/constants"
import {
  useGetLensesByLensIdScoringQuery,
  useGetLensesQuery,
  usePostLensesByLensIdDraftMutation,
} from "@leadbay/state/api"
import localforage from "localforage"
import { useMemo } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { selectAuthState } from "../../slices/authSlice"
import { selectCommonsState, setCurrentLensId } from "../../slices/commonsSlice"
import { useAppSelector } from "../commons/useAppSelector"

export const useCheckLense = () => {
  const dispatch = useDispatch()

  const { token } = useAppSelector(selectAuthState)
  const { currentLensId } = useAppSelector(selectCommonsState)

  const {
    data: lenses,
    error: lensesError,
    refetch: refetchLenses,
    isUninitialized: lensesIsUninitialized,
  } = useGetLensesQuery(undefined, {
    skip: !token,
  })

  const { refetch: refetchScoring, isUninitialized: scoringIsUninitialized } =
    useGetLensesByLensIdScoringQuery(
      {
        lensId: currentLensId,
      },
      {
        skip: !currentLensId,
      },
    )

  const { refetch: refetchUserMe, isUninitialized: userMeIsUninitialized } =
    useGetLensesQuery(undefined, {
      skip: !token,
    })

  const [postLensesByLensIdDraft] = usePostLensesByLensIdDraftMutation()

  const createDraftLens = async () => {
    try {
      if (lensesError) return

      const defaultLensId = lenses?.find(
        (lens) => lens.name === DEFAULT_LENS,
      )?.id

      if (!defaultLensId) throw new Error("Default lens not found")

      const response = await postLensesByLensIdDraft({
        lensId: defaultLensId,
      }).unwrap()

      await localforage.setItem(CURRENT_LENS_ID, response.id)

      dispatch(setCurrentLensId(response.id))

      if (!lensesIsUninitialized) await refetchLenses()

      return response.id
    } catch (error) {
      toast.error("An unexpected error occurred.")

      console.error(error)
    }
  }

  const upgradeLens = async (lensId: string) => {
    dispatch(setCurrentLensId(lensId))

    if (!scoringIsUninitialized) await refetchScoring()
    if (!lensesIsUninitialized) await refetchLenses()
    if (!userMeIsUninitialized) await refetchUserMe()
  }

  const findDefaultLens = () => lenses?.find((lens) => lens.default)

  const findDefaultDraftLens = () => {
    const defaultLens = findDefaultLens()

    return lenses?.find((lens) => lens?.draft_of === defaultLens?.id)
  }

  const checkIfDraftLensNeeded = async (): Promise<string> => {
    const defaultLens = findDefaultLens()

    if (!defaultLens || defaultLens.id !== currentLensId) return currentLensId

    const defaultDraftLens = findDefaultDraftLens()

    if (defaultLens.id === currentLensId && defaultDraftLens) {
      const draftId = defaultDraftLens.id

      await upgradeLens(draftId)

      return draftId
    }

    const draftId = await createDraftLens()

    const id = draftId || currentLensId

    await upgradeLens(id)

    return id
  }

  const isDefaultLens = useMemo(() => {
    const defaultLens = findDefaultLens()

    return defaultLens?.id === currentLensId
  }, [currentLensId])

  return {
    upgradeLens,
    createDraftLens,
    checkIfDraftLensNeeded,
    findDefaultDraftLens,
    findDefaultLens,
    isDefaultLens,
    lenses,
  }
}
