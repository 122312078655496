import { useAppSelector } from "@leadbay/state/hooks"
import { selectAuthState } from "@leadbay/state/slices/authSlice"
import { MessagesByType, MessageType, WebSocketMessage } from "@leadbay/typings"
import { createContext, ReactNode, useEffect, useRef, useState } from "react"

interface WebSocketContextType {
  messagesByType: MessagesByType
  clearMessagesByType: (messageType: MessageType) => void
}

export const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined,
)

interface WebSocketProviderProps {
  children: ReactNode
}

export const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
  const { token, user } = useAppSelector(selectAuthState)

  const wsRef = useRef<WebSocket | null>(null)
  const reconnectAttemptsRef = useRef(0)

  const [messagesByType, setMessagesByType] = useState<MessagesByType>({})

  useEffect(() => {
    let isMounted = true

    const MAX_RECONNECT_ATTEMPTS = 5

    const connectWebSocket = async () => {
      if (!token || !user?.verified || wsRef.current) return

      // @ts-ignore - env variables are used from consuming apps
      const apiUrl = `${import.meta.env.VITE_LEADBAY_API_BASE_URL}/auth/ws?v=1.0`

      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (!response.ok) {
          console.error("Network response was not ok")
          return
        }

        const { url } = await response.json()

        const ws = new WebSocket(url)
        wsRef.current = ws

        ws.onopen = () => {
          reconnectAttemptsRef.current = 0
        }

        ws.onmessage = (event) => {
          try {
            const message: WebSocketMessage = JSON.parse(event.data)

            setMessagesByType((prev) => ({
              ...prev,
              [message.type]: [...(prev[message.type] || []), message],
            }))
          } catch (error) {
            console.error("Failed to parse WebSocket message:", error)
          }
        }

        ws.onerror = (error) => {
          console.error("WebSocket Error:", error)
        }

        ws.onclose = (event) => {
          console.error("WebSocket Closed:", event)
          wsRef.current = null

          if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
            const timeout = Math.pow(2, reconnectAttemptsRef.current) * 1000
            reconnectAttemptsRef.current += 1
            setTimeout(() => {
              if (isMounted) {
                connectWebSocket()
              }
            }, timeout)
          } else {
            console.error("Max reconnection attempts reached.")
          }
        }
      } catch (error) {
        console.error("Error during WebSocket connection setup:", error)
      }
    }

    connectWebSocket()

    return () => {
      isMounted = false
      if (wsRef.current) {
        wsRef.current.onmessage = null
        wsRef.current.onerror = null
        wsRef.current.onclose = null
        wsRef.current.close()
        wsRef.current = null
      }
    }
  }, [token, user?.verified])

  const clearMessagesByType = (messageType: MessageType) => {
    setMessagesByType((prev) => ({
      ...prev,
      [messageType]: [],
    }))
  }

  return (
    <WebSocketContext.Provider value={{ messagesByType, clearMessagesByType }}>
      {children}
    </WebSocketContext.Provider>
  )
}
