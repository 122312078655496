import { CENTERED_FLEX_COL } from "@leadbay/constants"
import { CheckCircle, Star } from "@mui/icons-material"
import {
  Box,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material"
import ListItem from "@mui/material/ListItem"

interface LbDataSourceCardProps {
  title: string
  infos: Array<string | { key: string; value: string } | false | undefined>
  isDefault?: boolean
  isActive?: boolean
  icon?: JSX.Element | null | undefined
  action?: JSX.Element
}

export const LbDataSourceCard = ({
  title,
  infos,
  isDefault = false,
  isActive = false,
  icon,
  action,
}: LbDataSourceCardProps) => {
  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        bgcolor: "background.paper",
        borderRadius: "12px",
        mb: 2,
      }}
    >
      {icon && <ListItemAvatar>{icon}</ListItemAvatar>}

      <ListItemText
        primary={title}
        secondary={infos.map(
          (info, index) =>
            info && (
              <>
                <Typography
                  key={index}
                  sx={{ display: "inline", alignSelf: "flex-end" }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {typeof info !== "string" && (
                    <Typography
                      component="span"
                      sx={{ fontWeight: "bold" }}
                      variant="body2"
                    >
                      {info.key}:&nbsp;
                    </Typography>
                  )}
                  {typeof info === "string" ? info : `${info.value}`}
                </Typography>
                <br />
              </>
            ),
        )}
      />

      <ListItemSecondaryAction
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
        }}
      >
        {isDefault && (
          <Tooltip title="Selected source for export">
            <Box sx={{ ...CENTERED_FLEX_COL }}>
              <Star />

              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                No lens
              </Typography>
            </Box>
          </Tooltip>
        )}

        {isActive && (
          <Box sx={{ ...CENTERED_FLEX_COL }}>
            <CheckCircle />

            <Typography variant="caption" sx={{ fontWeight: "bold" }}>
              Active
            </Typography>
          </Box>
        )}

        {action}
      </ListItemSecondaryAction>
    </ListItem>
  )
}
