import logoHubspot from "@assets/images/logos/hubspot-logo.png"
import saleForceLogo from "@assets/images/logos/saleforce-logo.png"
import logoZapier from "@assets/images/logos/zapier-logomark.png"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { WEB_APP_ROUTES } from "@leadbay/constants"
import { useFeatureFlagCheck } from "@leadbay/state/hooks"
import { FeatureFlagEnum } from "@leadbay/state/hooks/commons/useFeatureFlagCheck"
import { Close } from "@mui/icons-material"
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material"
import { useState } from "react"

const ZapierConnector = () => {
  return (
    <Box>
      <Typography variant="h5">Zapier instructions</Typography>

      <Typography variant="subtitle1" color="text.secondary" sx={{ my: 2 }}>
        To connect your data from a Zap, please go to the the{" "}
        <a
          style={{ textDecoration: "underline" }}
          rel="noreferrer"
          target="_blank"
          href="https://zapier.com/"
        >
          Zapier platform
        </a>
        , create a Zap and select Leadbay as the trigger app.
      </Typography>

      <a
        href="https://zapier.com/app/dashboard"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <LbButton>Go to Zapier</LbButton>
      </a>
    </Box>
  )
}

const HubspotConnector = () => {
  const HUBSPOT_APP_OAUTH_URL = "https://app-eu1.hubspot.com/oauth/authorize"
  const CLIENT_ID = import.meta.env.VITE_HUBSPOT_CLIENT_ID
  const SCOPES = import.meta.env.VITE_HUBSPOT_SCOPES.split(",")
  const REDIRECT_URI = WEB_APP_ROUTES.PRIVATE.HUBSPOT_OAUTH_CALLBACK
  const connectUrl = String.prototype.concat(
    HUBSPOT_APP_OAUTH_URL,
    `?client_id=${CLIENT_ID}`,
    `&scope=${SCOPES.join("%20")}`,
    `&redirect_uri=${window.location.origin}/${REDIRECT_URI}`,
  )

  return (
    <Box>
      <Typography variant="h5">Connect your HubSpot account</Typography>

      <Typography variant="subtitle1" color="text.secondary" sx={{ my: 2 }}>
        To connect your data from HubSpot, click the button below and follow the
        instructions.
      </Typography>

      <a
        href={connectUrl}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <LbButton>Connect HubSpot</LbButton>
      </a>
    </Box>
  )
}

const SaleforceConnector = () => {
  return (
    <Box>
      <Typography variant="h5">Connect your HubSpot account</Typography>

      <Typography variant="subtitle1" color="text.secondary" sx={{ my: 2 }}>
        To connect your Salesforce CRM, please contact the Leadbay team who will
        handle your request as soon as possible.
      </Typography>

      <a
        href="mailto:help@leadbay.ai"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <LbButton>Contact us</LbButton>
      </a>
    </Box>
  )
}

interface NewIntegrationModalProps {
  modalOpen: boolean
  toggleModal: () => void
}

enum IntegrationType {
  Zapier = "zapier",
  CSV = "csv",
  Hubspot = "hubspot",
  Salesforce = "salesforce",
}

export const NewIntegrationModal = ({
  modalOpen,
  toggleModal,
}: NewIntegrationModalProps) => {
  const [selectedIntegration, setSelectedIntegration] =
    useState<IntegrationType | null>()

  const handleClose = () => {
    setSelectedIntegration(null)
    toggleModal()
  }

  const isHubspotEnabled = useFeatureFlagCheck(FeatureFlagEnum.HUBSPOT)

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(255,255,255,0.8)",
          },
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: 500,
          width: "100%",
          backgroundColor: "white",
          p: 4,
          borderRadius: 1,
          border: "1px solid rgba(0, 0, 0, 0.08)",
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        {![
          IntegrationType.Zapier,
          IntegrationType.Hubspot,
          IntegrationType.Salesforce,
        ].includes(selectedIntegration as IntegrationType) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Box>
              <Typography variant="h5">Choose an integration</Typography>

              <Typography variant="subtitle1" color="text.secondary">
                More integrations are coming soon!
              </Typography>
            </Box>
          </Box>
        )}

        <IconButton
          sx={{ position: "absolute", top: 8, right: 9 }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>

        {selectedIntegration === IntegrationType.Zapier ? (
          <ZapierConnector />
        ) : selectedIntegration === IntegrationType.Hubspot ? (
          <HubspotConnector />
        ) : selectedIntegration === IntegrationType.Salesforce ? (
          <SaleforceConnector />
        ) : (
          <List
            sx={{
              width: "100%",
            }}
          >
            <Divider variant="inset" component="li" />

            <ListItemButton
              alignItems="flex-start"
              onClick={() => setSelectedIntegration(IntegrationType.Zapier)}
              sx={{
                cursor: "pointer",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "scale(1.02)",
                },
              }}
            >
              <ListItemAvatar>
                <Box
                  borderRadius="5px"
                  overflow="hidden"
                  width={40}
                  height={40}
                >
                  <img src={logoZapier} alt="zapier" width={40} />
                </Box>
              </ListItemAvatar>

              <ListItemText
                primary="Zapier"
                secondary={
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    Connect your data from a Zap
                  </Typography>
                }
              />
            </ListItemButton>

            {isHubspotEnabled && (
              <>
                <Divider variant="inset" component="li" />

                <ListItemButton
                  alignItems="flex-start"
                  sx={{
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                  onClick={() =>
                    setSelectedIntegration(IntegrationType.Hubspot)
                  }
                >
                  <ListItemAvatar>
                    <Box
                      borderRadius="5px"
                      overflow="hidden"
                      width={40}
                      height={40}
                    >
                      <img src={logoHubspot} alt="hubspot" width={40} />
                    </Box>
                  </ListItemAvatar>

                  <ListItemText
                    primary="HubSpot"
                    secondary={
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.secondary"
                      >
                        Connect your data from HubSpot
                      </Typography>
                    }
                  />
                </ListItemButton>
              </>
            )}

            <Divider variant="inset" component="li" />

            <ListItemButton
              alignItems="flex-start"
              sx={{
                cursor: "pointer",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "scale(1.02)",
                },
              }}
              onClick={() => setSelectedIntegration(IntegrationType.Salesforce)}
            >
              <ListItemAvatar>
                <Box
                  borderRadius="5px"
                  overflow="hidden"
                  width={40}
                  height={40}
                >
                  <img src={saleForceLogo} alt="saleforce" width={40} />
                </Box>
              </ListItemAvatar>

              <ListItemText
                primary="Salesforce"
                secondary={
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    Connect your data from your Salesforce CRM
                  </Typography>
                }
              />
            </ListItemButton>
          </List>
        )}
      </Box>
    </Modal>
  )
}
