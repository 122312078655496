import { MAPPING_STEP } from "@leadbay/constants"
import { useAppSelector, useLeadbayWSNotification } from "@leadbay/state/hooks"
import {
  selectOnboardingState,
  setMappingStep,
  setNextStep,
} from "@leadbay/state/slices/onboardingSlice"
import { MessageType } from "@leadbay/typings"
import { Typography, type SxProps } from "@mui/material"
import Box from "@mui/material/Box"
import { motion } from "framer-motion"
import localforage from "localforage"
import { type ReactNode } from "react"
import { useDispatch } from "react-redux"
import { LbButton } from "../../../../../components/feedback/LbButton/LbButton"

const OVERLAY_HEIGHT = "30px"

interface StepProps {
  children: ReactNode
  title: string
  ctaTitle?: string
  ctaLoading?: boolean
  onValidate?: () => void
  ctaDisabled?: boolean
  stepIndex: number
  sx?: SxProps
}

export const Step = (props: StepProps) => {
  const {
    children,
    title,
    ctaTitle,
    ctaLoading,
    sx,
    ctaDisabled = false,
    stepIndex,
    onValidate,
  } = props

  const { clearMessageHistory } = useLeadbayWSNotification(
    MessageType.IMPORT_PROGRESS,
  )

  const dispatch = useDispatch()

  const { currentStep, apiStep, mappingStep } = useAppSelector(
    selectOnboardingState,
  )

  const handleNavigateBack = () => {
    if (!currentStep || currentStep <= 0) return

    clearMessageHistory()

    if (currentStep === 2 && mappingStep === 1) {
      localforage.removeItem(MAPPING_STEP).catch(console.error)
      dispatch(setMappingStep(0))
    } else {
      dispatch(setNextStep(currentStep - 1))
    }
  }

  const handleNavigateNext = () => {
    if (typeof currentStep === "undefined") return

    dispatch(setNextStep(currentStep + 1))
  }

  return (
    <Box
      component={motion.section}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ ease: "linear", duration: 0.5 }}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        ...sx,
      }}
    >
      <Box component="header">
        <Typography variant="h4">{title}</Typography>
      </Box>

      <Box sx={{ position: "relative", maxHeight: "550px", overflow: "auto" }}>
        <Box
          sx={{
            background: "linear-gradient(to top, #FFFFFF , transparent)",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: OVERLAY_HEIGHT,
            zIndex: 1,
            pointerEvents: "none",
          }}
        />

        <Box
          sx={{
            background: "linear-gradient(to bottom, #FFFFFF , transparent)",
            position: "absolute",
            bottom: 0,
            top: 0,
            width: "100%",
            height: OVERLAY_HEIGHT,
            zIndex: 1,
            pointerEvents: "none",
          }}
        />

        <Box
          sx={{
            position: "relative",
            overflow: "auto",
            height: "100%",
            py: OVERLAY_HEIGHT,
            px: 1,
          }}
        >
          {children}
        </Box>
      </Box>

      {ctaTitle && (
        <Box
          component="footer"
          sx={{
            display: "flex",
            justifyContent:
              !!currentStep && currentStep < 3 ? "space-between" : "flex-start",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            {!!currentStep && currentStep > 1 && currentStep < 3 && (
              <LbButton
                onClick={handleNavigateBack}
                disabled={ctaLoading}
                variant="text"
              >
                Previous
              </LbButton>
            )}

            {apiStep > stepIndex && currentStep !== 4 && currentStep !== 1 && (
              <LbButton
                onClick={handleNavigateNext}
                disabled={ctaLoading}
                variant="text"
              >
                Next
              </LbButton>
            )}
          </Box>

          <LbButton
            loading={ctaLoading}
            size="large"
            onClick={onValidate}
            disabled={ctaDisabled}
            id="next-step-cta"
          >
            {ctaTitle}
          </LbButton>
        </Box>
      )}
    </Box>
  )
}
