import {
  AUTH_TOKEN,
  CURRENT_LENS_ID,
  DEFAULT_LENS,
  WEB_APP_ROUTES,
} from "@leadbay/constants/index"
import {
  GetLensesApiResponse,
  useGetLensesQuery,
  useGetOrganizationsByOrgIdFeatureFlagsQuery,
  useGetUsersMeQuery,
  User,
} from "@leadbay/state/api"
import { useAsyncEffect } from "ahooks"
import localforage from "localforage"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  selectAuthState,
  setSubscriptionRequired,
  setToken,
  setUser,
} from "../../slices/authSlice"
import { setCurrentLensId, setFeaturesFlags } from "../../slices/commonsSlice"
import { setOnboardingComplete } from "../../slices/onboardingSlice"
import { useAppSelector } from "../commons/useAppSelector"
import { useLogout } from "./useLogout"

export type Token = string | null | undefined

export enum AppStatus {
  Idle = "idle",
  Connected = "connected",
  NotConnected = "not connected",
}

export const useAuth = () => {
  const dispatch = useDispatch()

  const { logout } = useLogout()

  const { token } = useAppSelector(selectAuthState)

  const [appStatus, setAppStatus] = useState<AppStatus>(AppStatus.Idle)

  const [userPollingInterval, setUserPollingInterval] = useState<number>(
    1000 * 60 * 60,
  )

  const { data: userData, error: userError } = useGetUsersMeQuery(undefined, {
    skip: !token,
    pollingInterval: userPollingInterval,
  })

  const { data: lenses, error: lensesError } = useGetLensesQuery(undefined, {
    skip: !token || !userData?.verified,
  })

  const { data: featuresFlags, isError: featureFlagsIsError } =
    useGetOrganizationsByOrgIdFeatureFlagsQuery(
      {
        orgId: userData?.organization?.id!,
      },
      {
        skip: !userData?.organization?.id || !userData?.verified,
      },
    )

  useEffect(() => {
    const currentFeaturesFlags =
      featureFlagsIsError || !featuresFlags?.length ? [] : featuresFlags

    if (currentFeaturesFlags) dispatch(setFeaturesFlags(currentFeaturesFlags))
  }, [featuresFlags])

  useEffect(() => {
    if (
      (window.location.pathname.includes(WEB_APP_ROUTES.APP) &&
        userData?.computing_daily_timeline) ||
      userData?.computing_daily_wishlist
    )
      setUserPollingInterval(5000)
    else setUserPollingInterval(1000 * 60 * 60)
  }, [userData, window.location.pathname])

  const initApp = async (userData: User, lenses: GetLensesApiResponse) => {
    try {
      if (lensesError) {
        const errorMessage =
          // @ts-ignore - error is not defined in the type
          lensesError?.data?.error?.code === "suspended"
            ? "Your account has been suspended"
            : "No lenses found"

        await logout(errorMessage)
        setAppStatus(AppStatus.NotConnected)
        return
      }

      if (lenses?.length && userData.verified) {
        const savedLensId = await localforage.getItem<string>(CURRENT_LENS_ID)
        const lensToUse = await selectLens(savedLensId, lenses)

        if (!lensToUse) {
          await logout("No lenses found")
          setAppStatus(AppStatus.NotConnected)
          return
        }

        dispatch(setCurrentLensId(lensToUse))
      }
    } catch (error) {
      console.error("An error occurred during app initialization:", error)
      await logout("Initialization error")
      setAppStatus(AppStatus.NotConnected)
    }
  }

  const selectLens = async (
    savedLensId: string | null,
    lenses: GetLensesApiResponse,
  ): Promise<string | null> => {
    if (savedLensId) {
      const response = await fetchLensCounts(savedLensId)

      if (response.ok) {
        return savedLensId
      }
    }

    const defaultLens = lenses.find((lens) => lens.name === DEFAULT_LENS)
    return defaultLens ? defaultLens.id : null
  }

  const fetchLensCounts = async (lensId: string): Promise<Response> => {
    // @ts-ignore - env variables are used from consuming apps
    const url = `${import.meta.env.VITE_LEADBAY_API_BASE_URL}/lenses/${lensId}/leads/new_counts`

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response
  }

  useEffect(() => {
    if (!userData || !lenses?.length || !token) return

    initApp(userData, lenses)
  }, [userData, lenses, token])

  useAsyncEffect(async () => {
    if (userError) {
      console.error(userError)

      await logout("Authentication error, please login again.")

      setAppStatus(AppStatus.NotConnected)

      return
    }

    if (!userData) return

    if (
      userData?.organization?.billing?.suspended ||
      userData?.organization?.billing?.missing_seat
    )
      dispatch(setSubscriptionRequired(true))

    dispatch(setUser(userData))

    dispatch(
      setOnboardingComplete(
        userData?.organization?.onboarding_step === "FINISHED",
      ),
    )

    setAppStatus(AppStatus.Connected)
  }, [userData, userError])

  useAsyncEffect(async () => {
    const localToken: Token = await localforage.getItem(AUTH_TOKEN)

    if (localToken) {
      dispatch(setToken(localToken))
    } else {
      setAppStatus(AppStatus.NotConnected)
    }
  }, [])

  return { appStatus, setAppStatus }
}
