import { Connector } from "@leadbay/state/api"
import { useCallback } from "react"
import { toast } from "react-toastify"
import { selectAuthState } from "../../slices/authSlice"
import { WishlistViewMode, selectCommonsState } from "../../slices/commonsSlice"
import { useAppSelector } from "../commons/useAppSelector"

interface IExportLeads {
  onSuccess: (selectedTarget: string, targetName: string) => void
  onError: (error: Error) => void
  onFinished?: (selectedTarget: string) => void
}

interface HandleExportLeads {
  selectedTarget: string
  selectedLeads: string[]
  wishlistViewMode: WishlistViewMode
  connector?: Connector
}

export const useExportLeads = ({
  onSuccess,
  onError,
  onFinished,
}: IExportLeads) => {
  const { token } = useAppSelector(selectAuthState)
  const { currentLensId } = useAppSelector(selectCommonsState)

  const fetchAndHandleResponse = async (
    url: string,
    payload: Record<string, string | string[]> | undefined | string[],
  ): Promise<string> => {
    const response = await fetch(url, {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      }),
      body: payload ? JSON.stringify(payload) : null,
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const contentType = response.headers.get("Content-Type")

    if (contentType && contentType.includes("application/json")) {
      const data = await response.json()
      if (data?.error) {
        throw new Error(data?.error)
      }
      return "No data available."
    } else {
      return await response.text()
    }
  }

  const downloadCSV = (csvText: string, prefix: string = "leads_") => {
    const blob = new Blob([csvText], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    const date = new Date()
    const timestamp = date.getTime()
    const filename = `${prefix}${timestamp}.csv`
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  const handleExportLeads = useCallback(
    async ({
      selectedTarget,
      selectedLeads,
      wishlistViewMode,
      connector,
    }: HandleExportLeads) => {
      if (selectedLeads.length >= 1000) {
        toast.error("You can only export up to 1000 leads at a time.")

        return
      }

      try {
        const payload =
          wishlistViewMode === WishlistViewMode.MONITOR
            ? selectedLeads
            : {
                target_id: selectedTarget,
                lead_ids: selectedLeads,
              }

        // @ts-ignore - env variables are used from consuming apps
        const url = `${import.meta.env.VITE_LEADBAY_API_BASE_URL}/lenses/${currentLensId}/export/${wishlistViewMode}`

        const csvText = await fetchAndHandleResponse(url, payload)

        if (selectedTarget === "f") {
          downloadCSV(csvText)
        }

        onSuccess(selectedTarget, connector?.name || "file")
      } catch (error) {
        const err = error as Error
        console.error(err)
        onError(err)
      } finally {
        onFinished?.(selectedTarget)
      }
    },
    [token, currentLensId, onSuccess, onError, onFinished],
  )

  return { handleExportLeads }
}
