import { useRedirectUnauthenticatedUser } from "@hooks/useRedirectUnauthenticatedUser"
import {
  useAuth,
  useGetWindowSize,
  usePostHog,
  useUpdateApp,
} from "@leadbay/state/hooks"
import { AppStatus } from "@leadbay/state/hooks/auth/useAuth"
import { AppRoutes } from "@routes/AppRoutes"

export const App = () => {
  const { appStatus } = useAuth()

  useGetWindowSize()

  useUpdateApp()

  usePostHog()

  useRedirectUnauthenticatedUser()

  if (appStatus === AppStatus.Idle) return null

  return <AppRoutes />
}
