import {
  leadbayApi,
  LeadSavedSource,
  usePostLeadsByLeadIdClearSaveMutation,
  usePostLeadsByLeadIdSaveMutation,
} from "@leadbay/state/api"
import {
  useAppDispatch,
  useAppSelector,
  useRecordUserInteractions,
} from "@leadbay/state/hooks"
import {
  selectCommonsState,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"
import { GridRowSelectionModel } from "@mui/x-data-grid/models/gridRowSelectionModel"
import { useAsyncEffect } from "ahooks"
import { useCallback, useEffect, useRef, useState } from "react"
import { TransformedEntry } from "@leadbay/utils/commons/generateWishListRows"

export const sourceDict: Partial<Record<WishlistViewMode, LeadSavedSource>> = {
  whishlist: "DISCOVER",
  monitor: "MONITOR",
  timeline: "TIMELINE",
  saved: undefined,
}

export interface TempSavedLead {
  leadId: string
  source?: LeadSavedSource
}

interface UseSelectWishlistRowProps {
  rows: TransformedEntry[]
}

export const useSelectLeads = ({ rows }: UseSelectWishlistRowProps) => {
  const dispatch = useAppDispatch()

  const [postLeadsByLeadIdSave] = usePostLeadsByLeadIdSaveMutation()
  const [postLeadsByLeadIdClear] = usePostLeadsByLeadIdClearSaveMutation()

  const { wishlistViewMode, currentLensId } = useAppSelector(selectCommonsState)

  const source = sourceDict[wishlistViewMode]

  const initialRowSelectionModel = rows
    .filter((row) => row?.saved_source)
    .map((row) => row.id)

  const [rowSelectionModel, setLocalRowSelectionModel] =
    useState<GridRowSelectionModel>(initialRowSelectionModel)

  const prevRowSelectionModelRef = useRef<GridRowSelectionModel>(
    initialRowSelectionModel,
  )

  const { handleRecordUserInteractions } = useRecordUserInteractions()

  const handleSaveLeads = async (selectedLeads: TempSavedLead[]) => {
    try {
      await handleRecordUserInteractions(
        selectedLeads.map(({ leadId }) => ({
          type: "LEAD_CLICKED",
          lead_id: leadId,
          lens_id: currentLensId,
        })),
      )

      await Promise.all(
        selectedLeads.map(({ leadId, source }) =>
          postLeadsByLeadIdSave({ leadId, setSavedRequest: { source } }),
        ),
      )

      dispatch(leadbayApi.util.invalidateTags(["Timeline"]))
    } catch (error) {
      console.error("Error while saving leads:", error)
    }
  }

  const handleClearSaved = useCallback(
    async (deletedLeads: TempSavedLead[]) => {
      try {
        await Promise.all(
          deletedLeads.map(({ leadId }) => postLeadsByLeadIdClear({ leadId })),
        )

        dispatch(leadbayApi.util.invalidateTags(["Timeline"]))
      } catch (error) {
        console.error("Error while clearing saved leads:", error)
      }
    },
    [],
  )

  const setRowSelectionModel = useCallback(
    (newRowSelectionModel: GridRowSelectionModel) => {
      setLocalRowSelectionModel(newRowSelectionModel)
    },
    [],
  )

  useAsyncEffect(async () => {
    const prevRowSelectionModel = prevRowSelectionModelRef.current

    const addedLeads = rowSelectionModel
      .filter((leadId) => !prevRowSelectionModel.includes(leadId))
      .map((leadId) => ({ leadId: leadId.toString(), source }))

    const removedLeads = prevRowSelectionModel
      .filter((leadId) => !rowSelectionModel.includes(leadId))
      .map((leadId) => ({ leadId: leadId.toString() }))

    prevRowSelectionModelRef.current = rowSelectionModel

    if (addedLeads.length > 0) {
      await handleSaveLeads(addedLeads)
    }
    if (removedLeads.length > 0) {
      await handleClearSaved(removedLeads)
    }
  }, [rowSelectionModel])

  useEffect(() => {
    const storedRowSelectionModel = rows
      .filter((row) => row.saved_source)
      .map((row) => row.id)

    setLocalRowSelectionModel(storedRowSelectionModel)

    prevRowSelectionModelRef.current = storedRowSelectionModel
  }, [rows])

  return {
    setRowSelectionModel,
    rowSelectionModel,
  }
}
