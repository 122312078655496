import { LbCreateDraftLensButton } from "@components/inputs/LbCreateDraftLensButton/LbCreateDraftLensButton"
import { LbFilterCard } from "@components/inputs/LbFilterCard/LbFilterCard"
import {
  useGetCrmCustomFieldsQuery,
  useGetLensesByLensIdFilterQuery,
  type Filter,
  type FilterCriterion,
} from "@leadbay/state/api"
import { useAppSelector, useCheckLense } from "@leadbay/state/hooks"
import { selectCommonsState } from "@leadbay/state/slices/commonsSlice"
import { Box, Typography } from "@mui/material"
import { useMemo } from "react"
import { type ScoringType } from "../LbScoringParameters/LbScoringCrud"

const BASE_CRITERIA = [
  {
    type: "size",
    highlighting_field: "SIZE",
    importance: 50,
    sizes: [],
  },
  {
    type: "keywords",
    importance: 50,
    keywords: [],
  },
  {
    type: "sectors",
    importance: 50,
    sectors: [],
  },
  {
    type: "location",
    importance: 50,
    locations: [],
  },
]

export interface FilterItem {
  name: string
  score: number
  items: Array<{ label: string; highlighted: boolean }>
}

export const LbWishListFilters = () => {
  const { isDefaultLens } = useCheckLense()

  const { currentLensId } = useAppSelector(selectCommonsState)

  const { data } = useGetLensesByLensIdFilterQuery(
    {
      lensId: currentLensId,
    },
    {
      skip: !currentLensId,
    },
  )

  const { data: customFields } = useGetCrmCustomFieldsQuery()
  const customFieldsCriterias = useMemo(
    () =>
      customFields?.map<Extract<FilterCriterion, { type: "custom_field" }>>(
        (field) => ({
          type: "custom_field",
          field: field.id,
          values: [],
        }),
      ) ?? [],
    [customFields],
  )

  const wishlistCriteria = useMemo(() => {
    const savedIcp = data?.items[0].criteria ?? []

    const savedIcpTypes: ScoringType[] = savedIcp.map(
      (filterItem) => filterItem.type,
    )

    if (!data?.items[0].criteria)
      // @ts-expect-error:next-line
      return BASE_CRITERIA.sort((a, b) => a.type - b.type)

    return [
      ...savedIcp,
      ...(BASE_CRITERIA
        ? BASE_CRITERIA.filter(
            (filterItem) =>
              !savedIcpTypes.includes(filterItem.type as ScoringType),
          )
        : []),
      ...customFieldsCriterias.filter(
        (filterItem) =>
          !savedIcp.some(
            (savedFilterItem) =>
              savedFilterItem.type === "custom_field" &&
              savedFilterItem.field === filterItem.field,
          ),
      ),
      // @ts-expect-error:next-line
    ].sort((a, b) => a.type - b.type)
  }, [data?.items[0].criteria, customFieldsCriterias])

  return (
    <Box
      className="hide-scrollbar"
      component="article"
      sx={{ mr: 2, height: "100%", display: "flex", flexDirection: "column" }}
    >
      <Box>
        <Box
          sx={{ display: "block" }}
          component="a"
          href="https://docs.leadbay.app/leadbay-user-docs/product-guides/understanding-wishlists"
          target="_blank"
        >
          📘{" "}
          <Typography
            component="span"
            color="text.secondary"
            style={{ marginLeft: 5, fontWeight: 600, fontSize: "0.85rem" }}
            variant="caption"
          >
            Documentation
          </Typography>
        </Box>
      </Box>

      {isDefaultLens && <LbCreateDraftLensButton />}

      <Box
        className="hide-scrollbar"
        sx={{
          mt: 1,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: 2,
          pb: 4,
        }}
      >
        {(wishlistCriteria as FilterCriterion[])
          .slice()
          .sort((a, b) => {
            if (a.type === "custom_field" && b.type === "custom_field")
              return -a.field.localeCompare(b.field)
            return a.type.localeCompare(b.type)
          })
          .reverse()
          .map((filterItem, index) => (
            <LbFilterCard
              key={
                (filterItem.type === "custom_field"
                  ? `CUSTOM:${filterItem.field}`
                  : filterItem.type) + index
              }
              isDefaultLens={isDefaultLens}
              label={
                filterItem.type === "custom_field"
                  ? (customFields?.find(
                      (field) => field.id === filterItem.field,
                    )?.name ?? filterItem.field)
                  : filterItem.type
              }
              filterItem={filterItem}
              icp={
                data ??
                ({
                  items: [
                    {
                      criteria: BASE_CRITERIA,
                    },
                  ],
                } as unknown as Filter)
              }
            />
          ))}
      </Box>
    </Box>
  )
}
