import { useAppDispatch, useManageLenses } from "@leadbay/state/hooks"
import {
  NavDrawerState,
  toggleNavDrawer,
} from "@leadbay/state/slices/commonsSlice"
import { renameDefaultLens } from "@leadbay/utils"
import { Tune } from "@mui/icons-material"
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material"

export const LbLensSelector = () => {
  const dispatch = useAppDispatch()

  const { currentLensId, handleChangeLens, userLenses } = useManageLenses()

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <FormControl sx={{ width: "100%" }} size="small">
        <InputLabel>Lenses</InputLabel>

        <Select
          value={currentLensId}
          label="Lense"
          onChange={handleChangeLens}
          sx={{
            fontSize: "0.9rem",
            height: "2.5rem",
          }}
        >
          {userLenses.map((lens) => (
            <MenuItem
              sx={{
                fontSize: "0.9rem",
              }}
              key={lens.id}
              value={lens.id}
            >
              {renameDefaultLens(lens.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Tooltip title="Lens settings" placement="left">
        <IconButton
          sx={{
            alignSelf: "center",
            borderRadius: "50%",
            height: "2.5rem",
            width: "2.5rem",
          }}
          color="primary"
          size="small"
          onClick={() =>
            dispatch(
              toggleNavDrawer({
                isOpen: true,
                partial: NavDrawerState.LENS_CRUD,
              }),
            )
          }
        >
          <Tune />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
