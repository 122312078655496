import { CENTERED_FLEX_COL } from "@leadbay/constants"
import { useAppSelector } from "@leadbay/state/hooks"
import { selectAuthState } from "@leadbay/state/slices/authSlice"
import { Box, Typography } from "@mui/material"

export const LbTrialBanner = () => {
  const { trialDaysLeft, user } = useAppSelector(selectAuthState)

  const isAccountNotOk = user?.organization?.billing.status !== "OK"

  return isAccountNotOk ? (
    <Box
      sx={{
        position: "absolute",
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        ...CENTERED_FLEX_COL,
        zIndex: 1,
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: "bold",
        }}
      >
        {trialDaysLeft <= 0
          ? "Free trial ongoing. Update payment to keep account active."
          : `${trialDaysLeft} trial days left. Update payment to keep your account active.`}
      </Typography>
    </Box>
  ) : null
}
