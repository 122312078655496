import { WebSocketProvider } from "@leadbay/providers"
import store from "@leadbay/state"
import { CssBaseline } from "@mui/material"
import * as Sentry from "@sentry/react"
import { httpClientIntegration } from "@sentry/react"
import { APIProvider } from "@vis.gl/react-google-maps"
import posthog from "posthog-js"
import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { MuiThemeProvider } from "@leadbay/providers/styles/MuiThemeProvider"
import { App } from "./App"

import "@fontsource/hanken-grotesk/500.css"
import "@fontsource/hanken-grotesk/700.css"
import "@fontsource/hanken-grotesk/800.css"
import "@fontsource/material-icons"
import "@fontsource/reenie-beanie/400.css"

import "react-toastify/dist/ReactToastify.css"
import "./assets/styles/global.css"

const isProduction = !import.meta.env.DEV

if (isProduction && import.meta.env.VITE_POSTHOG_TOKEN)
  posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
    api_host: "https://eu.i.posthog.com",
  })

if (isProduction)
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      httpClientIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/staging\.api\.leadbay\.app\/1\.2/,
      /^http:\/\/staging\.leadbay\.app/,
      /^http:\/\/leadbay\.app/,
      /^https:\/\/api\.leadbay\.app\/1\.2/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
        <WebSocketProvider>
          <MuiThemeProvider>
            <BrowserRouter>
              <CssBaseline />

              <App />

              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                theme="dark"
                pauseOnHover
                closeOnClick
              />
            </BrowserRouter>
          </MuiThemeProvider>
        </WebSocketProvider>
      </APIProvider>
    </Provider>
  </React.StrictMode>,
)
