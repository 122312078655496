import posthog from "posthog-js"

const JOURNEYS = {
  EXPORT_LEADS: "Export Leads",
}

export const analytics = {
  startExportingLeads: () => {
    posthog.capture("start exporting leads", {
      journey: JOURNEYS.EXPORT_LEADS,
    })
  },
  finishExportingLeads: ({
    numLeads,
    target,
  }: {
    numLeads: number
    target: string
  }) => {
    posthog.capture("finish exporting leads", {
      journey: JOURNEYS.EXPORT_LEADS,
      leadbayNumberOfExportedLeads: numLeads,
      leadbayExportTarget: target,
    })
  },
  scoringParameterUpdated: ({ parameterName }: { parameterName: string }) => {
    posthog.capture("scoring parameter updated", {
      parameterName,
    })
  },
  wishlistCriteriaUpdated: ({ criteriaName }: { criteriaName: string }) => {
    posthog.capture("wishlist criteria updated", {
      criteriaName,
    })
  },
  startImportingDatasource: () => {
    posthog.capture("start importing datasource")
  },
  finishImportingDatasource: () => {
    posthog.capture("finish importing datasource")
  },
  vote: ({ voteType }: { voteType: string }) => {
    posthog.capture("vote", {
      voteType,
    })
  },
}
