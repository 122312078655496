import { useGetTimelineBlocksQuery } from "@leadbay/state/api"
import { useAppSelector } from "@leadbay/state/hooks"
import { selectAuthState } from "@leadbay/state/slices/authSlice"
import { useEffect, useMemo, useState } from "react"
import { generateWishlistRowsSimplified } from "@leadbay/utils"
import {
  GenerateWishlistRowsSimplifiedParams,
  TransformedEntry,
} from "@leadbay/utils/commons/generateWishListRows"

interface UseTimelineProps {
  endOfListElementRef: React.RefObject<HTMLDivElement>
}

export const useTimeline = ({ endOfListElementRef }: UseTimelineProps) => {
  const [page, setPage] = useState(0)
  const { user } = useAppSelector(selectAuthState)

  const {
    data: rawTimeline,
    isFetching,
    refetch,
  } = useGetTimelineBlocksQuery({
    page,
    count: 10,
  })

  const [lastComputed, setLastComputed] = useState<boolean>(
    user?.computing_daily_timeline ?? false,
  )

  const [_blocks, setBlocks] = useState<
    NonNullable<typeof rawTimeline>["items"]
  >([])

  useEffect(() => {
    if (page === 0 && rawTimeline?.items) {
      setBlocks(rawTimeline.items)
    } else if (rawTimeline?.items) {
      setBlocks((prevBlocks) => [...prevBlocks, ...rawTimeline.items])
    }
  }, [rawTimeline, page])

  useEffect(() => {
    if (lastComputed && !user?.computing_daily_timeline) {
      setPage(0)
      refetch()
    }

    setLastComputed(user?.computing_daily_timeline ?? false)
  }, [user?.computing_daily_timeline])

  const blocks = useMemo(
    () =>
      _blocks
        .filter(
          (block, index, self) =>
            self.findIndex((b) => b.id === block.id) === index,
        )
        .sort(
          (a, b) =>
            new Date(b.created_on).getTime() - new Date(a.created_on).getTime(),
        ),
    [_blocks],
  )

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      const totalPages = rawTimeline?.pagination?.pages ?? 0

      if (entry.isIntersecting && page + 1 < totalPages) {
        setPage((prevPage) => prevPage + 1)
      }
    })

    if (endOfListElementRef.current) {
      observer.observe(endOfListElementRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [endOfListElementRef, page, rawTimeline])

  const timeline = useMemo(() => {
    return (
      blocks.reduce<
        Array<
          { id: string } & (
            | { type: "date"; value: { date: Date; text?: string } }
            | { type: "text"; value: { text: string } }
            | {
                type: "table"
                value: { rows: TransformedEntry[]; title: string }
              }
          )
        >
      >((acc, block) => {
        const blockDate = new Date(block.created_on)
        const lastDateBlock = acc
          .slice()
          .reverse()
          .find((item) => item.type === "date") as
          | { id: string; type: "date"; value: { date: Date; text?: string } }
          | undefined

        const dateChanged =
          !lastDateBlock ||
          lastDateBlock.value.date.toDateString() !== blockDate.toDateString()

        if (dateChanged) {
          acc.push({
            id: `${block.id}-date`,
            type: "date",
            value: { date: blockDate },
          })
        }

        if (block.type === "horoscope") {
          acc.push({
            id: block.id,
            type: "text",
            value: { text: block.horoscope },
          })
        }

        if (block.type === "leads") {
          const leads = block.leads.map((lead) => ({
            ...lead,
            block_id: block.id,
          }))

          acc.push({
            id: block.id,
            type: "table",
            value: {
              rows: generateWishlistRowsSimplified(
                leads as GenerateWishlistRowsSimplifiedParams[],
              ),
              title: block.title,
            },
          })
        }

        return acc
      }, []) ?? []
    )
  }, [blocks])

  const noData = !blocks.length && !isFetching

  return { timeline, noData, isFetching, refetch }
}
