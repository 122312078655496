import { WEB_APP_ROUTES } from "@leadbay/constants"
import { useLogout } from "@leadbay/state/hooks"
import { useNavigate } from "react-router-dom"

export const useWebAppLogout = () => {
  const navigate = useNavigate()

  const { logout } = useLogout()

  const handleLogout = async () => {
    await logout()

    navigate(WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.LOGIN)
  }

  return {
    logout: handleLogout,
  }
}
