import { WEB_APP_ROUTES } from "@leadbay/constants"
import { useAppSelector } from "@leadbay/state/hooks/commons/useAppSelector"
import { selectAuthState } from "@leadbay/state/slices/authSlice"
import { Navigate } from "react-router-dom"

export const useRedirectUnauthenticatedUser = () => {
  const { user } = useAppSelector(selectAuthState)

  if (
    [
      ...Object.values(WEB_APP_ROUTES.PRIVATE).map((r) => `/${r}`),
      ...Object.values(WEB_APP_ROUTES.PRIVATE).map(
        (r) => `${WEB_APP_ROUTES.APP}/${r}`,
      ),
      WEB_APP_ROUTES.APP,
    ].includes(window.location.pathname) &&
    !user?.email
  ) {
    return (
      <Navigate
        to={WEB_APP_ROUTES.ROOT + WEB_APP_ROUTES.PUBLIC.LOGIN}
        state={{ from: window.location.pathname + window.location.search }}
      />
    )
  }
}
