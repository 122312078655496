import { LbButton } from "@components/feedback/LbButton/LbButton"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  useGetAuthInvitesStatusQuery,
  usePostAuthInviteMutation,
} from "@leadbay/state/api"
import { Add, Close } from "@mui/icons-material"
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material"

import { useState } from "react"
import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import { toast } from "react-toastify"
import { object, string } from "yup"

const validationSchema = object().shape({
  email: string()
    .required()
    .matches(
      /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Please enter a valid email address",
    ),
})

interface FormValues {
  email: string
}

export const Invitations = () => {
  const { data: inviteStatus } = useGetAuthInvitesStatusQuery()

  const [inviteUser, { isLoading }] = usePostAuthInviteMutation()

  const [showInviteForm, setSowInviteForm] = useState(false)

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  })

  const handleShowInviteForm = () => {
    setSowInviteForm(!showInviteForm)
  }

  const handleFormSubmit: SubmitHandler<FormValues> = async (
    authInvitePostRequest,
  ) => {
    try {
      const response = await inviteUser({
        authInvitePostRequest,
      })

      // @ts-ignore:next-line
      if (response?.error?.data?.error)
        throw new Error("Something went wrong, please try again later")

      toast("Invitation sent successfully")
    } catch (error) {
      const err = error as Error

      toast.error(err.message)
    } finally {
      handleShowInviteForm()

      reset()
    }
  }

  return (
    <>
      <Divider sx={{ mb: 5, mt: 6 }} />

      <Box component="section" sx={{ maxWidth: "600px" }}>
        <Box
          component="header"
          sx={{
            mb: 5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box>
            <Typography variant="h6" fontFamily="Hanken Grotesk">
              Invite friends to try Leadbay
            </Typography>

            {inviteStatus?.remaining && inviteStatus?.remaining > 0 && (
              <Box sx={{ background: "primary" }}>
                <Typography color="text.secondary">
                  {`${inviteStatus?.remaining} remaining invitation${inviteStatus?.remaining !== 1 ? "s" : ""}`}
                </Typography>
              </Box>
            )}

            <Typography sx={{ mt: 2 }}>
              {inviteStatus?.remaining === 0
                ? "You have reached the maximum number of invitations. Please wait for your invitations to be accepted."
                : "Leadbay is invite-only. We have reserved a few spots for you to invite your friends. They will not join your team."}
            </Typography>
          </Box>

          <Box>
            <LbButton
              disabled={inviteStatus?.remaining === 0}
              onClick={handleShowInviteForm}
              sx={{ textTransform: "none" }}
              size="small"
              startIcon={<Add />}
            >
              Invite
            </LbButton>
          </Box>
        </Box>
      </Box>

      <Modal
        open={showInviteForm}
        onClose={handleShowInviteForm}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(255,255,255,0.8)",
            },
          },
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(handleFormSubmit)}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 550,
            backgroundColor: "white",
            p: 4,
            borderRadius: 1,
            border: "1px solid rgba(0, 0, 0, 0.08)",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" component="h2" sx={{ lineHeight: 1 }}>
                  Invite an external user to try Leadbay
                </Typography>

                <IconButton size="small" onClick={handleShowInviteForm}>
                  <Close />
                </IconButton>
              </Box>

              <Box>
                <Typography variant="body2" color="text.secondary">
                  Please use only professional e-mail addresses when inviting
                  your friends. Addresses not linked to a professional website
                  may result in issues during user onboarding.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={10}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      type="email"
                      label="Email"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      fullWidth
                      required
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <LbButton
                variant="contained"
                color="primary"
                size="large"
                disabled={!isValid || isLoading}
                type="submit"
                loading={isLoading}
              >
                Send invitation
              </LbButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}
