import { LbScoringCard } from "@components/display/LbScoringCard/LbScoringCard"
import { LbCreateDraftLensButton } from "@components/inputs/LbCreateDraftLensButton/LbCreateDraftLensButton"
import { LbScoreFilter } from "@components/inputs/LbScoreFilter/LbScoreFilter"
import {
  leadbayApi,
  useGetLensesByLensIdScoringQuery,
  usePostLensesByLensIdScoringMutation,
  type ScoringCriterion,
} from "@leadbay/state/api"
import {
  useAppDispatch,
  useAppSelector,
  useCheckLense,
} from "@leadbay/state/hooks"
import { selectAuthState } from "@leadbay/state/slices/authSlice"
import { selectCommonsState } from "@leadbay/state/slices/commonsSlice"
import { Info } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { useAsyncEffect, useDebounceFn } from "ahooks"
import deepEqual from "deep-equal"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { analytics, generateLocationString } from "@leadbay/utils"

export interface LocalScoringCriterion {
  type: string
  importance: number
  highlighting_field?: string
  sizes: Array<{ min: number; max: number }>
  keywords: string[]
  status: string
  sectors: string[]
  locations: Array<{ city: string; state: string; country: string }>
}

export type EditionMode = "edit" | "refine"

export const LbScoringParameters = () => {
  const dispatch = useAppDispatch()

  const { user } = useAppSelector(selectAuthState)
  const { currentLensId } = useAppSelector(selectCommonsState)

  const { checkIfDraftLensNeeded, isDefaultLens } = useCheckLense()

  const [localCriteria, setLocalCriteria] = useState<ScoringCriterion[]>([])
  const [savedCriteria, setSavedCriteria] = useState<ScoringCriterion[]>([])
  const [sizeExpanded, setSizeExpanded] = useState<boolean>(true)
  const [keywordExpanded, setKeywordExpanded] = useState<boolean>(true)
  const [statusExpanded, setStatusExpanded] = useState<boolean>(true)
  const [sectorExpanded, setSectorExpanded] = useState<boolean>(true)
  const [locationExpanded, setLocationExpanded] = useState<boolean>(true)

  const { data, refetch: refetchScoring } = useGetLensesByLensIdScoringQuery(
    {
      lensId: currentLensId,
    },
    {
      skip: !currentLensId,
    },
  )

  const [postOrganizationsByOrgIdScoring] =
    usePostLensesByLensIdScoringMutation()

  useAsyncEffect(async () => {
    if (localCriteria.length === 0) return

    const lensId = await checkIfDraftLensNeeded()

    try {
      await postOrganizationsByOrgIdScoring({
        lensId,
        scoringConfig: {
          criteria: localCriteria,
        },
      })

      await refetchScoring()

      dispatch(leadbayApi.util.invalidateTags(["Leads"]))
    } catch (error) {
      toast.error("Error updating scoring parameters")

      console.error(error)
    }
  }, [localCriteria])

  useEffect(() => {
    if (data?.criteria) {
      setSavedCriteria(data.criteria)
    }
  }, [data?.criteria])

  const { run } = useDebounceFn(
    (uniqueId: string, newImportance: number) => {
      const newCriteria = data?.criteria.map((criterion) => {
        const currentId = createUniqueId(criterion)
        return currentId === uniqueId
          ? { ...criterion, importance: newImportance }
          : criterion
      })

      if (!newCriteria) return

      setLocalCriteria(newCriteria)
      setSavedCriteria(newCriteria)
    },
    {
      wait: 1000,
    },
  )

  const createUniqueId = (criterion: ScoringCriterion): string => {
    switch (criterion.type) {
      case "size":
        return (
          criterion.sizes?.map((size) => `${size.min}-${size.max}`).join("|") ||
          ""
        )
      case "keywords":
        return criterion.keywords?.join("|") || ""
      case "similar_to_status":
        return criterion.status || ""
      case "sectors":
        return criterion.sectors?.join("|") || ""
      case "location":
        return (
          criterion.locations
            ?.map(({ city, state, country }) =>
              generateLocationString({
                city,
                state,
                country,
              }),
            )
            .join("|") || ""
        )
      default:
        return ""
    }
  }

  const groupedScoringParameters = useMemo(() => {
    if (!data?.criteria)
      return {
        size: [],
        keywords: [],
        similar_to_status: [],
        sectors: [],
        location: [],
      }

    return data?.criteria.reduce(
      (acc: Record<string, LocalScoringCriterion[]>, criterion) => {
        if (!acc[criterion.type]) {
          acc[criterion.type] = []
        }
        acc[criterion.type].push(criterion as LocalScoringCriterion)
        return acc
      },
      {
        size: [],
        keywords: [],
        similar_to_status: [],
        sectors: [],
        location: [],
      },
    )
  }, [
    data?.criteria,
    savedCriteria,
    localCriteria,
    user?.organization.id,
    user,
  ])

  const handleDeleteCriteria = async (
    criterionToDelete: LocalScoringCriterion,
  ) => {
    try {
      if (savedCriteria.length === 1)
        throw new Error("You must have at least one scoring criterion")

      const newLocalCriteria = savedCriteria.filter(
        (criterion) => !deepEqual(criterion, criterionToDelete),
      )

      analytics.scoringParameterUpdated({
        parameterName: criterionToDelete.type,
      })

      await postOrganizationsByOrgIdScoring({
        lensId: currentLensId,
        scoringConfig: {
          criteria: newLocalCriteria,
        },
      })

      setLocalCriteria(newLocalCriteria)
      setSavedCriteria(newLocalCriteria)
    } catch (error) {
      const err = error as Error

      toast.error(err.message)
    }
  }

  const displayMaxCriteriaInfos = Object.values(
    groupedScoringParameters,
  ).filter((arr) => arr.length > 0)

  return (
    <Box
      component="article"
      sx={{
        mr: 2,
        display: "flex",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        component="a"
        href="https://docs.leadbay.app/leadbay-user-docs/product-guides/understanding-scoring"
        target="_blank"
        sx={{
          color: "rgba(52,109,219,1.00)",
          fontWeight: 600,
        }}
      >
        📙{" "}
        <Typography
          component="span"
          color="text.secondary"
          style={{ marginLeft: 5, fontWeight: 600, fontSize: "0.85rem" }}
        >
          Documentation
        </Typography>
      </Box>

      {isDefaultLens && <LbCreateDraftLensButton />}

      {displayMaxCriteriaInfos.length > 2 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            pt: 1,
          }}
        >
          <Info />

          <Typography variant="subtitle2">
            Too many scoring criteria may result in less relevant leads.
            Consider wishlist criteria instead
          </Typography>
        </Box>
      )}

      <Box sx={{ pb: 3, mt: 2 }}>
        {groupedScoringParameters?.size && (
          <LbScoringCard
            isDefaultLens={isDefaultLens}
            type="size"
            title={`Size (${groupedScoringParameters.size.length})`}
            expand={sizeExpanded}
            setExpanded={setSizeExpanded}
          >
            {groupedScoringParameters.size.map((criteria, index) => (
              <LbScoreFilter
                key={criteria.importance + index}
                elements={criteria.sizes.map(
                  (size) => `${size.min} - ${size.max}`,
                )}
                value={criteria.importance}
                onChange={(newImportance) => {
                  const uniqueId = createUniqueId(criteria as ScoringCriterion)
                  analytics.scoringParameterUpdated({
                    parameterName: "size",
                  })

                  run(uniqueId, newImportance)
                }}
                onDelete={async () => await handleDeleteCriteria(criteria)}
              />
            ))}
          </LbScoringCard>
        )}

        {groupedScoringParameters?.keywords && (
          <LbScoringCard
            isDefaultLens={isDefaultLens}
            type="keywords"
            title={`Keywords (${groupedScoringParameters?.keywords?.length ?? 0})`}
            expand={keywordExpanded}
            setExpanded={setKeywordExpanded}
          >
            {groupedScoringParameters.keywords.map((criteria, index) => (
              <LbScoreFilter
                key={criteria.importance + index}
                elements={criteria?.keywords}
                value={criteria.importance}
                onDelete={async () => await handleDeleteCriteria(criteria)}
                onChange={(newImportance) => {
                  const uniqueId = createUniqueId(criteria as ScoringCriterion)
                  analytics.scoringParameterUpdated({
                    parameterName: "keywords",
                  })

                  run(uniqueId, newImportance)
                }}
              />
            ))}
          </LbScoringCard>
        )}

        {groupedScoringParameters?.similar_to_status && (
          <LbScoringCard
            isDefaultLens={isDefaultLens}
            type="similar_to_status"
            title={`Status (${groupedScoringParameters?.similar_to_status?.length ?? 0})`}
            expand={statusExpanded}
            setExpanded={setStatusExpanded}
          >
            {groupedScoringParameters.similar_to_status.map((criteria) => (
              <LbScoreFilter
                key={criteria.importance}
                elements={[criteria.status]}
                value={criteria.importance}
                onDelete={async () => await handleDeleteCriteria(criteria)}
                onChange={(newImportance) => {
                  const uniqueId = createUniqueId(criteria as ScoringCriterion)
                  analytics.scoringParameterUpdated({
                    parameterName: "status",
                  })

                  run(uniqueId, newImportance)
                }}
              />
            ))}
          </LbScoringCard>
        )}

        {groupedScoringParameters?.sectors && (
          <LbScoringCard
            isDefaultLens={isDefaultLens}
            type="sectors"
            title={`Sectors (${groupedScoringParameters?.sectors?.length ?? 0})`}
            expand={sectorExpanded}
            setExpanded={setSectorExpanded}
          >
            {groupedScoringParameters.sectors.map((criteria, index) => (
              <LbScoreFilter
                key={criteria.importance + index}
                elements={criteria?.sectors}
                value={criteria.importance}
                onDelete={async () => await handleDeleteCriteria(criteria)}
                onChange={(newImportance) => {
                  const uniqueId = createUniqueId(criteria as ScoringCriterion)
                  analytics.scoringParameterUpdated({
                    parameterName: "sectors",
                  })

                  run(uniqueId, newImportance)
                }}
              />
            ))}
          </LbScoringCard>
        )}

        {groupedScoringParameters?.location && (
          <LbScoringCard
            isDefaultLens={isDefaultLens}
            type="location"
            title={`Location (${groupedScoringParameters?.location?.length ?? 0})`}
            expand={locationExpanded}
            setExpanded={setLocationExpanded}
          >
            {groupedScoringParameters.location.map((criteria, index) => (
              <LbScoreFilter
                key={criteria.importance + index}
                onDelete={async () => await handleDeleteCriteria(criteria)}
                elements={criteria?.locations.map(({ city, state, country }) =>
                  generateLocationString({
                    city,
                    state,
                    country,
                  }),
                )}
                value={criteria.importance}
                onChange={(newImportance) => {
                  const uniqueId = createUniqueId(criteria as ScoringCriterion)
                  analytics.scoringParameterUpdated({
                    parameterName: "location",
                  })

                  run(uniqueId, newImportance)
                }}
              />
            ))}
          </LbScoringCard>
        )}
      </Box>
    </Box>
  )
}
