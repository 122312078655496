import { LbResultRow } from "@components/display/LbResultRow/LbResultRow"
import { LbIaLoader } from "@components/feedback/LbIaLoader/LbIaLoader"
import { LbQuery } from "@components/feedback/LbQuery/LbQuery"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import {
  CENTERED_FLEX_COL,
  IMPORT_ID,
  MAPPING_STEP,
  WEB_APP_ROUTES,
} from "@leadbay/constants"
import { useGetLensesByLensIdLeadsOnboardingQuery } from "@leadbay/state/api"
import {
  useAppDispatch,
  useAppSelector,
  useLeadbayWSNotification,
} from "@leadbay/state/hooks"
import { selectCommonsState } from "@leadbay/state/slices/commonsSlice"
import { setOnboardingComplete } from "@leadbay/state/slices/onboardingSlice"
import { MessageType } from "@leadbay/typings"
import { Grid, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { OnboardingScreenSkeletons } from "@screens/onboarding/OnboardingScreen/OnboardingScreen.skeletons"
import { useAsyncEffect } from "ahooks"
import JSConfetti from "js-confetti"
import localforage from "localforage"
import { useNavigate } from "react-router-dom"
import { Step } from "../Step/Step"

export const StepResults = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { currentLensId } = useAppSelector(selectCommonsState)

  const { messages: importWsMessages } = useLeadbayWSNotification(
    MessageType.SCORES_UPDATED,
  )

  const lastMessage = importWsMessages[importWsMessages.length - 1]

  const leadsLoaded = lastMessage?.type === MessageType.SCORES_UPDATED

  const { data, isSuccess, error, isError, isLoading } =
    useGetLensesByLensIdLeadsOnboardingQuery(
      {
        lensId: currentLensId,
      },
      {
        skip: !currentLensId || !leadsLoaded,
      },
    )

  const handleNext = async () => {
    await localforage.removeItem(MAPPING_STEP)
    await localforage.removeItem(IMPORT_ID)

    dispatch(setOnboardingComplete(true))

    navigate(WEB_APP_ROUTES.APP)
  }

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      statusCodeConfig: {
        400: {
          message: {
            text: "Bad Request",
            type: "error",
          },
        },
        401: {
          message: {
            text: "Missing or invalid Authorization header, or invalid token",
            type: "error",
          },
        },
        403: {
          message: {
            text: "Forbidden",
            type: "error",
          },
        },
      },
    },
  })

  useAsyncEffect(async () => {
    if (data && data?.length > 0 && data?.[0]?.score !== undefined) {
      const jsConfetti = new JSConfetti()

      jsConfetti.addConfetti().catch((err) => {
        console.log(err)
      })
    }
  }, [data])

  return !leadsLoaded ? (
    <Box sx={{ ...CENTERED_FLEX_COL }}>
      <LbIaLoader progress={0.98} />
    </Box>
  ) : (
    <LbQuery
      isError={isError}
      isLoading={isLoading}
      skeleton={<OnboardingScreenSkeletons />}
      content={
        <Step
          stepIndex={4}
          title="🔬 Here we have some leads that might capture your interest."
          ctaTitle="See all leads"
          onValidate={handleNext}
          sx={{
            maxWidth: "900px",
            width: "900px",
            margin: "0 auto",
          }}
        >
          <Box
            className="hide-scrollbar"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{
                    fontSize: "14px",
                    marginLeft: 2,
                  }}
                >
                  Score
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{
                    fontSize: "14px",
                    marginLeft: 2,
                  }}
                >
                  Company
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  color="text.secondary"
                  variant="h6"
                  sx={{
                    fontSize: "14px",
                    marginLeft: 2,
                  }}
                >
                  Description
                </Typography>
              </Grid>
            </Grid>

            {data?.map((row, index) => (
              <LbResultRow key={row.id} row={row} index={index} />
            ))}
          </Box>
        </Step>
      }
    />
  )
}
