import { CURRENT_LENS_ID } from "@leadbay/constants"
import {
  FeatureFlag,
  LeadOrder,
  NewCounts,
  type Lead1,
} from "@leadbay/state/api"
import {
  LikeStatus,
  type BeforeInstallPromptEvent,
  type ThemeMode,
} from "@leadbay/typings"
import { type GridRowParams } from "@mui/x-data-grid"
import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import localforage from "localforage"
import { calcPageSize } from "../../utils"
import { RootState } from "../index"

const rowHeight = 49
const getAvailableSpace = () => window.innerHeight - 125 - rowHeight

export enum NavDrawerState {
  SCORING_PARAMETERS = "SCORING_PARAMETERS",
  WISHLIST_FILTERS = "WISHLIST_FILTERS",
  LEAD_INFOS = "LEAD_INFOS",
  LENS_CRUD = "LENS_CRUD",
}

export enum WishlistViewMode {
  DISCOVER = "whishlist",
  MONITOR = "monitor",
  TIMELINE = "timeline",
  SAVED_LEADS = "saved",
}
export type FavoriteViewMode = "all" | "wanted" | "unwanted"

export interface ToggleDrawerParams {
  isOpen: boolean
  partial?: NavDrawerState
  data?: GridRowParams
}

export interface TempLikedLead {
  id: string
  liked: LikeStatus
}

interface CommonsState {
  navDrawerIsOpen: boolean
  navDrawerPartial?: NavDrawerState
  lastNavDrawerPartial: NavDrawerState
  themeMode: ThemeMode
  drawerData?: GridRowParams
  wishListLoading?: boolean
  wishlistViewMode: WishlistViewMode
  favoriteViewMode: FavoriteViewMode
  leadHistory: Lead1[]
  pageSize: number
  deferredPrompt?: BeforeInstallPromptEvent | null
  currentLensId: string
  tempLikedLeads: Array<TempLikedLead>
  currentFilter?: string
  sortModel: LeadOrder[] | undefined
  selectedIds: string[]
  initialCountData?: NewCounts
  featuresFlags: FeatureFlag[]
}

const DEFAULT_NAV_DRAWER_PARTIAL = NavDrawerState.WISHLIST_FILTERS

const initialState: CommonsState = {
  navDrawerIsOpen: true,
  navDrawerPartial: DEFAULT_NAV_DRAWER_PARTIAL,
  lastNavDrawerPartial: DEFAULT_NAV_DRAWER_PARTIAL,
  themeMode: "light",
  drawerData: undefined,
  wishListLoading: false,
  wishlistViewMode: WishlistViewMode.DISCOVER,
  favoriteViewMode: "wanted",
  leadHistory: [],
  pageSize: calcPageSize(getAvailableSpace(), rowHeight) ?? 10,
  deferredPrompt: null,
  currentLensId: "",
  tempLikedLeads: [],
  sortModel: ["NEW:DESC", "SCORE:DESC"],
  currentFilter: undefined,
  selectedIds: [],
  initialCountData: undefined,
  featuresFlags: [],
}

export const commons = createSlice({
  name: "commons",

  initialState,

  reducers: {
    toggleNavDrawer: (state, action: PayloadAction<ToggleDrawerParams>) => {
      if (state.navDrawerPartial)
        state.lastNavDrawerPartial = state.navDrawerPartial

      state.navDrawerIsOpen = action.payload.isOpen
      state.navDrawerPartial = action.payload.partial
      // @ts-ignore drawerData is not always defined
      state.drawerData = action.payload.data
      state.leadHistory = []
    },

    goToDefaultNavDrawerPartial: (
      _,
      action: PayloadAction<Omit<ToggleDrawerParams, "isOpen" | "partial">>,
    ) => {
      console.log(action.payload)
      toggleNavDrawer({
        isOpen: true,
        partial: DEFAULT_NAV_DRAWER_PARTIAL,
        data: action.payload.data,
      })
    },

    setLeadHistory: (state, action: PayloadAction<Lead1[]>) => {
      state.leadHistory = action.payload
    },

    setDrawerData: (state, action: PayloadAction<GridRowParams>) => {
      // @ts-ignore drawerData is not always defined
      state.drawerData = action.payload
    },

    setThemeMode: (state, action: PayloadAction<ThemeMode>) => {
      state.themeMode = action.payload
    },

    setWishListLoading: (state, action: PayloadAction<boolean>) => {
      state.wishListLoading = action.payload
    },

    setFavoriteViewMode: (state, action: PayloadAction<FavoriteViewMode>) => {
      state.favoriteViewMode = action.payload
    },

    setWishlistViewMode: (state, action: PayloadAction<WishlistViewMode>) => {
      state.wishlistViewMode = action.payload
    },

    setTempLikedLeads(state, action: PayloadAction<TempLikedLead>) {
      const index = state.tempLikedLeads.findIndex(
        (lead) => lead.id === action.payload.id,
      )

      if (index !== -1) {
        state.tempLikedLeads[index] = action.payload
      } else {
        state.tempLikedLeads.push(action.payload)
      }
    },

    setDeferredPrompt(
      state,
      action: PayloadAction<BeforeInstallPromptEvent | null>,
    ) {
      state.deferredPrompt = action.payload
    },

    setCurrentLensId(state, action: PayloadAction<string>) {
      state.currentLensId = action.payload

      localforage.setItem(CURRENT_LENS_ID, action.payload).catch(console.error)
    },

    recalculatePageSize(state) {
      state.pageSize = calcPageSize(getAvailableSpace(), rowHeight)
    },

    setCurrentFilter(state, action: PayloadAction<string>) {
      state.currentFilter = action.payload
    },

    setSortModel(state, action: PayloadAction<LeadOrder[] | undefined>) {
      state.sortModel = action.payload
    },

    setSelectedIds(state, action: PayloadAction<string[]>) {
      state.selectedIds = action.payload
    },

    setInitialCountData(state, action: PayloadAction<NewCounts | undefined>) {
      state.initialCountData = action.payload
    },

    setFeaturesFlags(state, action: PayloadAction<FeatureFlag[]>) {
      state.featuresFlags = action.payload
    },
  },
})

export const {
  toggleNavDrawer,
  goToDefaultNavDrawerPartial,
  setThemeMode,
  setWishListLoading,
  setDrawerData,
  setWishlistViewMode,
  setLeadHistory,
  setDeferredPrompt,
  setFavoriteViewMode,
  setCurrentLensId,
  recalculatePageSize,
  setTempLikedLeads,
  setCurrentFilter,
  setSortModel,
  setSelectedIds,
  setInitialCountData,
  setFeaturesFlags,
} = commons.actions

export const selectCommonsState = (state: RootState): CommonsState =>
  state.commons

export default commons.reducer
