import logoHubspot from "@assets/images/logos/hubspot-logo.png"
import logoZapier from "@assets/images/logos/zapier-logomark.png"
import { LbDataSourceCard } from "@components/display/LbDataSourceCard/LbDataSourceCard"
import { LbToastConfirm } from "@components/display/LbToastConfirm"
import {
  type Connector,
  type Mappings,
  useDeleteConnectorsByConnectorIdMutation,
  useGetConnectorsByConnectorIdMappingsQuery,
  usePostConnectorsByConnectorIdMutation,
  usePostConnectorsByConnectorIdStatusMappingsMutation,
} from "@leadbay/state/api"
import { Delete, Edit } from "@mui/icons-material"
import { Box, IconButton, Tooltip } from "@mui/material"
import { EditIntegrationModal } from "@screens/dashboard/IntegrationsScreen/partials/Integrations/modals/EditIntegrationModal"
import { useCallback, useState } from "react"
import { toast } from "react-toastify"

interface IntergrationProps extends Connector {
  refetch: () => Promise<void>
}

export const Integration = ({
  id,
  name,
  type,
  imports,
  last_import: lastImport,
  refetch,
}: IntergrationProps) => {
  const { data: mappings } = useGetConnectorsByConnectorIdMappingsQuery({
    connectorId: id,
  })

  const logo =
    type === "ZAPIER" ? logoZapier : type === "HUBSPOT" ? logoHubspot : null

  const [editMode, setEditMode] = useState(false)

  const [deleteConnectorsByConnectorId] =
    useDeleteConnectorsByConnectorIdMutation()

  const handleDeleteConnectorApp = useCallback(async (id: number) => {
    try {
      await deleteConnectorsByConnectorId({ connectorId: String(id) })

      await refetch()

      toast(`Connector app disconnected successfully!`)
    } catch (error) {
      console.error(error)

      toast.error("Error updating Connector")
    }
  }, [])

  const handleConfirmDelete = useCallback(async () => {
    toast(
      <LbToastConfirm
        message={`Are you sure you want to disconnect the ${name} integration?`}
        buttonLabel="Disconnect"
      />,
      {
        onClick: async () => await handleDeleteConnectorApp(Number(id)),
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
      },
    )
  }, [id, name])

  const handleEdit = useCallback(() => {
    setEditMode(true)
  }, [])

  const [postConnectorsByConnectorId] = usePostConnectorsByConnectorIdMutation()
  const [postConnectorsByConnectorIdStatusMappings] =
    usePostConnectorsByConnectorIdStatusMappingsMutation()

  const handleSave = useCallback(
    async ({
      name,
      mappings,
    }: {
      name: string
      mappings: Mappings["statuses"]
    }) => {
      let noError = true

      try {
        await postConnectorsByConnectorId({
          connectorId: id,
          connectorUpdate: {
            name,
          },
        })
      } catch (error) {
        noError = false
        console.error("Error renaming Connector app", error)
        toast.error("Error renaming Connector app")
      }

      try {
        await postConnectorsByConnectorIdStatusMappings({
          connectorId: id,
          statusMappings: {
            statuses: mappings,
          },
        })
      } catch (error) {
        noError = false
        console.error("Error updating mappings", error)
        toast.error("Error updating mappings")
      }

      await refetch()

      if (noError) toast(`Connector app updated successfully!`)
    },
    [
      id,
      postConnectorsByConnectorId,
      postConnectorsByConnectorIdStatusMappings,
    ],
  )

  return (
    <>
      <LbDataSourceCard
        title={name}
        infos={[
          { key: "Type", value: type },
          { key: "Records imported", value: imports.toString() },
          lastImport !== undefined && {
            key: "Last import",
            value: new Date(lastImport).toLocaleString(undefined, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }),
          },
        ]}
        icon={
          logo ? (
            <Box borderRadius="5px" width={40} height={40} overflow="hidden">
              <img src={logo} alt="connector icon" width={40} />
            </Box>
          ) : undefined
        }
        action={
          <>
            <Tooltip title="Edit">
              <IconButton onClick={handleEdit} edge="end">
                <Edit color="primary" sx={{ fontSize: "1.2rem" }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Remove this connector">
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={handleConfirmDelete}
              >
                <Delete color="primary" sx={{ fontSize: "1.2rem" }} />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <EditIntegrationModal
        open={editMode}
        onClose={() => setEditMode(false)}
        name={name}
        mappings={mappings?.statuses ?? {}}
        icon={
          logo ? (
            <Box borderRadius="5px" width={40} height={40} overflow="hidden">
              <img src={logo} alt="connector" width={40} />
            </Box>
          ) : undefined
        }
        save={handleSave}
      />
    </>
  )
}
