import {
  GetGeoSearchApiResponse,
  LeadLocation,
  LeadSimplified,
  NewableLead,
} from "@leadbay/state/api"
import { AutoCompleteOption, TempLikedLead } from "@leadbay/typings"

export interface TransformedEntry {
  block_id?: string
  id: string
  name: string
  sector: string
  location: string
  keywords: Array<{ label: string; highlighted?: boolean }>
  size: [number, number]
  state: string
  score: number
  exported: boolean
  new: boolean
  liked?: boolean
  disliked?: boolean
  type: string
  status: { status: string }
  saved_source: string
  status_prediction: string
  description: string
  website: string
  description_source: string
  origin: string
  logo: string
  highlighted_fields: string[]
}

export const findLocationByValue = (
  locations: GetGeoSearchApiResponse,
  value: AutoCompleteOption,
) =>
  locations.find((location) =>
    Object.values(location).some((val) => value.value.split(",").includes(val)),
  )

export const parseLocationString = (locationString: string): LeadLocation => {
  const parts = locationString.split(", ").filter(Boolean)
  const location: LeadLocation = {
    country: "",
  }

  switch (parts.length) {
    case 3:
      location.city = parts[0]
      location.state = parts[1]
      location.country = parts[2]
      break
    case 2:
      location.state = parts[0]
      location.country = parts[1]
      break
    case 1:
      location.country = parts[0]
      break
  }

  return location
}

export const generateLocationString = ({
  city,
  state,
  country,
}: LeadLocation): string =>
  [city, state, country].filter((part) => part !== undefined).join(", ")

const getLike = (
  id: string,
  isLiked: boolean,
  isDisliked: boolean,
  tempLikedLeads: Array<TempLikedLead>,
) => {
  const tempLike = tempLikedLeads.find((tempLike) => tempLike.id === id)

  return {
    liked: !tempLike ? isLiked : tempLike.liked === "like",
    disliked: !tempLike ? isDisliked : tempLike.liked === "dislike",
  }
}

export const generateWishlistRows = (
  data: NewableLead[],
  tempLikedLeads?: Array<TempLikedLead>,
): TransformedEntry[] => {
  return data.length === 0
    ? ([] as unknown as TransformedEntry[])
    : (data.map((entry) => {
        const { liked, disliked } = tempLikedLeads
          ? getLike(entry.id, entry.liked, entry.disliked, tempLikedLeads)
          : { liked: entry.liked, disliked: entry.disliked }

        return {
          id: entry?.id,
          databaseId: entry?.id,
          new: entry?.new ?? undefined,
          name: entry?.name,
          highlighted_fields: entry?.highlighted_fields,
          sector: entry?.sector ?? "",
          logo: entry?.logo ?? "",
          liked,
          disliked,
          location: generateLocationString(entry?.location),
          keywords: entry?.keywords?.map((kw) => ({
            label: kw.keyword,
            highlighted: kw?.highlighted,
            importance: kw?.importance,
          })),
          type: entry?.type,
          status: {
            status: entry?.state?.status,
          },
          saved_source: entry?.saved_source,
          status_prediction: entry?.status_prediction,
          size: entry?.size ? [entry?.size?.min, entry?.size?.max] : undefined,
          state: entry?.state?.status,
          score: entry?.score ?? 0,
          description: entry?.description ?? undefined,
          website: entry?.website ?? undefined,
          description_source: entry?.description_source ?? undefined,
          origin: entry?.origin,
        }
      }) as unknown as TransformedEntry[])
}

export type GenerateWishlistRowsSimplifiedParams = LeadSimplified & {
  block_id: string
  exported: boolean
  saved_source: string
  state: { status: string }
  description: string
  website: string
  description_source: string
  liked: boolean
  disliked: boolean
  new: boolean
}

export const generateWishlistRowsSimplified = (
  data: GenerateWishlistRowsSimplifiedParams[],
): TransformedEntry[] =>
  data.length === 0
    ? ([] as unknown as TransformedEntry[])
    : (data.map((entry) => ({
        block_id: entry?.block_id,
        id: entry?.id,
        databaseId: entry?.id,
        new: entry?.new ?? undefined,
        name: entry?.name,
        sector: entry?.sector ?? "",
        exported: entry?.exported,
        liked: entry?.liked,
        disliked: entry?.disliked,
        type: entry?.type,
        logo: entry?.logo ?? "",
        saved_source: entry?.saved_source,
        location: generateLocationString(entry?.location),
        status: entry.state?.status,
        size: entry?.size ? [entry?.size?.min, entry?.size?.max] : undefined,
        state: entry?.state?.status,
        description: entry?.description ?? undefined,
        website: entry?.website ?? undefined,
        description_source: entry?.description_source ?? undefined,
      })) as unknown as TransformedEntry[])
