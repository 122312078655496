import { LbLensCard } from "@components/display/LbLensCard/LbLensCard"
import { DEFAULT_LENS } from "@leadbay/constants"
import {
  useGetLensesByLensIdScoringQuery,
  useGetLensesQuery,
  usePostLensesMutation,
  type Lens,
} from "@leadbay/state/api"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import { selectAuthState } from "@leadbay/state/slices/authSlice"
import {
  goToDefaultNavDrawerPartial,
  selectCommonsState,
  setCurrentLensId,
} from "@leadbay/state/slices/commonsSlice"
import { Add } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { toast } from "react-toastify"

export const LbLensesCrud = () => {
  const dispatch = useAppDispatch()

  const { token } = useAppSelector(selectAuthState)

  const { currentLensId } = useAppSelector(selectCommonsState)

  const { refetch: refetchScoring } = useGetLensesByLensIdScoringQuery(
    {
      lensId: currentLensId,
    },
    {
      skip: !currentLensId,
    },
  )

  const handleClose = () => {
    dispatch(goToDefaultNavDrawerPartial({}))
  }

  const { data: lenses, refetch: refetchLenses } = useGetLensesQuery(
    undefined,
    {
      skip: !token,
    },
  )

  const [lensTitle, setLensTitle] = useState("")
  const [lensCreateLoading, setLensCreateLoading] = useState(false)
  const [isMultiProductMode, setIsMultiProductMode] = useState(false)

  const [postLenses] = usePostLensesMutation()
  const [tempLenses, setTempLenses] = useState<
    Array<Lens & { realId?: string }>
  >([])

  const handleCreateLens = useCallback(async () => {
    const tempLensId = `temp-${tempLenses.length + 1}`

    try {
      setLensCreateLoading(true)

      setTempLenses((prev) => [
        ...prev,
        {
          id: tempLensId,
          name: lensTitle,
          default: false,
          multi_product_mode: isMultiProductMode,
        },
      ])

      const response = await postLenses({
        createLens: {
          base: currentLensId,
          name: lensTitle,
        },
      }).unwrap()

      setTempLenses((prev) =>
        prev.map((lens) =>
          lens.id === tempLensId ? { ...lens, realId: response.id } : lens,
        ),
      )

      dispatch(setCurrentLensId(response.id))

      await refetchLenses()
      await refetchScoring()

      setLensTitle("")

      toast.success(
        "Lens has been successfully created. Any changes you make to the filters will now be saved to this lens.",
      )
    } catch (error) {
      toast.error(
        "An error occurred during lens creation, please try again later",
      )

      console.error(error)
    } finally {
      setIsMultiProductMode(false)
      setLensCreateLoading(false)
      setTempLenses((prev) => prev.filter((lens) => lens.id !== tempLensId))
    }
  }, [
    currentLensId,
    dispatch,
    lensTitle,
    postLenses,
    refetchLenses,
    refetchScoring,
    tempLenses,
  ])

  const userLenses = lenses ?? []

  const privateLenses = useMemo(
    () =>
      [
        ...tempLenses.filter((lens) =>
          lenses?.every((l) => l.id !== lens.realId),
        ),
        ...userLenses,
      ].filter((l) => l.user_id),
    [userLenses, tempLenses],
  )

  const organizationLenses = userLenses
    ?.filter((l) => !l.user_id)
    .sort((a, b) =>
      a.name === DEFAULT_LENS
        ? 1
        : b.name === DEFAULT_LENS
          ? -1
          : a.name.localeCompare(b.name),
    )

  return (
    <Box
      className="hide-scrollbar"
      component="article"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Box sx={{ mb: 3, mx: 2, px: 1 }}>
        <Typography variant="h6" fontFamily="Hanken Grotesk">
          Lenses
        </Typography>

        <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
          With the &quot;Lens&quot; feature, you can easily create, share and
          switch between personalized filters.
        </Typography>

        <Box
          sx={{ mt: 1, display: "block" }}
          component="a"
          href="https://docs.leadbay.app/leadbay-user-docs/product-guides/lenses"
          target="_blank"
        >
          📙{" "}
          <Typography
            component="span"
            color="text.secondary"
            style={{ marginLeft: 5, fontWeight: 600, fontSize: 14 }}
          >
            documentation
          </Typography>
        </Box>
      </Box>

      <Paper
        sx={{
          px: 2,
          py: 3,
          mt: 1,
          mb: 2,
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
        elevation={0}
      >
        <TextField
          size="small"
          label="Create a new lens"
          placeholder="Lens title"
          value={lensTitle}
          onChange={(e) => setLensTitle(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === "Enter") await handleCreateLens()
          }}
          sx={{ width: "100%" }}
        />

        <IconButton
          color="primary"
          onClick={handleCreateLens}
          sx={{ alignSelf: "flex-center" }}
          disabled={lensCreateLoading || !lensTitle}
        >
          {!lensCreateLoading ? (
            <Add style={{ fontSize: "1.5rem" }} />
          ) : (
            <CircularProgress size={12} />
          )}
        </IconButton>
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flex: 1,
          gap: 2,
          mb: 0.5,
          px: 1,
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{ fontSize: "1.1rem" }}
            variant="h6"
            fontFamily="Hanken Grotesk"
          >
            Organization lenses ({organizationLenses?.length ?? 0})
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {organizationLenses?.map((lens, index) => (
          <LbLensCard
            isPrivate={false}
            key={lens.name + index}
            lens={lens}
            temporary={lens.id.startsWith("temp-")}
          />
        ))}
      </Box>

      <Box sx={{ mt: 5 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flex: 1,
            gap: 2,
            mb: 0.5,
            px: 1,
          }}
        >
          <Box sx={{ mb: 2 }}>
            <Typography
              sx={{ fontSize: "1.1rem" }}
              variant="h6"
              fontFamily="Hanken Grotesk"
            >
              Private lenses ({privateLenses?.length ?? 0})
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {privateLenses?.map((lens, index) => (
            <LbLensCard
              isPrivate
              key={lens.name + index}
              lens={lens}
              temporary={lens.id.startsWith("temp-")}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}
