import { APP_DRAWER_WIDTH, WEB_APP_ROUTES } from "@leadbay/constants"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"
import {
  commons,
  NavDrawerState,
  selectCommonsState,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"
import { Box, Tab, Tabs } from "@mui/material"
import { useEffect, useMemo, useState, type ReactNode } from "react"
import { LbLeadInfos } from "./partials/LbLeadInfos/LbLeadInfos"
import { LbLensSelector } from "./partials/LbLensSelector"
import { LbLensesCrud } from "./partials/LbLensesCrud/LbLensesCrud"
import { LbScoringParameters } from "./partials/LbScoringParameters/LbScoringParameters"
import { LbWishListFilters } from "./partials/LbWishListFilters/LbWishlistFilters"

const { toggleNavDrawer } = commons.actions

export const LbMainDrawer = () => {
  const { navDrawerPartial, drawerData, navDrawerIsOpen, wishlistViewMode } =
    useAppSelector(selectCommonsState)

  const dispatch = useAppDispatch()

  const handleNavigate = (menu: NavDrawerState) => {
    dispatch(
      toggleNavDrawer({
        isOpen: true,
        partial: menu,
      }),
    )
  }

  const partials: Record<NavDrawerState, ReactNode> = useMemo(
    () => ({
      WISHLIST_FILTERS: <LbWishListFilters />,
      SCORING_PARAMETERS: <LbScoringParameters />,
      LEAD_INFOS: <LbLeadInfos leadData={drawerData?.row} />,
      LENS_CRUD: <LbLensesCrud />,
    }),
    [drawerData],
  )

  const isFavoritesScreen =
    location.pathname === WEB_APP_ROUTES.APP + WEB_APP_ROUTES.PRIVATE.FAVORITES
  const isTimeLine = wishlistViewMode === WishlistViewMode.TIMELINE

  const currentPartial = useMemo(() => {
    if (isTimeLine) {
      return NavDrawerState.LEAD_INFOS
    }

    const current = isFavoritesScreen
      ? NavDrawerState.LEAD_INFOS
      : (navDrawerPartial as NavDrawerState)

    if (current === NavDrawerState.LEAD_INFOS && !drawerData?.row) {
      return NavDrawerState.SCORING_PARAMETERS
    }

    return current
  }, [isFavoritesScreen, navDrawerPartial, drawerData, wishlistViewMode])

  useEffect(() => {
    toggleNavDrawer({
      isOpen: navDrawerIsOpen,
      partial: currentPartial,
    })
  }, [])

  const [tabs, setTabs] = useState<
    Array<{ label: string; value: NavDrawerState }> | undefined
  >(
    currentPartial === NavDrawerState.LEAD_INFOS
      ? undefined
      : [
          {
            label: "Filters",
            value: NavDrawerState.WISHLIST_FILTERS,
          },
          {
            label: "Scoring",
            value: NavDrawerState.SCORING_PARAMETERS,
          },
        ],
  )

  useEffect(() => {
    if (currentPartial === NavDrawerState.LEAD_INFOS) {
      setTabs(undefined)
    } else {
      setTabs([
        {
          label: "Filters",
          value: NavDrawerState.WISHLIST_FILTERS,
        },
        {
          label: "Scoring",
          value: NavDrawerState.SCORING_PARAMETERS,
        },
      ])
    }
  }, [currentPartial])

  const currentTabs = [
    NavDrawerState.LEAD_INFOS,
    NavDrawerState.LENS_CRUD,
  ].includes(currentPartial)
    ? NavDrawerState.SCORING_PARAMETERS
    : currentPartial

  return (
    <Box
      component="nav"
      className="hide-scrollbar"
      sx={{
        background: "white",
        height: "calc(100vh - 30px)",
        overflowY: "auto",
        position: "sticky",
        width: APP_DRAWER_WIDTH,
        top: "30px",
        right: 0,
        transform: !navDrawerIsOpen ? "translateX(100%)" : "translateX(0)",
        willChange: "transform",
        pt: "16px",
        pb: 1,
        px: 2,
        transition: "all 0.2s ease",
      }}
    >
      {currentPartial !== NavDrawerState.LEAD_INFOS && (
        <Box mb={0.5} width="100%" px={2}>
          <LbLensSelector />
        </Box>
      )}

      {tabs && (
        <Tabs
          value={currentTabs}
          onChange={(_, value: NavDrawerState) => handleNavigate(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            mb: 1,
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "0.8rem",
              }}
            />
          ))}
        </Tabs>
      )}

      {partials[currentPartial]}
    </Box>
  )
}
