import { WebSocketContext } from "@leadbay/providers"
import { MessageType, MessageTypeToMessage } from "@leadbay/typings"
import { useContext } from "react"

interface UseLeadbayWSNotificationReturn<T extends MessageType> {
  messages: MessageTypeToMessage[T][]
  clearMessageHistory: () => void
}

export const useLeadbayWSNotification = <T extends MessageType>(
  messageType: T,
): UseLeadbayWSNotificationReturn<T> => {
  const context = useContext(WebSocketContext)

  if (!context) {
    throw new Error(
      "useLeadbayWSNotification must be used within a WebSocketProvider",
    )
  }

  const messages = (context.messagesByType[messageType] ||
    []) as MessageTypeToMessage[T][]

  const clearMessageHistory = () => {
    context.clearMessagesByType(messageType)
  }

  return {
    messages,
    clearMessageHistory,
  }
}
