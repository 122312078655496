import { useEffect } from "react";
import { recalculatePageSize } from "../../slices/commonsSlice";
import { useAppDispatch } from "../commons/useAppDispatch";

export const useGetWindowSize = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(recalculatePageSize());
    });
  }, [dispatch]);
};
