import AnimatedNumber from "@crossfox/react-animated-number"
import { useCountSeenLeads } from "@leadbay/state/hooks"
import { Box, LinearProgress, Tooltip, Typography } from "@mui/material"

export const LbNewLeadSeenProgress = () => {
  const { progress } = useCountSeenLeads()

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <Box sx={{ width: "100px" }}>
        <LinearProgress
          color={progress === 100 ? "success" : "primary"}
          variant="determinate"
          value={progress}
        />
      </Box>

      <Box sx={{ minWidth: 35, mt: "8px" }}>
        <Typography
          component="div"
          sx={{
            lineHeight: "0",
            whiteSpace: "nowrap",
          }}
          fontSize="0.7rem"
          color="text.secondary"
          fontWeight="bold"
        >
          <Tooltip title="Amount of new leads seen">
            <AnimatedNumber
              rate={100}
              value={Math.floor(progress)}
              duration={1000}
              suffix="% lead seen"
            />
          </Tooltip>
        </Typography>
      </Box>
    </Box>
  )
}
