import {
  Interaction,
  useGetLensesByLensIdLeadsQuery,
  useGetLensesByLensIdLeadsWishlistQuery,
} from "@leadbay/state/api"
import {
  useAppDispatch,
  useAppSelector,
  useRecordUserInteractions,
} from "@leadbay/state/hooks"
import { selectCommonsState } from "@leadbay/state/slices/commonsSlice"
import { MessageType } from "@leadbay/typings"
import { useAsyncEffect } from "ahooks"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useLeadbayWSNotification } from "../commons/useLeadbayWSNotification"

export interface PaginationModel {
  page: number
  pageSize: number
}

export const useWishlistData = () => {
  const dispatch = useAppDispatch()

  const {
    currentLensId,
    pageSize,
    wishlistViewMode,
    currentFilter,
    sortModel,
  } = useAppSelector(selectCommonsState)

  const [checkWishlistComputing, setCheckWishlistComputing] = useState(false)
  const [checkMonitorComputing, setCheckMonitorComputing] = useState(false)
  const [pageViewHistory, setPageViewHistory] = useState<number[]>([])

  const [monitorPaginationModel, setMonitorPaginationModel] =
    useState<PaginationModel>({
      page: 0,
      pageSize: pageSize,
    })

  const [wishlistPaginationModel, setWishlistPaginationModel] =
    useState<PaginationModel>({
      page: 0,
      pageSize: pageSize,
    })

  const { handleRecordUserInteractions } = useRecordUserInteractions()

  const { messages } = useLeadbayWSNotification(MessageType.IMPORT_PROGRESS)

  const {
    data: wishlistData,
    isFetching: isWishlistFetching,
    error: wishlistError,
  } = useGetLensesByLensIdLeadsWishlistQuery(
    {
      lensId: currentLensId,
      count: pageSize,
      page: wishlistPaginationModel.page,
      q: currentFilter,
      order: sortModel,
    },
    {
      skip: !currentLensId,
      pollingInterval: checkWishlistComputing ? 2000 : 1000 * 60 * 60,
    },
  )

  const {
    data: monitorData,
    isFetching: isMonitorFetching,
    error: monitorError,
  } = useGetLensesByLensIdLeadsQuery(
    {
      lensId: currentLensId,
      page: monitorPaginationModel.page,
      count: pageSize,
      wished: false,
      q: currentFilter,
      order: sortModel,
    },
    {
      skip: !currentLensId,
      pollingInterval: checkMonitorComputing ? 2000 : 1000 * 60 * 60,
    },
  )

  useAsyncEffect(async () => {
    const dataItems = monitorData?.items
    const interactions: Interaction[] =
      dataItems?.map((item) => ({
        type: "LEAD_SEEN",
        lead_id: item.id,
        lens_id: String(currentLensId),
      })) || []

    if (
      dataItems &&
      !checkWishlistComputing &&
      !isWishlistFetching &&
      !isMonitorFetching
    ) {
      if (!pageViewHistory.includes(monitorPaginationModel.page)) {
        await handleRecordUserInteractions(interactions)
        setPageViewHistory((prev) => [...prev, monitorPaginationModel.page])
      }

      if (!pageViewHistory.includes(wishlistPaginationModel.page)) {
        await handleRecordUserInteractions(interactions)
        setPageViewHistory((prev) => [...prev, wishlistPaginationModel.page])
      }
    }
  }, [
    monitorData?.items,
    monitorPaginationModel.page,
    wishlistPaginationModel.page,
    isMonitorFetching,
    isWishlistFetching,
    checkMonitorComputing,
    wishlistViewMode,
    pageViewHistory,
    currentLensId,
  ])

  useEffect(() => {
    const lastMessage = messages?.[messages.length - 1]

    if (
      lastMessage &&
      [MessageType.SCORES_UPDATED, MessageType.LENS_REFRESHED].includes(
        lastMessage.type,
      )
    ) {
      setCheckMonitorComputing(false)
      setCheckWishlistComputing(false)

      setWishlistPaginationModel({
        page: 0,
        pageSize: pageSize ?? 10,
      })

      setMonitorPaginationModel({
        page: 0,
        pageSize: pageSize ?? 10,
      })
    }

    const isComputing =
      wishlistData?.computing_scores || wishlistData?.computing_scores

    if (isComputing) {
      setCheckWishlistComputing(true)
      setCheckMonitorComputing(true)
    } else {
      setCheckWishlistComputing(false)
      setCheckMonitorComputing(false)
    }

    const isRefetchMonitor = monitorData?.computing_scores

    if (isRefetchMonitor) {
      setCheckMonitorComputing(true)
      setCheckWishlistComputing(true)
    } else {
      setCheckMonitorComputing(false)
      setCheckWishlistComputing(false)
    }
  }, [
    monitorData?.computing_scores,
    wishlistData?.computing_scores,
    pageSize,
    messages,
    dispatch,
  ])

  useEffect(() => {
    if (wishlistError || wishlistError) {
      setCheckMonitorComputing(false)
      setCheckWishlistComputing(false)

      toast.error("Error fetching wishlist data")

      console.error(wishlistError)
      console.error(monitorError)
    }
  }, [wishlistError, monitorError])

  return {
    showLoader: checkWishlistComputing || checkMonitorComputing,
    wishlistData,
    monitorData,
    setMonitorPaginationModel,
    setWishlistPaginationModel,
    monitorPaginationModel,
    wishlistPaginationModel,
    isFetching: isWishlistFetching || isMonitorFetching,
  }
}
