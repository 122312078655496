import { IMPORT_ID } from "@leadbay/constants"
import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import localforage from "localforage"
import { RootState } from "../index"

interface OnboardingState {
  currentStep?: number
  mappingStep: number
  selectedFile?: File
  onboardingComplete?: boolean
  apiStep: number
  importId?: string
}

const initialState: OnboardingState = {
  currentStep: undefined,
  mappingStep: 0,
  selectedFile: undefined,
  onboardingComplete: false,
  apiStep: 0,
  importId: undefined,
}

export const onboarding = createSlice({
  name: "onboarding",

  initialState,

  reducers: {
    setNextStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload
    },

    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload
    },

    setMappingStep: (state, action: PayloadAction<number>) => {
      state.mappingStep = action.payload
    },

    setSelectedFile: (state, action: PayloadAction<File | undefined>) => {
      state.selectedFile = action.payload
    },

    setApiStep: (state, action: PayloadAction<number>) => {
      state.apiStep = action.payload
    },

    setImportId: (state, action: PayloadAction<string | undefined>) => {
      state.importId = action.payload

      localforage.setItem(IMPORT_ID, action.payload)
    },

    setOnboardingComplete: (state, action: PayloadAction<boolean>) => {
      state.onboardingComplete = action.payload
    },
  },
})

export const {
  setNextStep,
  setCurrentStep,
  setMappingStep,
  setSelectedFile,
  setApiStep,
  setImportId,
  setOnboardingComplete,
} = onboarding.actions

export const selectOnboardingState = (state: RootState): OnboardingState =>
  state.onboarding

export default onboarding.reducer
