import { LbWishlistGrid } from "@components/display/LbWishlistGrid/LbWishlistGrid"
import {
  useAppSelector,
  useKeyboardController,
  useWishlistData,
} from "@leadbay/state/hooks"
import {
  selectCommonsState,
  WishlistViewMode,
} from "@leadbay/state/slices/commonsSlice"
import { useEffect, useMemo, useState } from "react"
import { generateWishlistRows } from "@leadbay/utils"

export const Monitor = () => {
  const { wishlistViewMode, pageSize } = useAppSelector(selectCommonsState)

  const {
    monitorData,
    showLoader,
    isFetching,
    monitorPaginationModel,
    setMonitorPaginationModel,
  } = useWishlistData()

  const [rowCountState, setRowCountState] = useState(0)

  const rows = useMemo(
    () => generateWishlistRows(monitorData?.items ?? []),
    [monitorData],
  )

  const { dataGridRef } = useKeyboardController({
    page: monitorPaginationModel.page,
    rows,
  })

  useEffect(() => {
    if (pageSize) {
      setMonitorPaginationModel({
        pageSize,
        page: 0,
      })
    }
  }, [wishlistViewMode, pageSize])

  useEffect(() => {
    const total = monitorData?.pagination.total

    if (total !== undefined) {
      setRowCountState(total)
    }
  }, [monitorData, wishlistViewMode])

  return (
    <LbWishlistGrid
      key={showLoader ? "loader" : "grid"}
      dataGridRef={dataGridRef}
      rows={rows}
      rowCount={rowCountState}
      isFetching={isFetching}
      showLoader={showLoader}
      gridSubject={WishlistViewMode.MONITOR}
      paginationModel={monitorPaginationModel}
      setPaginationModel={setMonitorPaginationModel}
    />
  )
}
