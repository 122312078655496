import { useEffect, useRef } from "react"

interface UseKeyboardControllerProps {
  page: number
  rows: unknown[]
}

export const useKeyboardController = (props: UseKeyboardControllerProps) => {
  const dataGridRef = useRef<HTMLDivElement>(null)
  const isWaitingForPageChange = useRef(false)
  const isWaitingForRowsChange = useRef(false)
  const lastKey = useRef<string | null>(null)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const leadInfos = document.querySelector(".LbLeadInfos")
      if (!leadInfos) return
      const leadTitle = leadInfos.querySelector("h6")?.textContent?.trim()
      if (!leadTitle) return
      const leadScore = leadInfos
        .querySelector('[aria-label*="This score"]')
        ?.textContent?.trim()
      if (!leadScore) return

      const rows = Array.from(
        dataGridRef.current?.querySelectorAll(".MuiDataGrid-row") ?? [],
      )
      const selectedRow = rows.find((row) => {
        const score = row
          .querySelector('[data-field="score"]')
          ?.textContent?.trim()
        const title = row
          .querySelector('[data-field="name"]')
          ?.textContent?.trim()

        if (!score || !title) return false

        return (
          score.toLowerCase() === leadScore.toLowerCase() &&
          title.toLowerCase() === leadTitle.toLowerCase()
        )
      })

      if (!selectedRow) return

      if (event.key === "ArrowDown") {
        lastKey.current = "ArrowDown"

        const nextRow = selectedRow.nextElementSibling as HTMLElement
        if (nextRow) {
          nextRow.click()
        } else {
          const nextPageButton = document.querySelector(
            "button[aria-label='Go to next page']:not([disabled])",
          ) as HTMLButtonElement
          if (nextPageButton) {
            nextPageButton.click()
            isWaitingForPageChange.current = true
          }
        }
      }

      if (event.key === "ArrowUp") {
        lastKey.current = "ArrowUp"

        const previousRow = selectedRow.previousElementSibling as HTMLElement
        if (previousRow) {
          previousRow.click()
        } else {
          const previousPageButton = document.querySelector(
            "button[aria-label='Go to previous page']:not([disabled])",
          ) as HTMLButtonElement
          if (previousPageButton) {
            previousPageButton.click()
            isWaitingForPageChange.current = true
          }
        }
      }
    }

    document.body.addEventListener("keydown", handleKeyDown)

    return () => {
      document.body.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  useEffect(() => {
    if (!isWaitingForPageChange.current) return
    isWaitingForPageChange.current = false
    isWaitingForRowsChange.current = true
  }, [props.page])

  useEffect(() => {
    if (!isWaitingForRowsChange.current) return
    isWaitingForRowsChange.current = false

    setTimeout(() => {
      let row: Element | undefined
      if (lastKey.current === "ArrowDown") {
        row =
          dataGridRef.current?.querySelector(".MuiDataGrid-row") ?? undefined
      } else if (lastKey.current === "ArrowUp") {
        row =
          Array.from(
            dataGridRef.current?.querySelectorAll(".MuiDataGrid-row") || [],
          ).at(-1) ?? undefined
      }
      if (!row) return
      ;(row as HTMLElement).click()
    }, 0)
  }, [props.rows])

  return {
    dataGridRef,
  }
}
