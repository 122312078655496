import { LbMarker } from "@components/display/LbGoogleMapExport/LbMarker"
import { LBLogo } from "@components/display/LbLogo/LBLogo"
import { CENTERED_FLEX_COL, WEB_APP_ROUTES } from "@leadbay/constants"
import { Coordinate, LightLead } from "@leadbay/typings"
import { Box } from "@mui/material"
import { Map, useMap } from "@vis.gl/react-google-maps"
import { useEffect, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { openGoogleMapDirection } from "@leadbay/utils"

export const LeadbayMapScreen = () => {
  const navigate = useNavigate()

  const map = useMap()

  const [markers, setMarkers] = useState<Coordinate[]>([])
  const [lightLeads, setLightLeads] = useState<LightLead[]>([])

  useEffect(() => {
    const queryString = window.location.search

    const params = new URLSearchParams(queryString)

    const markersParam = params.get("markers")

    if (!markersParam) {
      navigate(WEB_APP_ROUTES.ROOT)

      return
    }

    const leads = JSON.parse(
      decodeURIComponent(atob(markersParam)),
    ) as LightLead[]

    setMarkers(
      leads.map(({ lat, lng }) => ({
        lat,
        lng,
      })),
    )

    setLightLeads(leads)
  }, [])

  useEffect(() => {
    if (map && markers && markers.length > 0) {
      const bounds = new google.maps.LatLngBounds()

      markers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng))
      })

      map.fitBounds(bounds)
    }
  }, [map, markers])

  return (
    <Box>
      <NavLink to={WEB_APP_ROUTES.ROOT}>
        <Box
          sx={{
            position: "absolute",
            top: 55,
            left: 7,
            zIndex: 1000,

            p: 1,
            borderRadius: "20px",
            ...CENTERED_FLEX_COL,
          }}
        >
          <LBLogo />
        </Box>
      </NavLink>

      <Map
        defaultCenter={{ lat: 51.5074, lng: 0.1278 }}
        defaultZoom={10}
        mapId="bf51a910020fa25a"
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        {markers.map((marker, index) => (
          <LbMarker
            key={lightLeads[index].name}
            position={marker}
            lead={lightLeads[index]}
            onClick={() => openGoogleMapDirection(marker)}
          />
        ))}
      </Map>
    </Box>
  )
}
